import React, { useState } from "react";
import { AdjustmentsTableProps } from "./types";
import { useAdjustments } from "hooks/useAdjustments";
import { Paper } from "@material-ui/core";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import CreateAdjustmentDialog from "components-ts/CreateAdjustmentDialog";
import ReviewAdjustmentDialog from "components-ts/ReviewAdjustmentDialog";

export const AdjustmentsTable: React.FC<AdjustmentsTableProps> = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [openCreate, setOpenCreate] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [adjustment, setAdjustment] = useState({});
  const {
    data: adjustments,
    createAdjustment,
    takeActionAdjustment,
    mutate,
    loading,
  } = useAdjustments();

  const handleAction = async (form) => {
    await createAdjustment(form);
    mutate();
    setOpenCreate(false);
  };

  const handleReviewAction = async (form) => {
    await takeActionAdjustment(form);
    mutate();
    setOpenCreate(false);
  };

  const handleOnClick = (
    _event: React.MouseEvent<unknown>,
    _history: any,
    item: any
  ) => {
    setOpenReview(true);
    setAdjustment(item);
  };

  const tableRows = [
    { key: "id", alignRight: false, label: "ID" },
    { key: "reason", alignRight: false, label: "Reason" },
    { key: "amount", alignRight: false, label: "Amount" },
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "category", alignRight: false, label: "Category" },
    { key: "creator_uid", alignRight: false, label: "Creator UID" },
    {
      key: "receiving_account_code",
      alignRight: false,
      label: "Receiving account number",
    },
    { key: "state", alignRight: true, label: "State" },
  ];

  return (
    <React.Fragment>
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={adjustments?.total}
          rows={tableRows}
          data={adjustments?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={handleOnClick}
          hidePagination={false}
          mutateData={mutate}
          newItem={() => setOpenCreate(true)}
          loading={loading}
          tableTitle={"Adjustments"}
        />
      </Paper>
      <CreateAdjustmentDialog
        open={openCreate}
        handleClose={() => setOpenCreate(false)}
        handleAction={(data) => handleAction(data)}
      />
      <ReviewAdjustmentDialog
        open={openReview}
        handleClose={() => setOpenReview(false)}
        handleAction={(data) => handleReviewAction(data)}
        defaultForm={adjustment}
      />
    </React.Fragment>
  );
};

export default AdjustmentsTable;
