import { USER_LEVEL } from "providers/UserProvider";

export enum OPERATION_TYPES {
  WITHDRAW = "withdraw",
  DEPOSIT = "deposit",
}

export const OPERATION_OPTIONS = [
  { label: "withdraw", value: OPERATION_TYPES.WITHDRAW },
  { label: "deposit", value: OPERATION_TYPES.DEPOSIT },
];

export enum LIMITS_KINDS {
  VOLUME = "volume",
  QUANTITY = "quantity",
}

export const KINDS_OPTIONS = [
  { label: "volume", value: LIMITS_KINDS.VOLUME },
  { label: "quantity", value: LIMITS_KINDS.QUANTITY },
];

export enum LIMITS_TIME {
  DAILY = "daily",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export const TIME_OPTIONS = [
  { label: "daily", value: LIMITS_TIME.DAILY },
  { label: "monthly", value: LIMITS_TIME.MONTHLY },
  { label: "yearly", value: LIMITS_TIME.YEARLY },
];

export const LEVEL_OPTIONS = [
  { label: "1", value: String(USER_LEVEL.LEVEL_1) },
  { label: "2", value: String(USER_LEVEL.LEVEL_2) },
  { label: "3", value: String(USER_LEVEL.LEVEL_3) },
];
