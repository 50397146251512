import React from "react";
import { UserAccountsProps } from "./types";
import { Typography } from "@material-ui/core";
import { InfoTable } from "components-ts/InfoTable";

export const UserAccounts: React.FC<UserAccountsProps> = ({
  member,
  accountRows,
}) => {
  const content =
    member && member.accounts && member.accounts.length ? (
      <InfoTable
        dataLength={accountRows.length}
        rows={accountRows}
        data={member.accounts}
        rowsPerPage={member.accounts.length}
        hidePagination={true}
      />
    ) : (
      <Typography
        variant="h6"
        gutterBottom={true}
        style={{ color: "#757575", paddingLeft: 26, paddingBottom: 26 }}
      >
        No Accounts
      </Typography>
    );

  return <React.Fragment>{content}</React.Fragment>;
};

export default UserAccounts;
