import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, Menu } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { NavbarProps } from "./types";
import { icons } from "./icons";

import "./styles.scss";

export const Navbar: React.FC<NavbarProps> = ({
  loggedIn,
  open,
  isSuperAdmin,
  pathname,
  handleDrawerOpen,
  handleDrawerClose,
  logout,
}) => {
  const [key, setKey] = useState<string>("/tower");

  const renderList = (list) => {
    return list.map((item) => (
      <Link to={item.key} className="navbar__link" key={item.key}>
        <ListItem
          className="navbar__listItem"
          classes={{ selected: "navbar__selected", button: "navbar__button" }}
          button={true}
          key={item.key}
          onClick={() => setKey(item.key)}
          selected={key === item.key}
        >
          <ListItemIcon>{icons(item.key)}</ListItemIcon>
          <ListItemText
            disableTypography={true}
            primary={<Typography variant="body2">{item.value}</Typography>}
          />
        </ListItem>
      </Link>
    ));
  };

  const NavBarItems = [
    { key: "/tower", value: "Dashboard" },
    { key: "/tower/users", value: "Let'sBit Members" },
    { key: "/tower/users/all", value: "All Members" },
    { key: "/tower/pending", value: "Pending Verification" },
    { key: "/tower/extra", value: "Pending Extra Documents" },
    { key: "/tower/users/blacklist", value: "Black List" },
    { key: "/tower/activities", value: "User Activities" },
    { key: "/tower/orders", value: "Orders" },
    { key: "/tower/trades", value: "Trades" },
    { key: "/tower/deposits", value: "Deposits" },
    { key: "/tower/withdraws", value: "Withdraws" },
    { key: "/tower/beneficiaries", value: "Beneficiaries" },
    { key: "/tower/unmatched", value: "Unmatched Deposits" },
  ];

  const SuperAdminItems = [
    { key: "/tower/permissions", value: "Permissions" },
    { key: "/tower/admin-activities", value: "Admin Activities" },
    { key: "/tower/adjustments", value: "Adjustments" },
  ];

  useEffect(() => {
    setKey(pathname);
  }, [pathname]);

  return (
    <div>
      <AppBar
        position="fixed"
        className={`navbar__appBar ${!loggedIn ? "navbar__navbarHidden" : ""}`}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => handleDrawerOpen()}
            className={`navbar__menuButton ${open ? "navbar__hide" : ""}`}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" color="inherit" className="navbar__grow" />
          <Button color="inherit" onClick={() => logout()}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className="navbar__drawer"
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: "navbar__drawerPaper",
        }}
      >
        <div className="navbar__drawerHeader">
          <IconButton onClick={() => handleDrawerClose()}>
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List>{renderList(NavBarItems.slice(0, 1))}</List>
        <Divider />
        <List>
          {renderList(NavBarItems.slice(1, 7))}
          {isSuperAdmin && renderList(SuperAdminItems)}
        </List>
        <Divider />
        <List>{renderList(NavBarItems.slice(7))}</List>
      </Drawer>
    </div>
  );
};

export default Navbar;
