import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { useUserData } from "hooks/useUserData";
import { UserLimitsProps } from "./types";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { ChangeLimitModal } from "components-ts/ChangeLimitModal/ChangeLimitModal";
import {
  LimitsOrderForm,
  useLimits,
  UserLimits as Limits,
} from "hooks/useLimits";
import DeleteConfirmationDialog from "components-ts/DeleteConfirmationDialog";

import "./styles.scss";

export const UserLimits: React.FC<UserLimitsProps> = ({ match }) => {
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [onTarget, setOnTarget] = useState<{
    limit_id: number;
    type: string;
  }>();
  const {
    data: limits,
    createLimit,
    mutate,
    loading,
    deleteLimit: delLimit,
  } = useLimits(match.params.uid);

  const { data } = useUserData(match.params.uid);

  const handleModalClose = () => {
    setOpenCreate(false);
    setOnTarget(undefined);
  };

  const submit = async (orderForm: LimitsOrderForm) => {
    if (data && data.customer.cid) {
      await createLimit({
        ...orderForm,
        cid: data.customer.cid,
      });
      mutate();
    }
  };

  const LimitsRows = [
    { key: "id", alignRight: false, label: "ID" },
    { key: "type", alignRight: false, label: "Type" },
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "payment_network", alignRight: false, label: "Payment Network" },
    { key: "value", alignRight: false, label: "Value" },
    { key: "remaining", alignRight: false, label: "Remaining" },
    { key: "operation", alignRight: false, label: "Operation" },
    { key: "kind", alignRight: false, label: "Kind" },
    { key: "level", alignRight: false, label: "Level" },
    { key: "time_period", alignRight: false, label: "Time Period" },
  ];

  const deleteItem = async () => {
    setOpenDelete(false);
    if (onTarget) {
      await delLimit(onTarget);
      mutate();
      setOnTarget(undefined);
    }
  };

  const deleteLimit = (item: Limits) => {
    setOnTarget({ limit_id: item.id, type: "customer" });
    setOpenDelete(true);
  };

  return (
    <Paper className="user-limits__paper">
      <InfoTableComponent
        dataLength={limits?.length}
        rows={LimitsRows}
        data={limits}
        page={0}
        rowsPerPage={limits?.length || 0}
        handleChangePage={() => {}}
        handleChangeRowsPerPage={() => {}}
        handleRowOnClick={() => {}}
        hidePagination={true}
        tableTitle={"User Limits"}
        deleteLimit={deleteLimit}
        mutateData={mutate}
        loading={loading}
        newItem={() => setOpenCreate(true)}
      />

      <ChangeLimitModal
        open={openCreate}
        submit={submit}
        handleModalClose={handleModalClose}
        type="custom"
      />
      <DeleteConfirmationDialog
        open={openDelete}
        handleClose={() => {
          setOpenDelete(false);
          setOnTarget(undefined);
        }}
        handleAction={() => deleteItem()}
      ></DeleteConfirmationDialog>
    </Paper>
  );
};

export default UserLimits;
