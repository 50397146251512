import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import {
  KINDS_OPTIONS,
  LEVEL_OPTIONS,
  OPERATION_OPTIONS,
  TIME_OPTIONS,
} from "constants/limits";
import FormSelectOptions from "components-ts/FormSelectOption";
import { ChangeLimitModalProps } from "./types";
import { useCurrencies } from "hooks/useCurrencies";

import "./styles.scss";

export const ChangeLimitModal: React.FC<ChangeLimitModalProps> = ({
  open,
  handleModalClose,
  submit,
  parameters,
  type,
}) => {
  const onSubmit = (orderForm) => {
    submit(orderForm);
    handleModalClose();
    reset();
  };

  const cancel = () => {
    handleModalClose();
    reset();
  };
  const { handleSubmit, control, reset } = useForm();

  const { data: currencies } = useCurrencies();

  const CURRENCY_OPTIONS =
    currencies &&
    currencies.map((currency) => {
      return { label: currency.code, value: currency.code };
    });

  useEffect(() => {
    reset(parameters);
  }, [parameters, reset]);

  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>CREATE CUSTOM LIMIT</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText className="change-limit-modal__content">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Payment network"
                  helperText={error?.message}
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="payment_network"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={CURRENCY_OPTIONS || []}
                  error={invalid}
                  errorMessage={error?.message}
                  label="Currency"
                />
              )}
              control={control}
              name="currency"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={OPERATION_OPTIONS}
                  error={invalid}
                  errorMessage={error?.message}
                  label="Operation"
                />
              )}
              control={control}
              name="operation"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Value"
                  helperText={error?.message}
                  variant="outlined"
                  type="number"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="value"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={KINDS_OPTIONS}
                  error={invalid}
                  errorMessage={error?.message}
                  label="Kind"
                />
              )}
              control={control}
              name="kind"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={TIME_OPTIONS}
                  error={invalid}
                  errorMessage={error?.message}
                  label="Time period"
                />
              )}
              control={control}
              name="time_period"
              rules={{ required: "Required" }}
            />

            {type === "global" && (
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormSelectOptions
                    onChange={onChange}
                    value={value}
                    optionList={LEVEL_OPTIONS}
                    error={invalid}
                    errorMessage={error?.message}
                    label="Level"
                  />
                )}
                control={control}
                name="level"
                rules={{ required: "Required" }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            CANCEL
          </Button>
          <Button color="primary" type="submit">
            SAVE
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
