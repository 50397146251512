import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Grid, Modal, TextField, Typography } from '@material-ui/core';

import { DepositForm, NewDepositModalProps } from './types';
import FormSelectOptions from 'components-ts/FormSelectOption';
import { Currency } from 'hooks/useCurrencies';

import './styles.scss';

export const NewDepositModal: React.FC<NewDepositModalProps> = ({
  open,
  modalClose,
  currencyOptions,
  newDeposit,
}) => {
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = (formValues: DepositForm) => {
    newDeposit(formValues.currency, formValues.amount);
    modalClose();
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Modal open={open} onClose={modalClose} onKeyPress={handleEnterKeyPress}>
      <Grid
        container={true}
        direction={'column'}
        className="new-deposit-modal__paper"
      >
        <Grid item={true}>
          <Typography
            variant="h5"
            component="h5"
            className="new-deposit-modal__textField"
          >
            New Deposit
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item={true} className="new-deposit-modal__items">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error, invalid },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Amount"
                  helperText={error?.message}
                  variant="outlined"
                  type="number"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="amount"
              rules={{ required: 'Required' }}
            />
          </Grid>
          <Grid item={true} className="new-deposit-modal__items">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error, invalid },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={currencyOptions.map((c: Currency) => ({
                    label: c.code,
                    value: c.code,
                  }))}
                  label="Currency"
                  error={invalid}
                  errorMessage={error?.message}
                />
              )}
              control={control}
              name="currency"
              rules={{ required: 'Required' }}
            />
          </Grid>
          <Grid item={true}>
            <Grid
              container={true}
              justify={'flex-end'}
              spacing={1}
              style={{ marginTop: 20 }}
            >
              <Grid item={true}>
                <Button
                  onClick={() => {
                    reset();
                    modalClose();
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ color: '#3598D5' }}
                  >
                    CANCEL
                  </Typography>
                </Button>
              </Grid>
              <Grid item={true}>
                <Button type="submit">
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ color: '#3598D5' }}
                  >
                    OK
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Modal>
  );
};

export default NewDepositModal;
