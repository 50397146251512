import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { convertToObj } from "helpers/convertToObj";
import { buildQueryString } from "helpers/buildQueryString";
import { useUnmatchedDepositHistory } from "hooks/useUnmatchedDepositHistory";
import { UnmatchedDepositsScreenProps } from "./types";
import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";

export const UnmatchedDepositsScreen: React.FC<UnmatchedDepositsScreenProps> = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);

  const {
    data: unmatchedDeposits,
    mutate,
    loading,
  } = useUnmatchedDepositHistory(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ...convertToObj(data),
    })
  );

  const handleRowOnClick = (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => {
    history.push(`/tower/unmatched/${item.id}`);
  };

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  const tableRows = [
    { key: "id_type", alignRight: false, label: "ID type" },
    { key: "id_number", alignRight: false, label: "ID" },
    { key: "account_id", alignRight: false, label: "Account ID" },
    { key: "name", alignRight: false, label: "Name" },
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "amount", alignRight: false, label: "Amount" },
    { key: "start_date", alignRight: false, label: "Start Date" },
    { key: "end_date", alignRight: false, label: "End Date" },
    { key: "state", alignRight: true, label: "Status" },
  ];

  const selectedValues = [
    {
      value: "state",
      label: "State",
      checked: true,
    },
  ];

  const parsedData =
    unmatchedDeposits &&
    unmatchedDeposits.data.map((deposit) => {
      return {
        id_number: deposit.data.counterparty?.id
          ? deposit.data.counterparty?.id
          : deposit.id,
        id_type: deposit.data.counterparty?.id_type,
        name: deposit.data.counterparty?.name,
        amount: deposit.data.amount
          ? deposit.data.amount
          : deposit.data.charge?.value.amount,
        currency: deposit.data.currency
          ? deposit.data.currency
          : deposit.data.charge?.value.currency,
        start_date: deposit.data.start_date,
        end_date: deposit.data.end_date,
        account_id: deposit.data.account_number
          ? deposit.data.account_number
          : deposit.data.from?.account_id,
        ...deposit,
      };
    });

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
        handleTimeFormat={(date) => {
          return date.unix().toString();
        }}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={unmatchedDeposits?.total}
          rows={tableRows}
          data={parsedData}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={handleRowOnClick}
          hidePagination={false}
          mutateData={mutate}
          loading={loading}
          tableTitle={"Unmatched Deposits"}
        />
      </Paper>
    </React.Fragment>
  );
};

export default UnmatchedDepositsScreen;
