import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { localeDate } from "helpers/localeDate";
import { useWithdraw } from "hooks/useWithdraw";
import { useWithdrawHistory } from "hooks/useWithdrawHistory";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { ActionButtonInterface } from "components-ts/ActionButtons/types";
import ActionButtons from "components-ts/ActionButtons";
import { WithdrawInfoProps } from "./types";

import "./styles.scss";

export const WithdrawInfo: React.FC<WithdrawInfoProps> = ({ match, uid }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const {
    data: withdraw,
    takeActionWithdraw,
    mutate: mutateWithdraw,
  } = useWithdraw(match.params.tid);
  const {
    data: withdrawHistory,
    mutate: mutateWithdraws,
    loading,
  } = useWithdrawHistory(
    buildQueryString({
      page: page + 1,
      limit: tablePageLimit(),
      uid: withdraw?.uid || "",
    })
  );
  const history = useHistory();

  useEffect(() => {
    mutateWithdraws();
  }, [withdraw, mutateWithdraws]);

  const beneficiary = withdraw?.beneficiary;

  const actionButtons: ActionButtonInterface[] = [
    {
      variant: "accept",
      value: "Confirm",
      state: "processing",
      onClick: () => handleWithdrawAction("confirm"),
    },
    {
      variant: "accept",
      value: "Success",
      state: "errored processing confirming",
      onClick: () => handleWithdrawAction("success"),
    },
    {
      variant: "reject",
      value: "Fail",
      state: "processing submitted errored",
      onClick: () => handleWithdrawAction("fail"),
    },
    {
      variant: "reject",
      value: "Error",
      state: "processing confirming",
      onClick: () => handleWithdrawAction("err"),
    },
    {
      variant: "archive",
      value: "Process",
      state: "errored submitted",
      onClick: () => handleWithdrawAction("process"),
    },
  ];

  const handleWithdrawAction = async (action: string) => {
    if (withdraw) {
      const requestProps = {
        tid: withdraw.tid,
        action,
      };

      await takeActionWithdraw(requestProps);
      mutateWithdraw();
      mutateWithdraws();
    }
  };

  const handleRowOnClickWithdraw = (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => {
    history.push(`/tower/withdraws/${item.tid}`);
  };

  const tableRows = [
    { key: "uid", alignRight: false, label: "UID" },
    { key: "tid", alignRight: false, label: "TID" },
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "amount", alignRight: false, label: "Amount" },
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "rid", alignRight: false, label: "Recipient Address" },
    { key: "state", alignRight: true, label: "Status" },
  ];

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardContent className="withdraw-info__withdrawInfo">
          <div className="withdraw-info__withdrawInfoBlockBody">
            <div className="withdraw-info__withdrawInfoBlock">
              <Typography variant="h6" className="withdraw-info__emailTitle">
                {withdraw?.uid}
              </Typography>
            </div>
            <div className="withdraw-info__block">
              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  State
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  <span
                    className={clsx({
                      "withdraw-info__red": "failed errored".includes(
                        withdraw?.state || ""
                      ),
                      "withdraw-info__yellow": "submitted processing".includes(
                        withdraw?.state || ""
                      ),
                      "withdraw-info__green": "succeed confirming".includes(
                        withdraw?.state || ""
                      ),
                    })}
                  >
                    {withdraw?.state}
                  </span>
                </Typography>
              </div>
              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Date
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {localeDate(withdraw?.created_at, "fullDate")}
                </Typography>
              </div>
              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  TxID
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {withdraw?.txid}
                </Typography>
              </div>

              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  UID
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo withdraw-info__uidButton"
                  component="u"
                  onClick={() =>
                    history.push(`/tower/users/${withdraw?.uid}/main`)
                  }
                >
                  {withdraw?.uid}
                </Typography>
              </div>
              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  RID
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {withdraw?.rid}
                </Typography>
              </div>
              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  TID
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {withdraw?.tid}
                </Typography>
              </div>

              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Type
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {withdraw?.type}
                </Typography>
              </div>

              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Currency
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {(withdraw &&
                    withdraw.currency &&
                    withdraw.currency.toUpperCase()) ||
                    ""}
                </Typography>
              </div>
              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Amount
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {withdraw?.amount}
                </Typography>
              </div>
              <div className="withdraw-info__blockItem">
                <Typography
                  className="withdraw-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Fee
                </Typography>
                <Typography
                  className="withdraw-info__blockItemInfo"
                  component="p"
                >
                  {withdraw?.fee || 0}
                </Typography>
              </div>
              <Divider variant="middle" className="withdraw-info__divider" />
              {beneficiary && (
                <>
                  <div className="withdraw-info__withdrawInfoBlock">
                    <Typography
                      variant="h6"
                      className="withdraw-info__emailTitle"
                    >
                      Beneficiary
                    </Typography>
                  </div>
                  <div className="withdraw-info__blockItem">
                    <Typography
                      className="withdraw-info__blockItemTitle"
                      variant="h5"
                      component="h5"
                    >
                      Member UID
                    </Typography>
                    <Typography
                      className="withdraw-info__blockItemInfo"
                      component="p"
                    >
                      {beneficiary.member_uid}
                    </Typography>
                  </div>
                  <div className="withdraw-info__blockItem">
                    <Typography
                      className="withdraw-info__blockItemTitle"
                      variant="h5"
                      component="h5"
                    >
                      Name
                    </Typography>
                    <Typography
                      className="withdraw-info__blockItemInfo"
                      component="p"
                    >
                      {beneficiary.name}
                    </Typography>
                  </div>
                  <div className="withdraw-info__blockItem">
                    <Typography
                      className="withdraw-info__blockItemTitle"
                      variant="h5"
                      component="h5"
                    >
                      Description
                    </Typography>
                    <Typography
                      className="withdraw-info__blockItemInfo"
                      component="p"
                    >
                      {beneficiary.description}
                    </Typography>
                  </div>
                  <div className="withdraw-info__blockItem">
                    <Typography
                      className="withdraw-info__blockItemTitle"
                      variant="h5"
                      component="h5"
                    >
                      Currency
                    </Typography>
                    <Typography
                      className="withdraw-info__blockItemInfo"
                      component="p"
                    >
                      {beneficiary.currency}
                    </Typography>
                  </div>
                  <div className="withdraw-info__blockItem">
                    <Typography
                      className="withdraw-info__blockItemTitle"
                      variant="h5"
                      component="h5"
                    >
                      Payment network
                    </Typography>
                    <Typography
                      className="withdraw-info__blockItemInfo"
                      component="p"
                    >
                      {beneficiary.payment_network}
                    </Typography>
                  </div>
                  {beneficiary.data &&
                    beneficiary.data !== null &&
                    Object.entries(beneficiary.data).map(([field, data]) => (
                      <div className="withdraw-info__blockItem" key={field}>
                        <Typography
                          className="withdraw-info__blockItemTitle"
                          variant="h5"
                          component="h5"
                        >
                          {field.replace(/_/g, " ")}
                        </Typography>
                        <Typography
                          className="withdraw-info__blockItemInfo"
                          component="p"
                        >
                          {String(data)}
                        </Typography>
                      </div>
                    ))}
                </>
              )}
              <Divider variant="middle" className="withdraw-info__divider" />
              {withdraw && (
                <ActionButtons
                  actionButtons={actionButtons}
                  originalData={withdraw}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      <InfoTableComponent
        dataLength={withdrawHistory?.total}
        rows={tableRows}
        data={withdrawHistory?.data}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(page) => setPage(page)}
        handleChangeRowsPerPage={(count) => {
          setPage(0);
          setRowsPerPage(Number(count));
        }}
        handleRowOnClick={handleRowOnClickWithdraw}
        hidePagination={false}
        mutateData={mutateWithdraws}
        loading={loading}
        tableTitle={"Withdrawal History"}
      />
    </React.Fragment>
  );
};

export default WithdrawInfo;
