import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  ACTION_OPTIONS,
  ROLES_OPTIONS,
  VERBS_OPTIONS,
} from "../../constants/permissions";
import { EditPermissionsDialogProps } from "./types";
import { objectFilter } from "utils/objectFilter";
import FormSelectOptions from "components-ts/FormSelectOption";

import "./styles.scss";

export const EditPermissionsDialog: React.FC<EditPermissionsDialogProps> = ({
  handleClose,
  open,
  handleAction,
  defaultForm,
}) => {
  const { handleSubmit, control, reset } = useForm();
  const onSubmit = (data) => {
    const editPermissionForm = {
      role: data.role,
      path: data.path,
      topic: data.topic ? data.topic : undefined,
      verb: data.verb,
      action: data.action,
    };
    handleAction(objectFilter(editPermissionForm, (x: any) => !!x));
  };

  useEffect(() => {
    reset(defaultForm);
  }, [defaultForm, reset]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>EDIT</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText className="edit-permissions__content">
            <Controller
              render={({ field: { onChange, value } }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={ROLES_OPTIONS}
                  label="Role"
                />
              )}
              control={control}
              name="role"
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Path"
                  helperText={error?.message}
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="path"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({ field: { onChange, value } }) => (
                <TextField
                  style={{ margin: 15 }}
                  label="Topic"
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="topic"
            />

            <Controller
              render={({ field: { onChange, value } }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={VERBS_OPTIONS}
                  label="Verb"
                />
              )}
              control={control}
              name="verb"
            />

            <Controller
              render={({ field: { onChange, value } }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={ACTION_OPTIONS}
                  label="Action"
                />
              )}
              control={control}
              name="action"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
          <Button color="primary" type="submit">
            SAVE
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditPermissionsDialog;
