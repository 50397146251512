import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { ReviewAdjustmentDialogProps } from "./types";

import "./styles.scss";

export const ReviewAdjustmentDialog: React.FC<ReviewAdjustmentDialogProps> = ({
  handleClose,
  open,
  handleAction,
  defaultForm,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>REVIEW ADJUSTMENT</DialogTitle>
      <DialogContent>
        <DialogContentText className="review-adjustment-dialog__content">
          <TextField
            style={{ margin: 15 }}
            label="Reason"
            variant="outlined"
            type="text"
            value={defaultForm?.reason}
          />

          <TextField
            style={{ margin: 15 }}
            label="Description"
            variant="outlined"
            type="text"
            value={defaultForm?.description}
          />

          <TextField
            style={{ margin: 15 }}
            label="Currency"
            variant="outlined"
            type="text"
            value={defaultForm?.currency}
          />

          <TextField
            style={{ margin: 15 }}
            label="Category"
            variant="outlined"
            type="text"
            value={defaultForm?.category}
          />
          <TextField
            style={{ margin: 15 }}
            label="Amount"
            variant="outlined"
            type="text"
            value={defaultForm?.amount}
          />

          <TextField
            style={{ margin: 15 }}
            label="Asset code"
            variant="outlined"
            type="text"
            value={defaultForm?.asset_account_code}
          />

          <TextField
            style={{ margin: 15 }}
            label="Receiving account code"
            variant="outlined"
            type="text"
            value={defaultForm?.receiving_account_code}
          />

          {defaultForm?.receiving_member_uid && (
            <TextField
              style={{ margin: 15 }}
              label="UID"
              variant="outlined"
              type="text"
              value={defaultForm.receiving_member_uid}
            />
          )}
        </DialogContentText>
      </DialogContent>
      {defaultForm?.state === "pending" && (
        <DialogActions>
          <Button
            onClick={() =>
              handleAction({ id: defaultForm.id, action: "accept" })
            }
            color="primary"
          >
            ACCEPT
          </Button>
          <Button
            onClick={() =>
              handleAction({ id: defaultForm.id, action: "reject" })
            }
            color="primary"
            type="submit"
          >
            REJECT
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ReviewAdjustmentDialog;
