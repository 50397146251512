import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { WithdrawInfo } from "./useWithdrawHistory";
import {
  PAYMENTS_API_END_POINT_WITHDRAWS,
  PAYMENTS_API_END_POINT_WITHDRAWS_ACTIONS,
} from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

export const SWR_USER_WITHDRAW_KEY = (uid: string) => [
  "get-user-withdraw",
  uid,
];

const fetcher = async (_key: string, uid: string): Promise<WithdrawInfo> => {
  const { data } = await API.get(requestOptions)(
    `${PAYMENTS_API_END_POINT_WITHDRAWS}/${uid}`
  );

  return data;
};

export const useWithdraw = (uid: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR(SWR_USER_WITHDRAW_KEY(uid), fetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const takeActionWithdraw = async (data) => {
    try {
      await API.post(requestOptions)(
        PAYMENTS_API_END_POINT_WITHDRAWS_ACTIONS,
        data
      );
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return { data, error, mutate, takeActionWithdraw };
};
