import { API, RequestOptions } from "api";
import {
  COMPLIANCE_API_END_POINT_ADMIN_LABELS,
  COMPLIANCE_API_END_POINT_ADMIN_LABELS_UPDATE,
} from "api/apiEndpoints";
import { useSnackbar } from "notistack";

const requestOptions: RequestOptions = {
  apiVersion: "compliance",
};

interface labelForm {
  key: string;
  value: string;
  scope: string;
  uid: string;
}

export const useLabels = () => {
  const { enqueueSnackbar } = useSnackbar();
  const addNewLabel = async (labelForm: labelForm) => {
    try {
      await API.post(requestOptions)(
        COMPLIANCE_API_END_POINT_ADMIN_LABELS,
        labelForm
      );

      enqueueSnackbar("Added label", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const editLabel = async (labelForm: labelForm) => {
    try {
      await API.post(requestOptions)(
        COMPLIANCE_API_END_POINT_ADMIN_LABELS_UPDATE,
        labelForm
      );

      enqueueSnackbar("Edited label", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const deleteLabel = async (deleteLabelForm: {
    uid: string;
    key: string;
    scope: string;
  }) => {
    try {
      await API.delete(requestOptions)(
        COMPLIANCE_API_END_POINT_ADMIN_LABELS,
        deleteLabelForm
      );

      enqueueSnackbar("Deleted label", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return {
    addNewLabel,
    editLabel,
    deleteLabel,
  };
};
