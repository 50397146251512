import React, { useState } from "react";
import { Paper } from "@material-ui/core";

import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { buildQueryString, convertToObj } from "helpers";
import { useBeneficiaryHistory } from "hooks/useBeneficiaryHistory";

import { UserBeneficiariesProps } from "./types";

export const UserBeneficiaries: React.FC<UserBeneficiariesProps> = ({
  match,
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);
  const { data: beneficiaries, mutate, loading } = useBeneficiaryHistory(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ordering: "desc",
      uid: match.params.uid,
      ...convertToObj(data),
    })
  );

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  const handleRowOnClick = (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => {
    history.push(`/tower/beneficiaries/${item.id}`);
  };

  const tableRows = [
    { key: "id", alignRight: false, label: "ID" },
    { key: "member_uid", alignRight: false, label: "UID" },
    { key: "name", alignRight: false, label: "Name" },
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "payment_network", alignRight: false, label: "Payment Network" },
    { key: "state", alignRight: false, label: "State" },
  ];

  const selectedValues = [
    {
      value: "id",
      label: "ID",
      checked: false,
    },
    {
      value: "payment_network",
      label: "Payment Network",
      checked: false,
    },
    {
      value: "currency",
      label: "Currency",
      checked: false,
    },
  ];

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={beneficiaries?.total}
          rows={tableRows}
          data={beneficiaries?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={handleRowOnClick}
          hidePagination={false}
          mutateData={mutate}
          loading={loading}
          tableTitle={"Beneficiaries"}
        />
      </Paper>
    </React.Fragment>
  );
};

export default UserBeneficiaries;
