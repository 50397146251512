import { useEffect } from "react";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { API } from "api";
import { RequestOptions } from "api/requestBuilder";
import { COMPLIANCE_API_END_POINT_ADMIN_VERIFICATION } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "compliance",
};

export interface IdDocument {
  member_id: number;
  file: {
    url: string;
  };
  status: string;
  created_at: Date;
  updated_at: Date;
}

export interface VerificationVideo {
  member_id: number;
  video: {
    url: string;
  };
  status: string;
  created_at: Date;
  updated_at: Date;
}

export interface ChallengeDescription {
  gesture_id: string;
  image: string;
}

export interface LastChallenge {
  challenge_type: string;
  challenge_description: ChallengeDescription[];
  expires_at: Date;
}

export interface KyCDocumentsDescription {
  uid: string;
  email: string;
  modified_at: string;
  id_document?: IdDocument;
  verification_video?: VerificationVideo;
  last_challenge?: LastChallenge;
}

interface Response {
  data: KyCDocumentsDescription[];
  total: number;
}

export const SWR_PENDING_DOCUMENTS_KEY = (optionalParams?: string) => [
  "get-pending-documents",
  optionalParams,
];

const fetcher = async (
  _key: string,

  optionalParams?: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${COMPLIANCE_API_END_POINT_ADMIN_VERIFICATION}?${optionalParams}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const usePendingDocuments = (optionalParams?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_PENDING_DOCUMENTS_KEY(optionalParams),
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return {
    data,
    error,
    mutate,
    loading: isValidating,
  };
};
