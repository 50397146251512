import React, { useState } from "react";
import { UserExtraDocumentsProps } from "./types";
import "./styles.scss";
import { Paper } from "@material-ui/core";
import { useExtraDocument } from "hooks/useExtraDocument";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import ExtraDocumentModal from "components-ts/ExtraDocumentModal";
import { buildQueryString } from "helpers";

export const UserExtraDocuments: React.FC<UserExtraDocumentsProps> = ({
  match,
}) => {
  const [open, setOpen] = useState(false);
  const [extra, setExtra] = useState();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const { data, mutate, takeActionDocument, loading } = useExtraDocument(
    match.params.uid,
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
    })
  );

  const activityRows = [
    { key: "reason", alignRight: false, label: "Reason" },
    { key: "upload_type", alignRight: false, label: "Type" },
    { key: "comments", alignRight: false, label: "Comments" },
    { key: "status", alignRight: false, label: "Status" },
    { key: "created_at", alignRight: true, label: "Created at" },
  ];

  const handleSubmit = async (id: number, action: string) => {
    await takeActionDocument(id, action);
    setExtra(undefined);
    setOpen(false);
    mutate();
  };

  return (
    <React.Fragment>
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={data?.extra_documents.length}
          rows={activityRows}
          data={data?.extra_documents}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={(e, h, document) => {
            setOpen(true);
            setExtra(document);
          }}
          hidePagination={false}
          tableTitle={"Extra Documents"}
          mutateData={mutate}
          loading={loading}
        />
        <ExtraDocumentModal
          open={open}
          close={() => setOpen(!open)}
          submit={handleSubmit}
          extraDocument={extra}
        />
      </Paper>
    </React.Fragment>
  );
};

export default UserExtraDocuments;
