import React from "react";
import { UserAccountsInfoProps } from "./types";
import { Paper } from "@material-ui/core";
import { UserAccounts } from "../UserAccounts";
import { usePeatioMembers } from "hooks/usePeatioMembers";

export const UserAccountsInfo: React.FC<UserAccountsInfoProps> = ({
  match,
}) => {
  const { data: member } = usePeatioMembers(match.params.uid);

  const accountRows = [
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "balance", alignRight: false, label: "Balance" },
    { key: "locked", alignRight: false, label: "Locked" },
  ];
  return (
    <>
      <Paper>
        {member ? (
          <UserAccounts member={member} accountRows={accountRows} />
        ) : (
          "Loading"
        )}
      </Paper>
    </>
  );
};

export default UserAccountsInfo;
