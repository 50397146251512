import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";

const requestOptions: RequestOptions = {
  apiVersion: "compliance",
};

export interface File {
  url: string;
}

export interface ExtraDocument {
  email: string;
  uid: string;
  status: string;
  reason: string;
  comments?: string;
  upload_type?: string;
  files: File[];
  created_at: string;
}

interface Response {
  data: ExtraDocument[];
  total: number;
}

export const SWR_EXTRA_DOCUMENTS_KEY = (optionalParameters: string) => [
  "get-extra-documents",
  optionalParameters,
];

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `/admin/extra/pending?${optionalParameters}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useExtraDocumentsHistory = (optionalParameters: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_EXTRA_DOCUMENTS_KEY(optionalParameters),
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return { data, error, mutate, loading: isValidating };
};
