export interface Config {
  authUrl: string;
  complianceUrl: string;
  tradeUrl: string;
  paymentsUrl: string;
  tablePageLimit: number;
  msAlertDisplayTime: string;
  minutesUntilAutoLogout: string;
  withCredentials: boolean;
  captcha: {
    captchaType: "recaptcha" | "geetest" | "none";
    siteKey: string;
  };
}

export const defaultConfig: Config = {
  complianceUrl: "",
  authUrl: "",
  tradeUrl: "",
  paymentsUrl: "",
  msAlertDisplayTime: "5000",
  tablePageLimit: 100,
  minutesUntilAutoLogout: "5",
  withCredentials: false,
  captcha: {
    captchaType: "none",
    siteKey: "",
  },
};

export const Tower = {
  config: defaultConfig,
};

declare global {
  interface Window {
    env: Config;
  }
}

window.env = window.env || defaultConfig;
Tower.config = { ...window.env };

export const complianceUrl = () => Tower.config.complianceUrl;
export const tradeUrl = () => Tower.config.tradeUrl;
export const authUrl = () => Tower.config.authUrl;
export const paymentsUrl = () => Tower.config.paymentsUrl;
export const msAlertDisplayTime = () => Tower.config.msAlertDisplayTime;
export const tablePageLimit = () => Tower.config.tablePageLimit;
export const minutesUntilAutoLogout = () => Tower.config.minutesUntilAutoLogout;
export const withCredentials = () => Tower.config.withCredentials;
export const siteKey = (): string => Tower.config.captcha.siteKey;
