import React, { createContext } from "react";

import { useUser } from "../hooks/useUser";

export enum USER_LEVEL {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
}

export interface Profile {
  first_name: string;
  last_name: string;
  dob: Date;
  address: string;
  postcode: string;
  city: string;
  country: string;
  state: string;
  metadata: object;
}

export interface Documents {
  upload: string;
  doc_type: string;
  doc_number: string;
  doc_expire: string;
  metadata: string;
  created_at: string;
}

export interface UserLabel {
  uid: string;
  key: string;
  value: string;
  scope: string;
  created_at?: string;
  updated_at?: string;
}

export interface User {
  id?: number;
  email: string;
  level: USER_LEVEL;
  otp: boolean;
  role: string;
  state: string;
  uid: string;
  profile?: Profile;
  documents?: Documents;
  created_at?: string;
  updated_at?: string;
  labels?: UserLabel[];
}

export interface LoginForm {
  email: string;
  password: string;
  otp_code?: string;
  captcha_response: string;
}

interface UserContextInterface {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
  getUser: (redirectPath?: string) => Promise<void>;
  login: (form: LoginForm) => void;
  logout: () => void;
  loading: boolean;
  require2FA: boolean;
}

class VoidContext implements UserContextInterface {
  get user(): never {
    throw new Error("Cannot consume context outside of provider");
  }
  get setUser(): never {
    throw new Error("Cannot consume context outside of provider");
  }
  get getUser(): never {
    throw new Error("Cannot consume context outside of provider");
  }
  get loading(): never {
    throw new Error("Cannot consume context outside of provider");
  }
  get logout(): never {
    throw new Error("Cannot consume context outside of provider");
  }
  get login(): never {
    throw new Error("Cannot consume context outside of provider");
  }
  get require2FA(): never {
    throw new Error("Cannot consume context outside of provider");
  }
}

export const UserContext = createContext<UserContextInterface>(
  new VoidContext()
);

export const UserContextProvider: React.FC = ({ children }) => {
  const value = useUser();

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
