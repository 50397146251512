import React, { useState } from "react";
import { Paper } from "@material-ui/core";

import { PermissionsData, usePermissions } from "hooks/usePermissions";

import { tablePageLimit } from "api/config";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { PermissionsProps } from "./types";
import { EditPermissionsForm } from "../../constants/permissions";
import EditPermissionsDialog from "components-ts/EditPermissionsDialog";
import DeleteConfirmationDialog from "components-ts/DeleteConfirmationDialog";
import { buildQueryString } from "helpers";
import CreatePermissionsDialog from "components-ts/CreatePermissionsDialog";

export const Permissions: React.FC<PermissionsProps> = () => {
  const permissionsRows = [
    { key: "role", alignRight: false, label: "Role" },
    { key: "verb", alignRight: false, label: "Verb" },
    { key: "path", alignRight: false, label: "Path" },
    { key: "topic", alignRight: false, label: "Topic" },
    { key: "action", alignRight: true, label: "Action" },
  ];

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const {
    createPermission,
    deletePermission,
    editPermission,
    data: permissionsList,
    mutate,
    loading,
  } = usePermissions(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
    })
  );

  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [onTarget, setOnTarget] = useState<PermissionsData>({
    action: undefined,
    id: undefined,
    path: undefined,
    role: undefined,
    topic: undefined,
    created_at: undefined,
    updated_at: undefined,
    verb: undefined,
  });

  const deleteItem = () => {
    setOpenDelete(false);
    if (onTarget.id) {
      deletePermission(+onTarget?.id);
    }
  };

  const editItem = (data: EditPermissionsForm) => {
    setOpenEdit(false);
    if (onTarget.id) {
      editPermission(+onTarget?.id, data);
    }
  };

  const createItem = (data: EditPermissionsForm) => {
    setOpenCreate(false);
    createPermission(data);
  };

  const editLimit = (item: PermissionsData) => {
    setOnTarget(item);
    setOpenEdit(true);
  };

  const deleteLimit = (item: PermissionsData) => {
    setOnTarget(item);
    setOpenDelete(true);
  };

  return (
    <React.Fragment>
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={permissionsList?.total}
          rows={permissionsRows}
          data={permissionsList?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={() => {}}
          hidePagination={false}
          tableTitle={"Permissions"}
          editLimit={editLimit}
          deleteLimit={deleteLimit}
          mutateData={mutate}
          newItem={() => setOpenCreate(true)}
          loading={loading}
        />
      </Paper>
      <EditPermissionsDialog
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        handleAction={editItem}
        defaultForm={{
          role: onTarget.role,
          path: onTarget.path,
          topic: onTarget.topic ? onTarget.topic : undefined,
          verb: onTarget.verb,
          action: onTarget.action,
        }}
      ></EditPermissionsDialog>

      <CreatePermissionsDialog
        open={openCreate}
        handleClose={() => setOpenCreate(false)}
        handleAction={createItem}
      />

      <DeleteConfirmationDialog
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleAction={deleteItem}
      ></DeleteConfirmationDialog>
    </React.Fragment>
  );
};

export default Permissions;
