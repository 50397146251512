import { Typography } from "@material-ui/core";

export const prettyPrintObject = (
  object: Object,
  classNameTitle: string,
  classNameItem: string
) => {
  return Object.entries(object).map(([field, data]) => (
    <div className="withdraw-info__blockItem" key={field}>
      <Typography className={classNameTitle} variant="h5" component="h5">
        {field.replace(/_/g, " ")}
      </Typography>
      <Typography className={classNameItem} component="p">
        {typeof data === "object" && data !== null
          ? prettyPrintObject(data, classNameTitle, classNameItem)
          : data}
      </Typography>
    </div>
  ));
};
