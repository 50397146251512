import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { convertToObj } from "helpers/convertToObj";
import { useBlackList } from "hooks/useBlackList";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";

export const BlackListTable: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);
  const { data: blackList, mutate, loading } = useBlackList(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ordering: "desc",
      ...convertToObj(data),
    })
  );
  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  blackList?.data.sort((a, b) => {
    switch (a.state) {
      case b.state:
        return 0;
      case "pending":
        return -1;
      case "rejected":
        return 1;
      case "accepted":
        return b.state === "rejected" ? -1 : 1;
      default:
        return 0;
    }
  });

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  const handleRowOnClick = (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => {
    history.push(`/tower/users/blacklist/${item.id}`);
  };

  const tableRows = [
    { key: "customer_national_id", alignRight: false, label: "National ID" },
    { key: "customer_cid", alignRight: false, label: "CID" },
    { key: "state", alignRight: false, label: "State" },
    { key: "member_email", alignRight: false, label: "Mail" },
  ];

  const selectedValues = [
    {
      value: "state",
      label: "State",
      checked: false,
    },
    {
      value: "customer_cid",
      label: "CID",
      checked: false,
    },
    {
      value: "customer_national_id",
      label: "National ID",
      checked: false,
    },
    {
      value: "member_email",
      label: "Mail",
      checked: false,
    },
  ];

  return (
    <>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={blackList?.total}
          rows={tableRows}
          data={blackList?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={handleRowOnClick}
          hidePagination={false}
          tableTitle={"Black List"}
          mutateData={mutate}
          loading={loading}
        />
      </Paper>
    </>
  );
};

export default BlackListTable;
