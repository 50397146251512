import { Layout } from "components-ts/Layout";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "./providers/UserProvider";
import { AppRouter } from "./router";

export const App = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { getUser, loading, user, logout } = useContext(UserContext);

  useEffect(() => {
    if (!user && !loading) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);

  useEffect(() => {
    if (user) {
      setIsAuthenticated(!!user.email && user.state === "active");
      setOpen(true);
    } else {
      setIsAuthenticated(false);
      setOpen(false);
    }
  }, [user]);

  return (
    <Layout
      logout={logout}
      loggedIn={isAuthenticated}
      handleDrawerOpen={() => setOpen(true)}
      handleDrawerClose={() => setOpen(false)}
      open={open}
      isSuperAdmin={(user && user.role === "superadmin") || false}
    >
      <AppRouter
        userLoading={loading}
        isCurrentSession={isAuthenticated}
        logout={logout}
        user={user}
      />
    </Layout>
  );
};
