import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core/styles/withStyles";

export interface RowItemInterface {
  key: string;
  alignRight: boolean;
  label: string;
}

export interface InfoTableProps {
  dataLength?: number;
  rows: RowItemInterface[];
  data?: any[];
  page: number;
  rowsPerPage: number;
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (count: string) => void;
  handleRowOnClick: (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => void;
  hidePagination?: boolean;
  tableTitle: string;
  editLimit?: (n: any) => void;
  deleteLimit?: (n: any) => void;
  newItem?: () => void;
  loading: boolean;
  mutateData: any;
}

export interface StyleProps extends WithStyles<typeof styles> {
  theme: Theme;
}

export type Props = StyleProps & InfoTableProps;

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      color: "rgba(0, 0, 0, 0.87)",
      border: "",
    },
    table: {
      minWidth: 1020,
    },
    tableTitle: {
      flex: "0 0 auto",
      color: "rgba(0, 0, 0, 0.87)",
      fontStyle: "normal",
      paddingLeft: "24px",
    },
    tableWrapper: {
      overflowX: "auto",
    },
    headerItem: {
      color: "rgba(0, 0, 0, 0.87)",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: 0.1,
    },
    row: {
      cursor: "pointer",
    },
    email: {
      cursor: "pointer",
      color: "#3598d5",
      fontSize: "0.75rem",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    link: {
      cursor: "pointer",
      textDecoration: "none",
      color: "#6e6e6e",
      fontSize: "12px",
    },
    tableItem: {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "12px",
      lineHeight: "16px",
      fontStyle: "normal",
      fontWeight: "normal",
      letterSpacing: 0.4,
    },
    tableItemStatus: {
      fontSize: "12px",
      lineHeight: "24px",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    red: {
      color: "#dd503d",
    },
    green: {
      color: "#43a047",
    },
    yellow: {
      color: "#d7a700",
    },
    blue: {
      color: "#309cea",
    },
    iconBackgroundNotActive: {
      color: "rgba(0, 0, 0, 0.3)",
    },
    iconBackground: {
      color: " #30adf3",
    },
    cardContent: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  });
