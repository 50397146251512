import React, { useState } from "react";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { UserHistoryProps } from "./types";
import { Paper } from "@material-ui/core";
import { tablePageLimit } from "api/config";

import { useDepositHistory } from "hooks/useDepositHistory";
import { buildQueryString } from "helpers/buildQueryString";
import { useWithdrawHistory } from "hooks/useWithdrawHistory";
import { useTrades } from "hooks/useTrades";
import { useOrders } from "hooks/useOrders";

export const UserHistory: React.FC<UserHistoryProps> = ({ match }) => {
  const [depositPage, setDepositPage] = useState<number>(0);
  const [withdrawPage, setWithdrawPage] = useState<number>(0);
  const [tradesPage, setTradesPage] = useState<number>(0);
  const [ordersPage, setOrdersPage] = useState<number>(0);

  const [depositRowsPerPage, setDepositRowsPerPage] = useState<number>(
    tablePageLimit()
  );
  const [withdrawRowsPerPage, setWithdrawRowsPerPage] = useState<number>(
    tablePageLimit()
  );
  const [tradesRowsPerPage, setTradesRowsPerPage] = useState<number>(
    tablePageLimit()
  );
  const [ordersRowsPerPage, setOrdersRowsPerPage] = useState<number>(
    tablePageLimit()
  );

  const [tradesOptionalParameters, setTradesOptionalParameters] = useState<
    string
  >(
    buildQueryString({
      uid: match.params.uid,
      ordering: "desc",
      limit: depositRowsPerPage,
      page: tradesPage,
    })
  );
  const [ordersOptionalParameters, setOrdersOptionalParameters] = useState<
    string
  >(
    buildQueryString({
      uid: match.params.uid,
      ordering: "desc",
      limit: ordersRowsPerPage,
      page: ordersPage,
    })
  );

  const {
    data: deposits,
    mutate: mutateDeposits,
    loading: loading_deposits,
  } = useDepositHistory(
    buildQueryString({
      uid: match.params.uid,
      ordering: "desc",
      limit: depositRowsPerPage,
      page: depositPage + 1,
    })
  );
  const {
    data: withdraws,
    mutate: mutateWithdraws,
    loading: loading_withdraws,
  } = useWithdrawHistory(
    buildQueryString({
      uid: match.params.uid,
      ordering: "desc",
      limit: withdrawRowsPerPage,
      page: withdrawPage + 1,
    })
  );
  const {
    data: trades,
    mutate: mutateTrades,
    loading: loading_trades,
  } = useTrades(tradesOptionalParameters);
  const {
    data: orders,
    mutate: mutateOrders,
    loading: loading_orders,
  } = useOrders(ordersOptionalParameters);

  const handleRowOnClickWithdraw = (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => {
    history.push(`/tower/withdraws/${item.tid}`);
  };

  const handleRowOnClickDeposit = (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => {
    history.push(`/tower/deposits/${item.tid}`);
  };

  const tableRows = [
    { key: "uid", alignRight: false, label: "UID" },
    { key: "tid", alignRight: false, label: "TID" },
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "amount", alignRight: false, label: "Amount" },
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "state", alignRight: true, label: "Status" },
  ];

  const TradeTableRows = [
    { key: "maker_order_email", alignRight: false, label: "Maker email" },
    { key: "taker_order_email", alignRight: false, label: "Taker email" },
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "taker_type", alignRight: false, label: "Taker Type" },
    { key: "market", alignRight: true, label: "Market" },
    { key: "price", alignRight: false, label: "Price" },
    { key: "amount", alignRight: false, label: "Amount" },
    { key: "taker_fee_amount", alignRight: false, label: "Taker Fee" },
  ];

  const OrderTableRows = [
    { key: "id", alignRight: false, label: "Order ID" },
    { key: "email", alignRight: false, label: "Email" },
    { key: "ord_type", alignRight: false, label: "Type" },
    { key: "market", alignRight: false, label: "Market" },
    { key: "origin_volume", alignRight: true, label: "Amount" },
    { key: "executed_volume", alignRight: true, label: "Executed" },
    { key: "price", alignRight: false, label: "Price" },
    { key: "avg_price", alignRight: true, label: "Average" },
    { key: "side", alignRight: true, label: "Side" },
    { key: "created_at", alignRight: false, label: "Created" },
    { key: "updated_at", alignRight: false, label: "Updated" },
    { key: "state", alignRight: false, label: "Status" },
  ];

  return (
    <Paper>
      <InfoTableComponent
        dataLength={deposits?.total || 1}
        rows={tableRows}
        data={deposits?.data}
        page={depositPage}
        rowsPerPage={depositRowsPerPage}
        handleChangePage={(page) => {
          setDepositPage(page);
        }}
        handleChangeRowsPerPage={(count) => {
          setDepositPage(0);
          setDepositRowsPerPage(Number(count));
        }}
        handleRowOnClick={handleRowOnClickDeposit}
        hidePagination={false}
        mutateData={mutateDeposits}
        loading={loading_deposits}
        tableTitle={"Deposits"}
      />
      <InfoTableComponent
        dataLength={withdraws?.total || 1}
        rows={tableRows}
        data={withdraws?.data}
        page={withdrawPage}
        rowsPerPage={withdrawRowsPerPage}
        handleChangePage={(page) => {
          setWithdrawPage(page);
        }}
        handleChangeRowsPerPage={(count) => {
          setWithdrawPage(0);
          setWithdrawRowsPerPage(Number(count));
        }}
        handleRowOnClick={handleRowOnClickWithdraw}
        hidePagination={false}
        mutateData={mutateWithdraws}
        loading={loading_withdraws}
        tableTitle={"Withdraws"}
      />
      <InfoTableComponent
        dataLength={trades?.total || 1}
        rows={TradeTableRows}
        data={trades?.data}
        page={tradesPage}
        rowsPerPage={tradesRowsPerPage}
        handleChangePage={(page) => {
          setTradesPage(page);
          setTradesOptionalParameters(
            buildQueryString({
              uid: match.params.uid,
              ordering: "desc",
              limit: tradesRowsPerPage,
              page: page + 1,
            })
          );
        }}
        handleChangeRowsPerPage={(count) => {
          setTradesPage(0);
          setTradesRowsPerPage(Number(count));
          setTradesOptionalParameters(
            buildQueryString({
              uid: match.params.uid,
              ordering: "desc",
              limit: Number(count),
              page: 0,
            })
          );
        }}
        handleRowOnClick={() => {}}
        hidePagination={false}
        mutateData={mutateTrades}
        loading={loading_trades}
        tableTitle={"Trades"}
      />
      <InfoTableComponent
        dataLength={orders?.total || 1}
        rows={OrderTableRows}
        data={orders?.data}
        page={ordersPage}
        rowsPerPage={ordersRowsPerPage}
        handleChangePage={(page) => {
          setOrdersPage(page);
          setOrdersOptionalParameters(
            buildQueryString({
              uid: match.params.uid,
              ordering: "desc",
              limit: ordersRowsPerPage,
              page: page + 1,
            })
          );
        }}
        handleChangeRowsPerPage={(count) => {
          setOrdersPage(0);
          setOrdersRowsPerPage(Number(count));
          setOrdersOptionalParameters(
            buildQueryString({
              uid: match.params.uid,
              ordering: "desc",
              limit: Number(count),
              page: 0,
            })
          );
        }}
        handleRowOnClick={() => {}}
        hidePagination={false}
        mutateData={mutateOrders}
        loading={loading_orders}
        tableTitle={"Orders"}
      />
    </Paper>
  );
};

export default UserHistory;
