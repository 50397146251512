import { BARONG_API_END_POINT_ADMIN_IDENTITY_SESSIONS, BARONG_API_END_POINT_ADMIN_RESOURCE_SESSIONS_ME } from "api/apiEndpoints";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { API, RequestOptions } from "../api";
import { LoginForm, User } from "../providers/UserProvider";

const requestOptions: RequestOptions = {
  apiVersion: "barong",
};

type useUserType = {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  getUser: (redirectPath?: string | undefined) => Promise<void>;
  loading: boolean;
  logout: (path?: string | undefined) => Promise<void>;
  login: (form: LoginForm) => Promise<void>;
  require2FA: boolean;
};

export const useUser = (): useUserType => {
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [require2FA, setRequire2FA] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const redirectPathRef = useRef<string>(
    `${location.pathname}${location.search}`
  );

  const uid = user && user.uid;
  const userState = user && user.state;

  const isAdmin = (user) => {
    return [
      "admin",
      "superadmin",
      "finance_analist",
      "compliance",
      "it_support",
      "customer_support",
      "operations",
      "otc"
    ].includes(user.role);
  };

  useEffect(() => {
    const isUserPending = userState === "pending";

    if (uid && !isUserPending) {
      history.push(redirectPathRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, userState]);
  const logout = useCallback(async () => {
    try {
      API.delete(requestOptions)(BARONG_API_END_POINT_ADMIN_IDENTITY_SESSIONS);
      sessionStorage.clear();
      setUser(undefined);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message[0] || "", {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = useCallback(async () => {
    setLoading(true);
    try {
      const { data: user }: { data: User } = await API.get(requestOptions)(
        BARONG_API_END_POINT_ADMIN_RESOURCE_SESSIONS_ME
      );

      if (!isAdmin(user)) {
        enqueueSnackbar("invalid account", {
          variant: "error",
        });
        logout();
      } else {
        setUser(user);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (form: LoginForm): Promise<void> => {
    const onSucess = (user: User): void => {
      if (user.state === "pending" || !isAdmin(user)) {
        enqueueSnackbar("invalid account", {
          variant: "error",
        });
        logout();
      } else {
        setUser(user);
      }
    };

    const onError = (
      error: { error_code: number; message: Array<string> } | undefined
    ): void => {
      if (error) {
        const errorCode = error ? error.message[0] : "";
        if (errorCode === "identity.session.missing_otp") {
          setRequire2FA(true);
        } else {
          enqueueSnackbar(errorCode, {
            variant: "error",
          });
        }
      }
    };

    try {
      setUser(undefined);
      const { data: user }: { data: User } = await API.post(requestOptions)(
        BARONG_API_END_POINT_ADMIN_IDENTITY_SESSIONS,
        form
      );

      onSucess(user);
    } catch (error) {
      onError(error);
    }
  };

  return {
    user,
    setUser,
    getUser,
    loading,
    logout,
    login,
    require2FA,
  };
};
