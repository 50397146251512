import { useEffect } from "react";
import { API } from "api";
import { RequestOptions } from "api/requestBuilder";
import useSWR from "swr";
import { PEATIO_API_END_POINT_CURRENCIES } from "../api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "peatio",
};

export interface Currency {
  name: string;
  symbol: string;
  explorer_transaction: string;
  explorer_address: string;
  type: string;
  deposit_enabled: string;
  withdrawal_enabled: string;
  deposit_fee: string;
  min_deposit_amount: string;
  withdraw_fee: string;
  min_withdraw_amount: string;
  withdraw_limit_24h: string;
  withdraw_limit_72h: string;
  base_factor: number;
  precision: number;
  position: number;
  icon_url: string;
  min_confirmations: string;
  code: string;
  blockchain_key: string;
  min_collection_amount: number;
  visible: string;
  subunits: number;
  options: {};
  created_at: string;
  updated_at: string;
}

const fetcher = async (): Promise<Currency[]> => {
  const { data: currencies } = await API.get(requestOptions)(
    PEATIO_API_END_POINT_CURRENCIES
  );
  return currencies;
};

export const useCurrencies = () => {
  const { data, error, mutate } = useSWR("get-currencies", fetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, error, mutate]);

  return {
    data,
    error,
    mutate,
  };
};
