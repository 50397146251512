import { useEffect } from "react";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { API, RequestOptions } from "../api";
import { BARONG_API_END_POINT_ADMIN_USERS } from "api/apiEndpoints";

const getMembersOptions: RequestOptions = {
  apiVersion: "barong",
};

export interface User {
  email: string;
  uid: string;
  role: string;
  level: number;
  otp: boolean;
  state: string;
  referral_uid?: string;
  username: string;
  data: string;
}

interface Response {
  data: User[];
  total: number;
}

export const SWR_BARONG_MEMBERS_KEY = (optionalParams?: string) => [
  "get-barong-members",
  optionalParams,
];

const fetcher = async (
  _key: string,
  optionalParams?: string
): Promise<Response> => {
  const { data, headers } = await API.get(getMembersOptions)(
    `${BARONG_API_END_POINT_ADMIN_USERS}?${optionalParams}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useBarongMembers = (optionalParams?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_BARONG_MEMBERS_KEY(optionalParams),
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return {
    data,
    error,
    mutate,
    loading: isValidating,
  };
};
