import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { BARONG_API_END_POINT_ADMIN_ACTIVITIES } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "barong",
};

export interface UserActivityData {
  id: number;
  action: string;
  created_at: string;
  data: null;
  result: string;
  topic: string;
  user: {
    email: string;
    level: number;
    otp: boolean;
    state: string;
    uid: string;
  };
  user_agent: string;
  user_ip: string;
}

interface Response {
  data: UserActivityData[];
  total: number;
}

export const SWR_USER_ACTIVITY_KEY = (optionalParameters: string) => [
  "get-user-activity",
  optionalParameters,
];

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${BARONG_API_END_POINT_ADMIN_ACTIVITIES}?${optionalParameters}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useActivity = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_USER_ACTIVITY_KEY(optionalParameters || ""),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return { data, error, mutate, loading: isValidating };
};
