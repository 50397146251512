import React from "react";
import { Button } from "@material-ui/core";
import { Clear, Done, Folder } from "@material-ui/icons";

import { ActionButtonsProps } from "./types";

import "./styles.scss";

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  actionButtons,
  originalData,
}) => {
  const filteredButtons = actionButtons.filter((data) => {
    return data.state.includes(originalData.state);
  });

  return (
    <div className="action-buttons__beneficiaryInfoBlockBodyItem">
      <div className="action-buttons__blockButtons">
        {filteredButtons.map((data, index) => {
          return (
            <>
              {data.variant === "accept" && (
                <Button
                  variant="contained"
                  className="action-buttons__buttonAccept"
                  onClick={data.onClick}
                >
                  {data.value}
                  <Done className="action-buttons__buttonIcon" />
                </Button>
              )}

              {data.variant === "archive" && (
                <Button
                  variant="contained"
                  className="action-buttons__buttonArchive"
                  onClick={data.onClick}
                >
                  {data.value}
                  <Folder className="action-buttons__buttonIcon" />
                </Button>
              )}

              {data.variant === "reject" && (
                <Button
                  variant="contained"
                  className="action-buttons__buttonReject"
                  onClick={data.onClick}
                >
                  {data.value}
                  <Clear className="action-buttons__buttonIcon" />
                </Button>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ActionButtons;
