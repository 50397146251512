import { API, RequestOptions } from "api";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import useSWR from "swr";

import {
  ACTIONS,
  EditPermissionsForm,
  ROLES,
  VERBS,
} from "../constants/permissions";
import { BARONG_API_END_POINT_ADMIN_PERMISSIONS } from "../api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "barong",
};

export interface PermissionsData {
  action?: ACTIONS;
  id?: string;
  path?: string;
  role?: ROLES;
  topic?: string;
  created_at?: string;
  updated_at?: string;
  verb?: VERBS;
}

export const SWR_PERMISSIONS_KEY = (optionalParams?: string) => [
  "get-permissions",
  optionalParams,
];

const fetcher = async (_key, optionalParams?: string) => {
  const { data, headers } = await API.get(requestOptions)(
    BARONG_API_END_POINT_ADMIN_PERMISSIONS + "?" + optionalParams
  );

  const permissions = {
    data: data,
    total: +headers.total,
  };

  return permissions;
};

export const usePermissions = (optionalParams?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_PERMISSIONS_KEY(optionalParams),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const deletePermission = async (id: number) => {
    try {
      await API.delete(requestOptions)(BARONG_API_END_POINT_ADMIN_PERMISSIONS, {
        id: id,
      });
      enqueueSnackbar("Permission was deleted", { variant: "success" });
      mutate();
    } catch (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  const editPermission = async (id: number, data: EditPermissionsForm) => {
    try {
      await API.put(requestOptions)(BARONG_API_END_POINT_ADMIN_PERMISSIONS, {
        id: id,
        ...data,
      });
      enqueueSnackbar("Permission was edited", { variant: "success" });
      mutate();
    } catch (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  const createPermission = async (data: EditPermissionsForm) => {
    try {
      await API.post(requestOptions)(
        BARONG_API_END_POINT_ADMIN_PERMISSIONS,
        data
      );
      enqueueSnackbar("Permission was created", { variant: "success" });
      mutate();
    } catch (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  return {
    data,
    error,
    mutate,
    createPermission,
    deletePermission,
    editPermission,
    loading: isValidating,
  };
};
