import { useEffect } from "react";
import { API } from "api";
import { RequestOptions } from "api/requestBuilder";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { PEATIO_API_END_POINT_MEMBERS } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "peatio",
};

export interface Account {
  currency: string;
  balance: number;
  locked: number;
}

export interface PeatioMember {
  uid: string;
  email: string;
  accounts: Account[];
  id: number;
  level: number;
  role: string;
  group: string;
  state: string;
  created_at: string;
  updated_at: string;
}

const SWR_PEATIO_MEMBERS_KEY = (uid) => ["get-peatio-members", uid];

const fetcher = async (_key: string, uid: string): Promise<PeatioMember> => {
  const { data } = await API.get(requestOptions)(`${PEATIO_API_END_POINT_MEMBERS}/${uid}`);

  return data;
};

export const usePeatioMembers = (uid: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR(SWR_PEATIO_MEMBERS_KEY(uid), fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return {
    data,
    error,
    mutate,
  };
};
