import React from "react";
import { UserLabelProps } from "./types";
import { Button, Grid, SvgIcon, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { EditLabelModal } from "../../EditLabelModal";

import "./styles.scss";

export const UserLabel: React.FC<UserLabelProps> = ({
  user,
  closeModal,
  deleteUserLabel,
  newLabelName,
  newLabelValue,
  newLabelScope,
  addNewLabel,
  editLabel,
  isEditLabelModalOpened,
  isAddLabelModalOpened,
  openAddLabelModal,
  openEditLabelModal,
}) => {
  // tslint:disable-next-line:no-any
  const renderContent = () =>
    user.labels &&
    user.labels.map((label: any, i: number) => getLabelData(label, i));

  // tslint:disable-next-line:no-any
  const getLabelData = (label: any, i: number) => {
    switch (label.key) {
      case "email":
        return (
          <Grid item={true} key={i}>
            <Grid
              container={true}
              justify={"space-between"}
              className="user-label__label"
              style={{ backgroundColor: "#7EB732" }}
            >
              <Typography
                onClick={(e) =>
                  openEditLabelModal(label.key, label.value, label.scope)
                }
                className="user-label__labelName"
              >
                email:{label.value}
              </Typography>
              <SvgIcon
                onClick={(e) => deleteLabel(user.uid, label.key, label.scope)}
                className="user-label__icon"
                viewBox="0 0 32 32"
              >
                <path
                  d="m15,0.25c8.15675,0 14.75,6.59325 14.75,14.75c0,8.15675 -6.59325,14.75 -14.75,14.75c-8.15675,0 -14.75,-6.59325 -14.75,-14.75c0,-8.15675 6.59325,-14.75 14.75,-14.75m5.29525,7.375l-5.29525,5.29525l-5.29525,-5.29525l-2.07975,2.07975l5.29525,5.29525l-5.29525,5.29525l2.07975,2.07975l5.29525,-5.29525l5.29525,5.29525l2.07975,-2.07975l-5.29525,-5.29525l5.29525,-5.29525l-2.07975,-2.07975z"
                  id="svg_1"
                  fill="#ffffff"
                  stroke="null"
                />
              </SvgIcon>
            </Grid>
          </Grid>
        );
      case "profile":
        return (
          <Grid item={true} key={i}>
            <Grid
              container={true}
              justify={"space-between"}
              className="user-label__label"
              style={{ backgroundColor: "#43A047" }}
            >
              <Typography
                onClick={(e) =>
                  openEditLabelModal(label.key, label.value, label.scope)
                }
                className="user-label__labelName"
              >
                profile:{label.value}
              </Typography>
              <SvgIcon
                onClick={(e) => deleteLabel(user.uid, label.key, label.scope)}
                className="user-label__icon"
                viewBox="0 0 32 32"
              >
                <path
                  d="m15,0.25c8.15675,0 14.75,6.59325 14.75,14.75c0,8.15675 -6.59325,14.75 -14.75,14.75c-8.15675,0 -14.75,-6.59325 -14.75,-14.75c0,-8.15675 6.59325,-14.75 14.75,-14.75m5.29525,7.375l-5.29525,5.29525l-5.29525,-5.29525l-2.07975,2.07975l5.29525,5.29525l-5.29525,5.29525l2.07975,2.07975l5.29525,-5.29525l5.29525,5.29525l2.07975,-2.07975l-5.29525,-5.29525l5.29525,-5.29525l-2.07975,-2.07975z"
                  id="svg_1"
                  fill="#ffffff"
                  stroke="null"
                />
              </SvgIcon>
            </Grid>
          </Grid>
        );
      case "identity":
        return (
          <Grid item={true} key={i}>
            <Grid
              container={true}
              justify={"space-between"}
              className="user-label__label"
              style={{ backgroundColor: "#3BBF82" }}
            >
              <Typography
                onClick={(e) =>
                  openEditLabelModal(label.key, label.value, label.scope)
                }
                className="user-label__labelName"
              >
                identity:{label.value}
              </Typography>
              <SvgIcon
                onClick={(e) => deleteLabel(user.uid, label.key, label.scope)}
                className="user-label__icon"
                viewBox="0 0 32 32"
              >
                <path
                  d="m15,0.25c8.15675,0 14.75,6.59325 14.75,14.75c0,8.15675 -6.59325,14.75 -14.75,14.75c-8.15675,0 -14.75,-6.59325 -14.75,-14.75c0,-8.15675 6.59325,-14.75 14.75,-14.75m5.29525,7.375l-5.29525,5.29525l-5.29525,-5.29525l-2.07975,2.07975l5.29525,5.29525l-5.29525,5.29525l2.07975,2.07975l5.29525,-5.29525l5.29525,5.29525l2.07975,-2.07975l-5.29525,-5.29525l5.29525,-5.29525l-2.07975,-2.07975z"
                  id="svg_1"
                  fill="#ffffff"
                  stroke="null"
                />
              </SvgIcon>
            </Grid>
          </Grid>
        );
      case "phone":
        return (
          <Grid item={true} key={i}>
            <Grid
              container={true}
              justify={"space-between"}
              className="user-label__label"
              style={{ backgroundColor: "#309CEA" }}
            >
              <Typography
                onClick={(e) =>
                  openEditLabelModal(label.key, label.value, label.scope)
                }
                className="user-label__labelName"
              >
                phone:{label.value}
              </Typography>
              <SvgIcon
                onClick={(e) => deleteLabel(user.uid, label.key, label.scope)}
                className="user-label__icon"
                viewBox="0 0 32 32"
              >
                <path
                  d="m15,0.25c8.15675,0 14.75,6.59325 14.75,14.75c0,8.15675 -6.59325,14.75 -14.75,14.75c-8.15675,0 -14.75,-6.59325 -14.75,-14.75c0,-8.15675 6.59325,-14.75 14.75,-14.75m5.29525,7.375l-5.29525,5.29525l-5.29525,-5.29525l-2.07975,2.07975l5.29525,5.29525l-5.29525,5.29525l2.07975,2.07975l5.29525,-5.29525l5.29525,5.29525l2.07975,-2.07975l-5.29525,-5.29525l5.29525,-5.29525l-2.07975,-2.07975z"
                  id="svg_1"
                  fill="#ffffff"
                  stroke="null"
                />
              </SvgIcon>
            </Grid>
          </Grid>
        );
      case "document":
        return (
          <Grid item={true} key={i}>
            <Grid
              container={true}
              justify={"space-between"}
              className="user-label__label"
              style={{ backgroundColor: "#3F51B5" }}
            >
              <Typography
                onClick={(e) =>
                  openEditLabelModal(label.key, label.value, label.scope)
                }
                className="user-label__labelName"
              >
                document:{label.value}
              </Typography>
              <SvgIcon
                onClick={(e) => deleteLabel(user.uid, label.key, label.scope)}
                className="user-label__icon"
                viewBox="0 0 32 32"
              >
                <path
                  d="m15,0.25c8.15675,0 14.75,6.59325 14.75,14.75c0,8.15675 -6.59325,14.75 -14.75,14.75c-8.15675,0 -14.75,-6.59325 -14.75,-14.75c0,-8.15675 6.59325,-14.75 14.75,-14.75m5.29525,7.375l-5.29525,5.29525l-5.29525,-5.29525l-2.07975,2.07975l5.29525,5.29525l-5.29525,5.29525l2.07975,2.07975l5.29525,-5.29525l5.29525,5.29525l2.07975,-2.07975l-5.29525,-5.29525l5.29525,-5.29525l-2.07975,-2.07975z"
                  id="svg_1"
                  fill="#ffffff"
                  stroke="null"
                />
              </SvgIcon>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid item={true} key={i}>
            <Grid
              container={true}
              justify={"space-between"}
              className="user-label__label"
              style={{ backgroundColor: "#e0e0e0" }}
            >
              <Typography
                onClick={(e) =>
                  openEditLabelModal(label.key, label.value, label.scope)
                }
                style={{
                  paddingTop: 5,
                  color: "#757575",
                  fontSize: 14,
                  marginRight: 7,
                }}
              >
                {label.key}
              </Typography>
              <SvgIcon
                onClick={(e) => deleteLabel(user.uid, label.key, label.scope)}
                className="user-label__icon"
                viewBox="0 0 32 32"
              >
                <path
                  d="m15,0.25c8.15675,0 14.75,6.59325 14.75,14.75c0,8.15675 -6.59325,14.75 -14.75,14.75c-8.15675,0 -14.75,-6.59325 -14.75,-14.75c0,-8.15675 6.59325,-14.75 14.75,-14.75m5.29525,7.375l-5.29525,5.29525l-5.29525,-5.29525l-2.07975,2.07975l5.29525,5.29525l-5.29525,5.29525l2.07975,2.07975l5.29525,-5.29525l5.29525,5.29525l2.07975,-2.07975l-5.29525,-5.29525l5.29525,-5.29525l-2.07975,-2.07975z"
                  id="svg_1"
                  fill="#979797"
                  stroke="null"
                />
              </SvgIcon>
            </Grid>
          </Grid>
        );
    }
  };

  const modalClose = () => {
    closeModal();
  };

  const deleteLabel = (uid: string, key: string, scope: string) => {
    deleteUserLabel(uid, key, scope);
  };

  return (
    <div className="user-label__paper">
      <Grid
        item={true}
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          variant="h6"
          gutterBottom={true}
          className="user-label__title"
          style={{ paddingLeft: 0, paddingTop: 0 }}
        >
          Labels
        </Typography>
        <Grid item={true}>
          <Button
            onClick={(e) => openAddLabelModal()}
            disableFocusRipple={true}
          >
            <AddCircleOutline style={{ color: "#979797", opacity: 0.6 }} />
          </Button>
        </Grid>
      </Grid>
      <Grid container={true} justify={"flex-start"} spacing={2}>
        {user && user.labels && user.labels.length === 0 ? (
          <Typography
            variant="h6"
            gutterBottom={true}
            style={{ color: "#757575", marginLeft: 8 }}
          >
            No labels
          </Typography>
        ) : (
          renderContent()
        )}
        <EditLabelModal
          editLabel={addNewLabel}
          modalClose={modalClose}
          open={isAddLabelModalOpened}
        />
        <EditLabelModal
          editLabel={editLabel}
          modalClose={modalClose}
          open={isEditLabelModalOpened}
          defaultForm={{
            key: newLabelName,
            value: newLabelValue,
            scope: newLabelScope,
          }}
        />
      </Grid>
    </div>
  );
};

export default UserLabel;
