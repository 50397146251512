import React, { ChangeEvent, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import { Clear, Done } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/Create";
import { useSnackbar } from "notistack";
import { UnmatchedDepositInfoProps } from "./types";
import { useUnmatchedDeposit } from "hooks/useUnmatchedDeposit";

import "./styles.scss";

export const UnmatchedDepositInfo: React.FC<UnmatchedDepositInfoProps> = ({
  match,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: unmatchedDeposit,
    mutate: mutateDeposit,
    takeActionDeposit,
    updateDeposit,
  } = useUnmatchedDeposit(match.params.id);
  const fileInput = useRef<HTMLInputElement>(null);

  const getStateItem = (value: string) => {
    let className;

    switch (value.toLowerCase()) {
      case "rejected":
        className = "unmatched-deposit-info__red";
        break;
      case "submitted":
        className = "unmatched-deposit-info__yellow";
        break;
      case "matched":
        className = "unmatched-deposit-info__green";
        break;
    }

    return <span className={className}>{value}</span>;
  };

  const handleRejectDeposit = async () => {
    await takeActionDeposit("reject");
    mutateDeposit();
  };

  const handleMatchDeposit = async () => {
    await takeActionDeposit("match");
    mutateDeposit();
  };

  const handleJSONinput = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.type === "application/json") {
        file.text().then(async (json) => {
          await updateDeposit(JSON.stringify(JSON.parse(json)));
          mutateDeposit();
        });
      } else {
        enqueueSnackbar("Invalid file type", { variant: "error" });
      }
    }

    if (fileInput && fileInput.current) {
      fileInput.current.value = "";
    }
  };

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardContent className="unmatched-deposit-info__depositInfo">
          <div className="unmatched-deposit-info__depositInfoBlockBody">
            <div className="unmatched-deposit-info__depositInfoBlock">
              <Typography
                variant="h6"
                className="unmatched-deposit-info__emailTitle"
              >
                Unmatch deposit details
              </Typography>
            </div>
            <div className="unmatched-deposit-info__block">
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  TxId
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.txid}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Status
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {getStateItem(unmatchedDeposit?.state || "")}
                </Typography>
              </div>
            </div>
            <Divider
              variant="middle"
              className="unmatched-deposit-info__divider"
            />
          </div>
        </CardContent>

        <CardContent className="unmatched-deposit-info__depositInfo">
          <div className="unmatched-deposit-info__depositInfoBlockBody">
            <div className="unmatched-deposit-info__depositInfoBlock">
              <Typography
                variant="h6"
                className="unmatched-deposit-info__emailTitle"
              >
                Unmatch deposit data
              </Typography>
            </div>
            <div className="unmatched-deposit-info__block">
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  ID
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.id}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Type
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.type}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Start date
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.status}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Start date
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.start_date}
                </Typography>
              </div>

              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  End date
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.end_date || "-"}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Transactions IDs
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.transaction_ids}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  From
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  Bank id: {unmatchedDeposit?.data.from?.bank_id}
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  Account id:{" "}
                  {unmatchedDeposit?.data.account_number
                    ? unmatchedDeposit?.data.account_number
                    : unmatchedDeposit?.data.from?.account_id}
                </Typography>
              </div>

              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Details
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.details?.type}
                </Typography>
              </div>

              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Charge
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  Amount:{" "}
                  {unmatchedDeposit?.data.amount
                    ? unmatchedDeposit?.data.amount
                    : unmatchedDeposit?.data.charge?.value.amount}
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  Currency:{" "}
                  {unmatchedDeposit?.data.currency
                    ? unmatchedDeposit?.data.currency
                    : unmatchedDeposit?.data.charge?.value.currency}
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  Summary: {unmatchedDeposit?.data.charge?.summary}
                </Typography>
              </div>

              <Divider
                variant="middle"
                className="unmatched-deposit-info__divider"
              />
            </div>
          </div>
        </CardContent>

        <CardContent className="unmatched-deposit-info__depositInfo">
          <div className="unmatched-deposit-info__depositInfoBlockBody">
            <div className="unmatched-deposit-info__depositInfoBlock">
              <Typography
                variant="h6"
                className="unmatched-deposit-info__emailTitle"
              >
                Counterparty
              </Typography>
            </div>
            <div className="unmatched-deposit-info__block">
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Name
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.counterparty?.name}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  ID type
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.counterparty?.id_type}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  ID type
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.counterparty?.id}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Account scheme
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.counterparty?.account_routing?.scheme}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Account address
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {
                    unmatchedDeposit?.data.counterparty?.account_routing
                      ?.address
                  }
                </Typography>
              </div>

              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Bank scheme
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.counterparty?.bank_routing?.scheme}
                </Typography>
              </div>
              <div className="unmatched-deposit-info__blockItem">
                <Typography
                  className="unmatched-deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Bank address
                </Typography>
                <Typography
                  className="unmatched-deposit-info__blockItemInfo"
                  component="p"
                >
                  {unmatchedDeposit?.data.counterparty?.bank_routing?.address}
                </Typography>
              </div>
            </div>
          </div>
          {unmatchedDeposit && unmatchedDeposit.state !== "matched" && (
            <div className="unmatched-deposit-info__depositInfoBlockBodyItem">
              <div className="withdraw-info__blockButtons">
                <a
                  href={URL.createObjectURL(
                    new Blob([JSON.stringify(unmatchedDeposit.data, null, 2)], {
                      type: "application/json",
                    })
                  )}
                  download={`data_${match.params.id}.json`}
                >
                  Download json{" "}
                </a>
                <Button
                  variant="contained"
                  className="withdraw-info__buttonAccept"
                  onClick={handleMatchDeposit}
                >
                  Match <Done className="unmatched-deposit-info__buttonIcon" />
                </Button>
                <input
                  accept="application/json"
                  id="contained-button-file"
                  type="file"
                  className="unmatched-deposit-info__editInput"
                  onChange={handleJSONinput}
                  ref={fileInput}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                    <CreateIcon className="unmatched-deposit-info__buttonIcon" />
                  </Button>
                </label>
                <Button
                  variant="contained"
                  className="withdraw-info__buttonReject"
                  onClick={handleRejectDeposit}
                >
                  Reject{" "}
                  <Clear className="unmatched-deposit-info__buttonIcon" />
                </Button>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default UnmatchedDepositInfo;
