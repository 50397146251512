import { SvgIcon } from "@material-ui/core";
import {
  AccessTime,
  People,
  TrendingUp,
  SwapHoriz,
  ArrowUpward,
  ArrowDownward,
  HelpOutline,
  ControlPoint,
  AttachMoney,
} from "@material-ui/icons";
import BlockIcon from "@material-ui/icons/Block";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import * as React from "react";
import dashboard from "assets/dashboard.svg";
import activities from "assets/activities.svg";
import orders from "assets/orders.svg";
import orderbooks from "assets/orderbooks.svg";
import isotipo from "assets/isotipo.svg";

export const icons = (name: string) => {
  switch (name) {
    case "/tower":
      return <img className="navbar__img" src={dashboard} alt="" />;
    case "/tower/users":
      return <img className="navbar__img" src={isotipo} alt="" />;
    case "/tower/users/all":
      return <People />;
    case "/tower/users/blacklist":
      return <BlockIcon />;
    case "/tower/pending":
      return <AccessTime />;
    case "/tower/extra":
      return <ControlPoint />;
    case "/tower/activities":
      return <TrendingUp />;
    case "/tower/admin-activities":
      return <img className="navbar__img" src={activities} alt="" />;
    case "/tower/orders":
      return <img className="navbar__img" src={orders} alt="" />;
    case "/tower/orderbooks":
      return <img className="navbar__img" src={orderbooks} alt="" />;
    case "/tower/trades":
      return <SwapHoriz />;
    case "/tower/withdraws":
      return <ArrowUpward />;
    case "/tower/deposits":
      return <ArrowDownward />;
    case "/tower/unmatched":
      return <HelpOutline />;
    case "/tower/permissions":
      return <LockRoundedIcon />;
    case "/tower/beneficiaries":
      return <ContactMailIcon />;
    case "/tower/adjustments":
      return <AttachMoney />;
    default:
      return <SvgIcon />;
  }
};
