import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import FormSelectOptions from "components-ts/FormSelectOption";
import { useCurrencies } from "hooks/useCurrencies";
import {
  ASSET_OPTIONS,
  CATEGORY_OPTIONS,
  RECEIVING_CODE_OPTIONS,
} from "constants/adjustments";
import { CreateAdjustmentDialogProps } from "./types";

import "./styles.scss";

export const CreateAdjustmentDialog: React.FC<CreateAdjustmentDialogProps> = ({
  handleClose,
  open,
  handleAction,
}) => {
  const { data: currencies } = useCurrencies();
  const { handleSubmit, control, reset } = useForm();

  const onSubmit = (data) => {
    const createAdjustmentForm = {
      receiving_member_uid: data?.receiving_member_uid,
      ...data,
    };
    handleAction(createAdjustmentForm);
    reset();
  };

  const CURRENCY_OPTIONS =
    currencies &&
    currencies.map((currency) => {
      return { label: currency.code, value: currency.code };
    });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>CREATE ADJUSTMENT</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText className="create-adjustment-dialog__content">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Reason"
                  helperText={error?.message}
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="reason"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Description"
                  helperText={error?.message}
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="description"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={CURRENCY_OPTIONS || []}
                  error={invalid}
                  errorMessage={error?.message}
                  label="Currency"
                />
              )}
              control={control}
              name="currency_id"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={CATEGORY_OPTIONS}
                  error={invalid}
                  errorMessage={error?.message}
                  label="Category"
                />
              )}
              control={control}
              name="category"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Amount"
                  helperText={error?.message}
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="amount"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={ASSET_OPTIONS}
                  label="Asset code"
                  error={invalid}
                  errorMessage={error?.message}
                />
              )}
              control={control}
              name="asset_account_code"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={RECEIVING_CODE_OPTIONS}
                  label="Receiving account code"
                  error={invalid}
                  errorMessage={error?.message}
                />
              )}
              control={control}
              name="receiving_account_code"
              rules={{ required: "Required" }}
            />

            <Controller
              render={({ field: { onChange, value } }) => (
                <TextField
                  style={{ margin: 15 }}
                  label="UID"
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="receiving_member_uid"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
          <Button color="primary" type="submit">
            CREATE
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateAdjustmentDialog;
