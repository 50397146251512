import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';

import { FormSelectOptionsProps } from './types';

import './style.scss';

export const FormSelectOptions: React.FC<FormSelectOptionsProps<string>> = ({
  optionList,
  label,
  value,
  onChange,
  error,
  errorMessage,
}) => {
  return (
    <TextField
      className="form-select-option"
      variant="outlined"
      select
      error={error}
      label={label}
      helperText={errorMessage}
      value={value}
      onChange={onChange}
    >
      {optionList.map((data) => (
        <MenuItem value={data.value} key={data.value}>
          {data.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default FormSelectOptions;
