import React, { useEffect } from "react";
import { Button, Grid, Modal, TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { EditLabelModalProps } from "./types";
import FormSelectOptions from "components-ts/FormSelectOption";

import "./styles.scss";

export const EditLabelModal: React.FC<EditLabelModalProps> = ({
  open,
  modalClose,
  editLabel,
  defaultForm,
}) => {
  const scopeTypes = [
    {
      label: "Public",
      value: "public",
    },
    {
      label: "Private",
      value: "private",
    },
  ];

  const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    reset(defaultForm);
  }, [defaultForm, reset]);

  const onSubmit = (labelForm) => {
    editLabel(labelForm.key, labelForm.value, labelForm.scope);
    reset();
    modalClose();
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const cancelEditingLabel = () => {
    reset();
    modalClose();
  };

  return (
    <Modal
      open={open}
      onClose={cancelEditingLabel}
      onKeyPress={handleEnterKeyPress}
    >
      <Grid
        container={true}
        direction={"column"}
        className="edit-label-modal__paper"
      >
        <Grid item={true}>
          <Typography
            variant="h5"
            component="h5"
            className="edit-label-modal__textField"
          >
            Edit Label
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item={true} className="edit-label-modal__items">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Key"
                  helperText={error?.message}
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="key"
              rules={{ required: "Required" }}
            />
          </Grid>
          <Grid item={true} className="edit-label-modal__items">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <TextField
                  style={{ margin: 15 }}
                  error={invalid}
                  label="Value"
                  helperText={error?.message}
                  variant="outlined"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name="value"
              rules={{ required: "Required" }}
            />
          </Grid>
          <Grid item={true} className="edit-label-modal__items">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormSelectOptions
                  onChange={onChange}
                  value={value}
                  optionList={scopeTypes}
                  label="Scope"
                  error={invalid}
                  errorMessage={error?.message}
                />
              )}
              control={control}
              name="scope"
              rules={{ required: "Required" }}
            />
          </Grid>
          <Grid item={true}>
            <Grid container={true} justify={"flex-end"} spacing={1}>
              <Grid item={true}>
                <Button onClick={cancelEditingLabel}>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ color: "#3598D5" }}
                  >
                    CANCEL
                  </Typography>
                </Button>
              </Grid>
              <Grid item={true}>
                <Button type="submit">
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{ color: "#3598D5" }}
                  >
                    OK
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Modal>
  );
};

export default EditLabelModal;
