import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { FormWithInvisibleRecaptchaProps } from "./types";
import { siteKey } from "api/config";
import clsx from "clsx";

import "./styles.scss";

export const FormWithInvisibleRecaptcha: React.FC<FormWithInvisibleRecaptchaProps> = ({
  onSubmit,
  className,
  children,
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  return (
    <form
      className={clsx("form-with-invisible-re-captcha", className)}
      onSubmit={async (e: any) => {
        e.preventDefault();
        if (recaptchaRef.current) {
          onSubmit(recaptchaRef.current)(e);
        }
      }}
      noValidate
    >
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey()} />
      {children}
      <p className="form-with-invisible-re-captcha__disclaimer">
        Este sitio está protegido por reCAPTCHA y aplican las{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Políticas de Privacidad
        </a>{" "}
        y los{" "}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Términos y Condiciones
        </a>{" "}
        de Google.
      </p>
    </form>
  );
};

export default FormWithInvisibleRecaptcha;
