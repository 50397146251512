import { useEffect } from "react";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { API, RequestOptions } from "../api";
import { COMPLIANCE_API_END_POINT_ADMIN_MEMBERS } from "api/apiEndpoints";

const getLimitsOptions: RequestOptions = {
  apiVersion: "compliance",
};

export interface UserMember {
  id: number;
  email: string;
  uid: string;
  level: number;
  state: string;
  role: string;
  type: string;
  partner: string;
}

interface Response {
  data: UserMember[];
  total: number;
}

export const SWR_MEMBERS_KEY = (optionalParams?: string) => [
  "get-members",
  optionalParams,
];

const fetcher = async (
  _key: string,
  optionalParams?: string
): Promise<Response> => {
  const { data, headers } = await API.get(getLimitsOptions)(
    `${COMPLIANCE_API_END_POINT_ADMIN_MEMBERS}?${optionalParams}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useMembers = (optionalParams?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_MEMBERS_KEY(optionalParams),
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return {
    data,
    error,
    mutate,
    loading: isValidating,
  };
};
