import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { UserData } from "hooks/useUserData";
import moment from "moment";
import React from "react";
import "../../../../node_modules/video-react/dist/video-react.css";
import { ImgMediaCard } from "./ImgCardComponent";
import { VideoMediaCard } from "./VideoCardComponent";

import "./styles.scss";

export interface UserKYCDocumentsProps {
  user: UserData;
  handleRejectVideo: (value: string) => void;
  handleRejectID: (value: string) => void;
  handleAcceptKYC: () => void;
}

export const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
});

const ChallengesCard = (props) => {
  return (
    <Card className="user-kyc-documents__card">
      <CardActionArea>
        <CardContent>
          <List>
            {props.challenge.challenge_description.map((item) => {
              return (
                <div>
                  <ListItem>
                    <Avatar src={item.image} />
                    <ListItemText primary={item.gesture_id} />
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const UserKYCDocuments = (props: UserKYCDocumentsProps) => {
  const checkID = () => {
    if (props.user)
      return (
        props.user.id_document && props.user.id_document.status === "submitted"
      );
    return false;
  };

  const checkVideo = () => {
    if (props.user)
      return (
        props.user.verification_video &&
        props.user.verification_video.status === "submitted"
      );
    return false;
  };
  const checkKYCVerified = () => {
    return checkID() && checkVideo();
  };

  const statusAndDate = (status: string, created_at: Date) => (
    <div>
      <div>State: {status}</div>
      <div>Date: {moment(created_at).format("MMMM Do YYYY")}</div>
    </div>
  );

  const { user } = props;
  const content = user ? (
    <Grid container>
      <Grid container justify="space-evenly">
        <Grid item>
          {user.id_document &&
            statusAndDate(user.id_document.status, user.id_document.created_at)}
          {user.id_document && (
            <ImgMediaCard
              img={user.id_document.file}
              checked={checkID()}
              handleRejectID={props.handleRejectID}
            />
          )}
        </Grid>
        <Grid item>
          {user.verification_video &&
            statusAndDate(
              user.verification_video.status,
              user.verification_video.created_at
            )}
          {user.verification_video && (
            <VideoMediaCard
              video={user.verification_video.video}
              fluid={false}
              height={200}
              checked={checkVideo()}
              handleRejectVideo={props.handleRejectVideo}
            />
          )}
        </Grid>
        <Grid item>
          {user.last_challenge && (
            <ChallengesCard challenge={user.last_challenge} />
          )}
        </Grid>
      </Grid>
      {checkKYCVerified() && (
        <Grid justify="flex-end" container direction="row">
          <Grid item>
            <Button
              size="small"
              color="primary"
              onClick={props.handleAcceptKYC}
            >
              Accept
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  ) : (
    <Typography
      variant="h6"
      gutterBottom={true}
      style={{ color: "#757575", paddingLeft: 26, paddingBottom: 26 }}
    >
      No documents
    </Typography>
  );
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        gutterBottom={true}
        className="user-kyc-documents__title"
      >
        KYC Documents
      </Typography>
      <div className="user-kyc-documents__paper">{content}</div>
    </React.Fragment>
  );
};
