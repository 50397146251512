import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { PEATIO_API_END_POINT_ADMIN_TRADES } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "peatio",
};

export interface TradesListItem {
  id: string;
  price: number;
  amount: number;
  total: number;
  market: string;
  created_at: string;
  taker_type: string;
  maker_order_email: string;
  maker_uid: string;
  maker_fee: number;
  maker_fee_amount: number;
  maker_fee_currency: string;
  taker_order_email: string;
  taker_uid: string;
  taker_fee_currency: string;
  taker_fee: number;
  taker_fee_amount: number;
}

interface Response {
  data: TradesListItem[];
  total: number;
}

export const SWR_USER_TRADES_KEY = (optionalParameters: string) => [
  "get-users-trades",
  optionalParameters,
];

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${PEATIO_API_END_POINT_ADMIN_TRADES}?${optionalParameters}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useTrades = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_USER_TRADES_KEY(optionalParameters || ""),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return { data, error, mutate, loading: isValidating };
};
