import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import * as React from "react";
import { Link } from "react-router-dom";
import {
  convertToOtp,
  getUserBrowser,
  getUserOS,
  localeDate,
} from "../../helpers";

import { InfoTableProps } from "./types";

import "./styles.scss";
import clsx from "clsx";

export const InfoTable: React.FC<InfoTableProps> = ({
  dataLength,
  rows,
  data,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  hidePagination,
  label,
  location,
  handleOpen,
  rejectNotification,
  acceptNotification,
}) => {
  const renderActionButtons = (n: any) => {
    const shouldRender = n["state"] !== "accepted" && n["state"] !== "rejected";
    return (
      shouldRender && (
        <>
          <Button
            style={{ marginRight: 10 }}
            variant="contained"
            color="primary"
            onClick={() => {
              if (acceptNotification) {
                acceptNotification(n.tid);
              }
            }}
          >
            ACCEPT
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (rejectNotification) {
                rejectNotification(n.tid);
              }
            }}
          >
            REJECT
          </Button>
        </>
      )
    );
  };

  const changePage = (event: any, page: number) => {
    handleChangePage && handleChangePage(page);
  };

  const changeRowsPerPage = (event) => {
    handleChangeRowsPerPage && handleChangeRowsPerPage(event.target.value);
  };

  const handleOpenCarousel = (i: number) => () => {
    handleOpen && handleOpen(i);
  };

  const getHeaderForTable = () => {
    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row: { key: string; alignRight: boolean; label: string }) => (
              <TableCell
                key={row.key}
                align={row.alignRight ? "right" : "left"}
              >
                <Typography
                  variant="subtitle2"
                  gutterBottom={true}
                  className="info-table__headers"
                >
                  {row.label}
                </Typography>
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  };

  const getColored = (state: string) => {
    return (
      <span
        className={clsx({
          "info-table__active":
            state === "active" ||
            state === "done" ||
            state === "accepted" ||
            state === "succeed",
          "info-table__banned": state === "rejected" || state === "canceled",
          "info-table__in-process":
            state !== "active" &&
            state !== "done" &&
            state !== "accepted" &&
            state !== "succeed" &&
            state !== "rejected" &&
            state !== "canceled",
        })}
      >
        {state}
      </span>
    );
  };

  const renderContent = () => {
    return (
      <div className="info-table__root">
        <div className="info-table__table-wrapper">
          <Table className="info-table__table" aria-labelledby="tableTitle">
            {getHeaderForTable()}
            <TableBody>
              {data.map((n: any, i: number) => {
                return (
                  <TableRow key={i}>
                    {rows.map((row: any, index: number) => {
                      return (
                        <TableCell
                          key={index}
                          component="td"
                          align={row.alignRight ? "right" : "left"}
                        >
                          {row.key === "email" ? (
                            <Link
                              to={`/tower/users/${n.uid}/main?`}
                              className="info-table__link"
                            >
                              {n.email}
                            </Link>
                          ) : row.key === "receipt" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              href={n.receipt.url}
                              target="_blank"
                            >
                              Receipt
                            </Button>
                          ) : row.key === "taker_order_email" ? (
                            <Link
                              to={`/tower/users/${n.taker_uid}/main`}
                              className="info-table__link"
                            >
                              {n.taker_order_email}
                            </Link>
                          ) : row.key === "maker_order_email" ? (
                            <Link
                              to={`/tower/users/${n.maker_uid}/main`}
                              className="info-table__link"
                            >
                              {n.maker_order_email}
                            </Link>
                          ) : row.key === "user_email" ? (
                            <Link
                              to={`${location && location.pathname}/${
                                n.user && n.user.uid
                              }`}
                              className="info-table__link"
                            >
                              {n.user && n.user.email}
                            </Link>
                          ) : row.key === "otp" ? (
                            convertToOtp(n.otp) === "true" ? (
                              "2FA"
                            ) : (
                              "-"
                            )
                          ) : row.key === "upload" ? (
                            <span className="info-table__attachment">
                              1{" "}
                              <AttachFile
                                onClick={handleOpenCarousel(i)}
                                className="info-table__grey-icon"
                              />
                            </span>
                          ) : row.key === "created_at" ||
                            row.key === "validated_at" ||
                            row.key === "updated_at" ? (
                            localeDate(n[row.key], "fullDate")
                          ) : row.key === "browser" ? (
                            getUserBrowser(n.user_agent)
                          ) : row.key === "os" ? (
                            getUserOS(n.user_agent)
                          ) : row.key === "result" ? (
                            getColored(n.result)
                          ) : row.key === "user_role" ? (
                            n.user && n.user.role
                          ) : row.key === "doc_expire" ? (
                            localeDate(n[row.key], "date")
                          ) : row.key === "state" ? (
                            getColored(n.state)
                          ) : row.key === "action" ? (
                            renderActionButtons(n)
                          ) : (
                            n[row.key]
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {!hidePagination ? (
          <TablePagination
            component="div"
            count={Number(dataLength)}
            rowsPerPage={rowsPerPage}
            page={page || 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
            classes={{ selectIcon: "info-table__select-icon" }}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className="info-table__root">
      {label && (
        <Typography
          variant="h6"
          gutterBottom={true}
          className="info-table__label"
        >
          {label}
        </Typography>
      )}
      {data.length ? renderContent() : null}
    </div>
  );
};
