import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { useOrders } from "hooks/useOrders";
import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { convertToObj } from "helpers/convertToObj";

export const OrdersScreen: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);
  const { data: orders, mutate, loading } = useOrders(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ordering: "desc",
      ...convertToObj(data),
    })
  );
  const activityRows = [
    { key: "email", alignRight: false, label: "Email" },
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "side", alignRight: false, label: "Type" },
    { key: "market", alignRight: true, label: "Market" },
    { key: "price", alignRight: false, label: "Price" },
    { key: "state", alignRight: false, label: "State" },
    { key: "origin_volume", alignRight: true, label: "Origin Volume" },
    { key: "executed_volume", alignRight: true, label: "Executed Volume" },
    { key: "trades_count", alignRight: true, label: "Trades Count" },
  ];

  const selectedValues = [
    {
      value: "market",
      label: "Market",
      checked: false,
    },
    {
      value: "state",
      label: "State",
      checked: false,
    },
    {
      value: "ord_type",
      label: "Order Type",
      checked: false,
    },
    {
      value: "price",
      label: "Price",
      checked: false,
    },
    {
      value: "origin_volume",
      label: "Origin Volume",
      checked: false,
    },
    {
      value: "type",
      label: "Type",
      checked: false,
    },
    {
      value: "email",
      label: "Email",
      checked: false,
    },
    {
      value: "uid",
      label: "UID",
      checked: false,
    },
  ];

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={orders?.total || 1}
          rows={activityRows}
          data={orders?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={() => { }}
          hidePagination={false}
          mutateData={mutate}
          loading={loading}
          tableTitle={"Orders"}
        />
      </Paper>
    </React.Fragment>
  );
};

export default OrdersScreen;
