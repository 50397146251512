import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useHistory } from "react-router";

import { UserInfo } from "components-ts/UserInfo";
import { UserAccountsInfo } from "components-ts/UserInfo/UserAccountsInfo";
import { UserHistory } from "components-ts/UserInfo/UserHistory";
import { UserActivities } from "components-ts/UserInfo/UserActivities";
import { UserLimits } from "components-ts/UserInfo/UserLimits";
import { UserBeneficiaries } from "components-ts/UserInfo/UserBeneficiaries";
import { UserExtraDocuments } from "components-ts/UserInfo/UserExtraDocuments";

export const UserTabs = (props) => {
  const { match } = props;
  const { params } = match;
  const { page, uid } = params;
  const tabNameToIndex = {
    0: "main",
    1: "balances",
    2: "history",
    3: "activities",
    4: "limits",
    5: "beneficiaries",
    6: "extra",
  };

  const indexToTabName = {
    main: 0,
    balances: 1,
    history: 2,
    activities: 3,
    limits: 4,
    beneficiaries: 5,
    extra: 6,
  };

  const [selectedTab, setSelectedTab] = React.useState<number>(
    indexToTabName[page]
  );

  const history = useHistory();

  const handleChange = (event, newValue) => {
    history.push(`/tower/users/${uid}/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  return (
    <>
      <AppBar color="inherit" position="static">
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label="Main Info" />
          <Tab label="Balances" />
          <Tab label="History" />
          <Tab label="Activities" />
          <Tab label="Limits" />
          <Tab label="Beneficiaries" />
          <Tab label="Extra Documents" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && <UserInfo {...props} />}
      {selectedTab === 1 && <UserAccountsInfo {...props} />}
      {selectedTab === 2 && <UserHistory {...props} />}
      {selectedTab === 3 && <UserActivities {...props} />}
      {selectedTab === 4 && <UserLimits {...props} />}
      {selectedTab === 5 && <UserBeneficiaries {...props} />}
      {selectedTab === 6 && <UserExtraDocuments {...props} />}
    </>
  );
};
