import { API, RequestOptions } from "api";
import { PAYMENTS_API_END_POINT_BENEFICIARIES } from "api/apiEndpoints";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import useSWR from "swr";
import { BeneficiaryInfo } from "./useBeneficiary";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

export const SWR_BENEFICIARIES_KEY = (optionalParameters: string) => [
  "get-beneficiaries-history",
  optionalParameters,
];

interface Response {
  data: BeneficiaryInfo[];
  total: number;
}

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${PAYMENTS_API_END_POINT_BENEFICIARIES}?${optionalParameters}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useBeneficiaryHistory = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_BENEFICIARIES_KEY(optionalParameters || ""),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return { data, error, mutate, loading: isValidating };
};
