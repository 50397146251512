import React, { useEffect, useState } from "react";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { useDepositHistory } from "hooks/useDepositHistory";
import { useDeposit } from "hooks/useDeposit";
import { DepositInfoProps } from "./types";
import ActionButtons from "components-ts/ActionButtons";
import { ActionButtonInterface } from "components-ts/ActionButtons/types";
import { prettyPrintObject } from "utils/prettyPrintObject";

import "./styles.scss";

export const DepositInfo: React.FC<DepositInfoProps> = ({ match }) => {
  const [page, setPage] = useState<number>(0);
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const {
    data: deposit,
    takeActionDeposit,
    mutate: mutateDeposit,
  } = useDeposit(match.params.tid);
  const {
    data: depositHistory,
    mutate: mutateDeposits,
    loading,
  } = useDepositHistory(
    buildQueryString({
      uid: deposit?.uid || "",
      page: page + 1,
      limit: rowsPerPage,
    })
  );

  useEffect(() => {
    mutateDeposits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deposit]);

  const actionButtons: ActionButtonInterface[] = [
    {
      variant: "accept",
      value: "Accept",
      state: "submitted blocked beneficiary_blocked",
      onClick: () => handleDepositAction("accept"),
    },
    {
      variant: "reject",
      value: "Reject",
      state: "submitted blocked beneficiary_blocked",
      onClick: () => handleDepositAction("reject"),
    },
    {
      variant: "archive",
      value: "Reject",
      state: "submitted",
      onClick: () => handleDepositAction("block"),
    },
  ];

  const handleDepositAction = async (action: string) => {
    if (deposit) {
      const requestProps = {
        tid: deposit.tid,
        action,
      };

      await takeActionDeposit(requestProps);
      mutateDeposit();
      mutateDeposits();
    }
  };

  const handleRowOnClickDeposit = (
    event: React.MouseEvent<unknown>,
    history: any,
    item: any
  ) => {
    history.push(`/tower/deposits/${item.tid}`);
  };

  const tableRows = [
    { key: "uid", alignRight: false, label: "UID" },
    { key: "tid", alignRight: false, label: "TID" },
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "amount", alignRight: false, label: "Amount" },
    { key: "currency", alignRight: false, label: "Currency" },
    { key: "state", alignRight: true, label: "Status" },
  ];

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardContent className="deposit-info__depositInfo">
          <div className="deposit-info__depositInfoBlockBody">
            <div className="deposit-info__depositInfoBlock">
              <Typography variant="h6" className="deposit-info__emailTitle">
                Deposit details
              </Typography>
            </div>
            <div className="deposit-info__block">
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  State
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  <span
                    className={clsx({
                      "deposit-info__red": "blocked rejected".includes(
                        deposit?.state || ""
                      ),
                      "deposit-info__yellow": "submitted".includes(
                        deposit?.state || ""
                      ),
                      "deposit-info__green": "accepted".includes(
                        deposit?.state || ""
                      ),
                      "deposit-info__blue": "collected".includes(
                        deposit?.state || ""
                      ),
                    })}
                  >
                    {deposit?.state}
                  </span>
                </Typography>
              </div>
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Member
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.uid}
                </Typography>
              </div>

              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Address
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.amount}
                </Typography>
              </div>
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  TxID
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.txid}
                </Typography>
              </div>

              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  UID
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo deposit-info__uidButton"
                  component="u"
                  onClick={() =>
                    history.push(`/tower/users/${deposit?.uid}/main`)
                  }
                >
                  {deposit?.uid}
                </Typography>
              </div>
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  TID
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.tid}
                </Typography>
              </div>

              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Type
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.type}
                </Typography>
              </div>
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Currency
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.currency.toUpperCase()}
                </Typography>
              </div>
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Amount
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.amount}
                </Typography>
              </div>

              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Confirmations
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.confirmations}
                </Typography>
              </div>
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Date Completed
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.completed_at}
                </Typography>
              </div>
              <div className="deposit-info__blockItem">
                <Typography
                  className="deposit-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Date Creation
                </Typography>
                <Typography
                  className="deposit-info__blockItemInfo"
                  component="p"
                >
                  {deposit?.created_at}
                </Typography>
              </div>
              {deposit &&
                deposit.data &&
                deposit.data !== null &&
                prettyPrintObject(
                  deposit.data,
                  "deposit-info__blockItemTitle",
                  "deposit-info__blockItemInfo"
                )}

              <Divider variant="middle" className="deposit-info__divider" />
              {deposit && (
                <ActionButtons
                  actionButtons={actionButtons}
                  originalData={deposit}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      <InfoTableComponent
        dataLength={depositHistory?.total}
        rows={tableRows}
        data={depositHistory?.data}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(page) => setPage(page)}
        handleChangeRowsPerPage={(count) => {
          setPage(0);
          setRowsPerPage(Number(count));
        }}
        handleRowOnClick={handleRowOnClickDeposit}
        hidePagination={false}
        mutateData={mutateDeposits}
        loading={loading}
        tableTitle={"Deposital History"}
      />
    </React.Fragment>
  );
};

export default DepositInfo;
