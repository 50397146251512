import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { File } from "./useExtraDocumentsHistory";

const requestOptions: RequestOptions = {
  apiVersion: "compliance",
};

export interface ExtraDocument {
  id: number;
  status: string;
  reason: string;
  comments?: string;
  files: File[];
  upload_type?: string;
  created_at: string;
}

interface Response {
  extra_documents: ExtraDocument[];
  total: number;
}

export const SWR_USER_EXTRA_DOCUMENT_KEY = (
  uid: string,
  optionalParameters?: string
) => ["get-user-extra-documents", uid, optionalParameters];

const fetcher = async (
  _key: string,
  uid: string,
  optionalParameters?: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `/admin/extra/${uid}?${optionalParameters}`
  );

  const result = {
    extra_documents: data,
    total: +headers.total,
  };

  return result;
};

export const useExtraDocument = (uid: string, optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_USER_EXTRA_DOCUMENT_KEY(uid, optionalParameters),
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const takeActionDocument = async (id: number, action: string) => {
    try {
      await API.post(requestOptions)(`/admin/extra/${action}/${id}`);
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return { data, error, mutate, takeActionDocument, loading: isValidating };
};
