import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Avatar,
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/LockOutlined";
import ReCAPTCHA from "react-google-recaptcha";

import { LoginForm, UserContext } from "providers/UserProvider";
import FormWithInvisibleRecaptcha from "components-ts/FormWithInvisibleRecaptcha";

import "./styles.scss";

export const LoginPage: React.FC = () => {
  const { login } = useContext(UserContext);
  const { handleSubmit, control } = useForm();

  const emailRegex = new RegExp(
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const onSubmit = (recaptchaRef: ReCAPTCHA) =>
    handleSubmit(async (loginForm: LoginForm) => {
      const token = await recaptchaRef.executeAsync();
      if (token) login({ ...loginForm, captcha_response: token });
    });

  return (
    <React.Fragment>
      <div className="login-component__root">
        <main className="login-component__main">
          <CssBaseline />

          <Paper className="login-component__paper">
            <Avatar className="login-component__avatar">
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <FormWithInvisibleRecaptcha onSubmit={onSubmit}>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <TextField
                    style={{ margin: 15, color: "#3598D5" }}
                    error={invalid}
                    label="Email Address"
                    helperText={error?.message}
                    type="text"
                    value={value}
                    onChange={onChange}
                    autoComplete="email"
                    autoFocus
                  />
                )}
                control={control}
                name="email"
                rules={{
                  required: "Required",
                  pattern: {
                    value: emailRegex,
                    message: "Not valid email",
                  },
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <TextField
                    style={{ margin: 15, color: "#3598D5" }}
                    error={invalid}
                    label="Password"
                    type="password"
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                    autoComplete="current-password"
                  />
                )}
                control={control}
                name="password"
                rules={{ required: "Required" }}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <TextField
                    style={{ margin: 15, color: "#3598D5" }}
                    error={invalid}
                    label="OTP code"
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                    autoFocus
                  />
                )}
                control={control}
                name="otp_code"
              />
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                className="login-component__submit"
              >
                Sign in
              </Button>
            </FormWithInvisibleRecaptcha>
          </Paper>
        </main>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
