import { SnackbarProvider } from 'notistack';
import { UserContextProvider } from 'providers/UserProvider';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';

const render = () =>
  ReactDOM.render(
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={5000}
    >
      <Router>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </Router>
    </SnackbarProvider>,
    document.getElementById('root')
  );

render();
