import React from "react";
import { useLocation } from "react-router";
import { CssBaseline } from "@material-ui/core";
import { Footer } from "components-ts/Footer";
import { Navbar } from "components-ts/Navbar";
import { LayoutProps } from "./types";

import "./styles.scss";

export const Layout: React.FC<LayoutProps> = ({
  loggedIn,
  logout,
  open,
  isSuperAdmin,
  handleDrawerOpen,
  handleDrawerClose,
  children,
}) => {
  const location = useLocation();
  return (
    <div className="layout__root">
      <CssBaseline />
      <Navbar
        loggedIn={loggedIn}
        logout={() => logout()}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        pathname={location.pathname}
        isSuperAdmin={isSuperAdmin}
      />
      <main className={`layout__content ${open ? "layout__contentShift" : ""}`}>
        <div className="layout__toolBar" />
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
