import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { ExtraDocumentModalProps } from "./types";

import "./styles.scss";

export const ExtraDocumentModal: React.FC<ExtraDocumentModalProps> = ({
  open,
  close,
  submit,
  extraDocument,
}) => {
  const getFileName = (url: string) => {
    return url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("?"));
  };

  const handleClick = (url: string) => {
    window.open(url, "_blank")?.focus();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogContent>
        <DialogContentText>
          <Grid item={true} className="extra-documents-modal__status">
            <Typography>Reason: {extraDocument?.reason}</Typography>
            {extraDocument?.reason === "limit_incrementation" && (
              <Typography>Type: {extraDocument?.upload_type}</Typography>
            )}
            <Typography>
              Status:{" "}
              <span
                className={clsx({
                  "extra-documents-modal__red":
                    (extraDocument?.status || "") === "rejected",
                  "extra-documents-modal__yellow":
                    (extraDocument?.status || "") === "submitted",
                  "extra-documents-modal__green":
                    (extraDocument?.status || "") === "accepted",
                })}
              >
                {extraDocument?.status || ""}
              </span>
            </Typography>
            {extraDocument?.comments && (
              <Typography>Comments: {extraDocument?.comments}</Typography>
            )}
          </Grid>

          <Grid item={true}>
            {extraDocument?.files.map((document) => {
              return (
                <Typography className="extra-documents-modal__file">
                  <button
                    className="extra-documents-modal__button"
                    onClick={() => {
                      handleClick(document.url);
                    }}
                  >
                    {getFileName(document.url)}
                  </button>
                </Typography>
              );
            })}
          </Grid>
        </DialogContentText>
      </DialogContent>

      {extraDocument?.status === "submitted" && (
        <DialogActions>
          <Button
            onClick={() => submit(extraDocument.id, "accept")}
            className="extra-documents-modal__blue"
          >
            ACCEPT
          </Button>
          <Button
            onClick={() => submit(extraDocument.id, "reject")}
            className="extra-documents-modal__red"
            type="submit"
          >
            REJECT
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ExtraDocumentModal;
