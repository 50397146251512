import React from "react";
import { UserSettingsProps } from "./types";
import "./styles.scss";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@material-ui/core";

export const UserSettings: React.FC<UserSettingsProps> = ({
  user,
  handleChangeUserState,
  currentUser,
  handleChangeUserOTP,
  handleChangeRole,
}) => {
  const stateTypes = [
    {
      value: "Pending",
      key: "pending",
    },
    {
      value: "Active",
      key: "active",
    },
    {
      value: "Banned",
      key: "banned",
    },
  ];

  const roleTypes = [
    {
      value: "Admin",
      key: "admin",
    },
    {
      value: "Member",
      key: "member",
    },
    {
      value: "Super Admin",
      key: "superadmin",
    },
    {
      value: "Accountant",
      key: "accountant",
    },
    {
      value: "Compliance",
      key: "compliance",
    },
    {
      value: "Partner",
      key: "partner",
    },
    {
      value: "Technical",
      key: "technical",
    },
    {
      value: "Support",
      key: "support",
    },
    {
      value: "Trader",
      key: "trader",
    },
    {
      value: "Broker",
      key: "broker",
    },
  ];

  return (
    <div className="user-settings__paper">
      <Grid container={true}>
        <Grid item={true} xs={6} className="user-settings__gridRow">
          <Typography variant="h6" className="user-settings__title">
            Settings
          </Typography>
          <Grid container={true} item={true} justify={"flex-start"} xs={12}>
            <FormControl
              fullWidth={true}
              className="user-settings__formControl"
            >
              <InputLabel htmlFor="state-label-placeholder">State</InputLabel>
              <Select
                value={user.state}
                onChange={handleChangeUserState}
                input={<Input id="state-label-placeholder" />}
              >
                {stateTypes.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container={true} item={true} justify={"flex-start"} xs={12}>
            {currentUser && currentUser.role === "superadmin" && (
              <FormControl
                fullWidth={true}
                className="user-settings__formControl"
              >
                <InputLabel htmlFor="role-label-placeholder">Role</InputLabel>
                <Select
                  value={user.role}
                  onChange={handleChangeRole}
                  input={<Input id="role-label-placeholder" />}
                >
                  {roleTypes
                    .sort((a, b) => a.value.localeCompare(b.value))
                    .map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>

        {user.otp !== undefined ? (
          <Grid item={true} xs={6} className="user-settings__gridRowCenter">
            <Grid container={true} alignItems={"center"}>
              <Grid item={true} xs={12}>
                <Typography variant="caption" gutterBottom={true}>
                  Authorization 2FA
                </Typography>
              </Grid>
              <Grid
                container={true}
                item={true}
                xs={6}
                justify={"flex-start"}
                alignItems="center"
              >
                <Typography>{user.otp ? "Enabled" : "Disabled"}</Typography>
              </Grid>
            </Grid>
            <Grid
              container={true}
              item={true}
              xs={6}
              justify="flex-end"
              alignItems="center"
            >
              <Switch
                checked={user.otp}
                onChange={handleChangeUserOTP}
                color="primary"
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default UserSettings;
