// tslint:disable:jsx-no-lambda
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddCircleOutline, Refresh } from "@material-ui/icons";
import classnames from "classnames";
import { getUserOS, getUserBrowser, localeDate } from "helpers";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { Props, RowItemInterface, styles } from "./types";

const colorizeItem = (value: string, classes: any) => {
  const state = value.toLowerCase();
  const cx = classnames(classes.tableItemStatus, {
    [classes.red]:
      state === "canceled" ||
      state === "cancel" ||
      state === "rejected" ||
      state === "aml_blocked",
    [classes.yellow]:
      state === "submitted" || state === "pending" || state === "wait",
    [classes.green]:
      state === "succeed" ||
      state === "accepted" ||
      state === "done" ||
      state === "active",
    [classes.blue]: state === "collected",
  });

  return <span className={cx}>{value}</span>;
};

const getUID = (key: string, item: any) => {
  switch (key) {
    case "email":
      return item.uid;
    case "user_email":
      return item.user && item.user.uid;
    case "taker_order_email":
      return item.taker_uid;
    case "maker_order_email":
      return item.maker_uid;
  }
  return undefined;
};
const renderCell = (key: string, item: any, classes: any) => {
  switch (key) {
    case "user_email":
      const uid_user = getUID(key, item);

      return (
        <Typography variant="caption">
          <Link
            to={`/tower/users/${uid_user}/main`}
            onClick={(e) => e.stopPropagation()}
            className={classes.email}
          >
            {item.user && item.user.email}
          </Link>
        </Typography>
      );
    case "email":
    case "taker_order_email":
    case "maker_order_email":
      const uid = getUID(key, item);
      return (
        <Typography variant="caption">
          <Link
            to={`/tower/users/${uid}/main`}
            onClick={(e) => e.stopPropagation()}
            className={classes.email}
          >
            {item[key]}
          </Link>
        </Typography>
      );
    case "state":
      return colorizeItem(item.state, classes);
    case "status":
      return colorizeItem(item.status, classes);
    case "result":
      return colorizeItem(item.result, classes);
    case "uid":
      return <span className={classes.tableItem}>{item.uid}</span>;
    case "created_at":
      return localeDate(item[key], "fullDate");
    case "amount":
    case "currency":
      return <span className={classes.tableItem}>{item[key]}</span>;
    case "user_role":
      return item.user.role ? item.user.role : "";
    case "browser":
      return getUserBrowser(item.user_agent);
    case "os":
      return getUserOS(item.user_agent);
    default:
      return item[key];
  }
};
const renderHeaderForTable = (props) => {
  const { classes, rows, isLimit } = props;

  return (
    <TableHead>
      <TableRow>
        {rows.map(
          (row: { key: string; alignRight: boolean; label: string }) => (
            <TableCell
              key={row.key}
              align={row.alignRight ? "right" : "left"}
              className={classes.headerItem}
            >
              {row.label}
            </TableCell>
          )
        )}
        {isLimit ? (
          <TableCell
            key="limit-button"
            align="left"
            className={classes.headerItem}
          ></TableCell>
        ) : (
          <> </>
        )}
      </TableRow>
    </TableHead>
  );
};

const InfoTable: React.FC<Props> = (props: Props) => {
  const {
    classes,
    page,
    hidePagination,
    dataLength,
    tableTitle,
    mutateData,
    data,
    loading,
    newItem,
  } = props;
  const history = useHistory();

  const getTableBody = () => {
    const { classes, rows, editLimit, deleteLimit } = props;
    if (data) {
      return data.map((n: any, i: number) => {
        return (
          <TableRow
            key={i}
            hover
            onClick={(e) => {
              e.stopPropagation();
              props.handleRowOnClick(e, history, n);
            }}
            className={classes.row}
          >
            {rows.map((row: RowItemInterface, index: number) => {
              const align = row.alignRight ? "right" : "left";
              return (
                <TableCell key={index} component="td" align={align}>
                  {renderCell(row.key, n, classes)}
                </TableCell>
              );
            })}
            {editLimit || deleteLimit ? (
              <TableCell component="td" align="left">
                {editLimit && (
                  <Button
                    style={{ marginRight: 10 }}
                    color="primary"
                    onClick={() => {
                      editLimit(n);
                    }}
                  >
                    <EditIcon />
                  </Button>
                )}
                {deleteLimit && n.type !== "global" && (
                  <Button
                    style={{ marginRight: 10 }}
                    color="primary"
                    onClick={() => {
                      deleteLimit(n);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                )}{" "}
              </TableCell>
            ) : (
              <></>
            )}
          </TableRow>
        );
      });
    }

    return <></>;
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardContent className={classes.cardContent}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" className={classes.tableTitle}>
              {tableTitle}
            </Typography>

            <div>
              {newItem && (
                <Button
                  onClick={() => newItem()}
                  style={{ marginRight: 12, width: 120, height: 36 }}
                >
                  {" "}
                  <AddCircleOutline style={{ paddingRight: 5 }} /> ADD NEW
                </Button>
              )}

              <Button
                onClick={() => mutateData()}
                style={{ marginRight: 24, width: 120, height: 36 }}
                disabled={loading}
              >
                {" "}
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    <Refresh style={{ paddingRight: 5 }} /> REFRESH
                  </>
                )}
              </Button>
            </div>
          </div>

          {data && dataLength ? (
            <>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} size="small">
                  {renderHeaderForTable(props)}
                  <TableBody>{getTableBody()}</TableBody>
                </Table>
              </div>

              {!hidePagination ? (
                <TablePagination
                  component="div"
                  count={Number(dataLength)}
                  rowsPerPage={props.rowsPerPage}
                  onChangeRowsPerPage={(e) =>
                    props.handleChangeRowsPerPage(e.target.value)
                  }
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={(e, page) => {
                    props.handleChangePage(page);
                  }}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} from ${count}`
                  }
                />
              ) : null}
            </>
          ) : (
            <Typography
              variant="caption"
              align="center"
              style={{ paddingLeft: 22 }}
            >
              There is no data to show
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export const InfoTableComponent = withStyles(styles, { withTheme: true })(
  InfoTable
);
