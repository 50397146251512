export enum ROLES {
  ACCOUNT = 'account',
  ADMIN = 'admin',
  BROKER = 'broker',
  COMPLIANCE = 'compliance',
  MEMBER = 'member',
  PARTNER = 'partner',
  SUPERADMIN = 'superadmin',
  SUPPORT = 'support',
  TECHNICAL = 'technical',
  TRADER = 'trader',
}

export const ROLES_OPTIONS = [
  { label: 'account', value: ROLES.ACCOUNT },
  { label: 'admin', value: ROLES.ADMIN },
  { label: 'broker', value: ROLES.BROKER },
  { label: 'compliance', value: ROLES.COMPLIANCE },
  { label: 'member', value: ROLES.MEMBER },
  { label: 'partner', value: ROLES.PARTNER },
  { label: 'superadmin', value: ROLES.SUPERADMIN },
  { label: 'support', value: ROLES.SUPPORT },
  { label: 'technical', value: ROLES.TECHNICAL },
  { label: 'trader', value: ROLES.TRADER },
];

export enum VERBS {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  PUT = 'PUT',
  ALL = 'ALL',
}

export const VERBS_OPTIONS = [
  { label: 'POST', value: VERBS.POST },
  { label: 'GET', value: VERBS.GET },
  { label: 'PATCH', value: VERBS.PATCH },
  { label: 'DELETE', value: VERBS.DELETE },
  { label: 'PUT', value: VERBS.PUT },
  { label: 'ALL', value: VERBS.ALL },
];

export enum ACTIONS {
  ACCEPT = 'ACCEPT',
  DROP = 'DROP',
  AUDIT = 'AUDIT',
}

export const ACTION_OPTIONS = [
  { label: 'ACCEPT', value: ACTIONS.ACCEPT },
  { label: 'DROP', value: ACTIONS.DROP },
  { label: 'AUDIT', value: ACTIONS.AUDIT },
];

export type EditPermissionsForm = {
  role?: ROLES;
  path?: string;
  topic?: string;
  verb?: VERBS;
  action?: ACTIONS;
};
