import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { PendingDocumentsProps } from "./types";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";
import { tablePageLimit } from "api/config";
import { convertToObj } from "helpers/convertToObj";
import { buildQueryString } from "helpers/buildQueryString";
import { usePendingDocuments } from "hooks/usePendingDocuments";

export const PendingDocuments: React.FC<PendingDocumentsProps> = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);

  const { data: pending, mutate, loading } = usePendingDocuments(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ...convertToObj(data),
    })
  );

  const tableRows = [
    { key: "email", alignRight: false, label: "Email" },
    { key: "kyc_last_modified", alignRight: false, label: "Date" },
    { key: "uid", alignRight: false, label: "UID" },
  ];

  const selectedValues = [
    {
      label: "UID",
      value: "uid",
      checked: false,
    },
  ];

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={pending?.total}
          rows={tableRows}
          data={pending?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={() => { }}
          hidePagination={false}
          tableTitle={"Pending Verification"}
          mutateData={mutate}
          loading={loading}
        />
      </Paper>
    </React.Fragment>
  );
};

export default PendingDocuments;
