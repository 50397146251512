import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Partner } from "hooks/useUserData";

import "./styles.scss";

type UserPartnerDataProps = {
  partner: Partner;
};

export const UserPartnerData: React.FC<UserPartnerDataProps> = (props) => {
  const { partner } = props;
  return (
    <div className="user-partner-data__paper">
      <Typography
        gutterBottom={true}
        variant="h6"
        className="user-partner-data__label"
      >
        Partner data
      </Typography>
      <Grid container={true} className="user-partner-data__grid-row">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-partner-data__title">
            UID
          </Typography>
          <Typography gutterBottom={true}>{partner.uid}</Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-partner-data__title">
            Email
          </Typography>
          <Typography gutterBottom={true}>{partner.email}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
