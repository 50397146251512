import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { UnmatchDeposit } from "./useUnmatchedDepositHistory";
import { PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

export const SWR_USER_UNMATCHED_KEY = (uid: string) => [
  "get-user-unmatched-deposit",
  uid,
];

const fetcher = async (_key: string, id: string): Promise<UnmatchDeposit> => {
  const { data } = await API.get(requestOptions)(
    `${PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS}/${id}`
  );

  return data;
};

type UnmatchedDepositsActions = "match" | "reject";

export const useUnmatchedDeposit = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR(SWR_USER_UNMATCHED_KEY(id), fetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const takeActionDeposit = async (action: UnmatchedDepositsActions) => {
    try {
      await API.put(requestOptions)(
        `${PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS}/${id}/${action}`
      );
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const updateDeposit = async (data) => {
    try {
      const form_data = new FormData();
      form_data.append("data", data);

      await API.put(requestOptions)(
        `${PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS}/${id}`,
        form_data
      );
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return { data, error, mutate, takeActionDeposit, updateDeposit };
};
