import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { convertToObj } from "helpers/convertToObj";
import { useTrades } from "hooks/useTrades";

export const TradesScreen: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);
  const { data: trades, mutate, loading } = useTrades(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ordering: "desc",
      ...convertToObj(data),
    })
  );

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  const activityRows = [
    { key: "maker_order_email", alignRight: false, label: "Maker email" },
    { key: "taker_order_email", alignRight: false, label: "Taker email" },
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "taker_type", alignRight: false, label: "Taker Type" },
    { key: "market", alignRight: true, label: "Market" },
    { key: "price", alignRight: false, label: "Price" },
    { key: "amount", alignRight: false, label: "Amount" },
    { key: "taker_fee_amount", alignRight: false, label: "Taker Fee" },
  ];

  const selectedValues = [
    {
      value: "market",
      label: "Market",
      checked: false,
    },
    {
      value: "order_id",
      label: "Order ID",
      checked: false,
    },
    {
      value: "uid",
      label: "UID",
      checked: false,
    },
  ];

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={trades?.total || 1}
          rows={activityRows}
          data={trades?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={() => { }}
          hidePagination={false}
          mutateData={mutate}
          loading={loading}
          tableTitle={"Trades"}
        />
      </Paper>
    </React.Fragment>
  );
};

export default TradesScreen;
