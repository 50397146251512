import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

import { UserCreateActionProps } from './types';
import NewDepositModal from 'components-ts/NewDepositModal';

import './styles.scss';

export const UserCreateAction: React.FC<UserCreateActionProps> = ({
  openNewDepositModal,
  newDeposit,
  isNewDepositModalOpened,
  closeModal,
  currencyOptions,
}) => {
  return (
    <div className="user-create-action__paper">
      <Grid container={true}>
        <Grid item={true} xs={6} className="user-create-action__gridRow">
          <Typography variant="h6" className="user-create-action__title">
            Actions
          </Typography>
        </Grid>
        <Grid item={true} xs={6} className="user-create-action__gridRowCenter">
          <Grid container={true} alignItems={'center'}>
            <Grid
              container={true}
              item={true}
              xs={6}
              justify={'flex-start'}
              alignItems="center"
            >
              <Button
                onClick={(e) => openNewDepositModal()}
                disableFocusRipple={true}
                disabled
              >
                New Deposit
              </Button>
            </Grid>
          </Grid>
          <Grid
            container={true}
            item={true}
            xs={6}
            justify="flex-end"
            alignItems="center"
          ></Grid>
        </Grid>
      </Grid>
      <NewDepositModal
        modalClose={closeModal}
        newDeposit={newDeposit}
        open={isNewDepositModalOpened}
        currencyOptions={currencyOptions}
      />
    </div>
  );
};

export default UserCreateAction;
