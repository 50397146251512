import React, { useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";
import { buildQueryString } from "helpers/buildQueryString";
import { AdminActivityTable } from "components-ts/AdminActivityTable";
import { useAdminActivity } from "hooks/useAdminActivity";
import { tablePageLimit } from "api/config";
import { convertToObj } from "helpers/convertToObj";

import "./styles.scss";

export const AdminActivities: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);
  const { data: adminActivity } = useAdminActivity(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ...convertToObj(data),
    })
  );

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  const activityRows = [
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "admin_email", alignRight: false, label: "Email" },
    { key: "admin_role", alignRight: false, label: "Role" },
    { key: "topic", alignRight: false, label: "Topic" },
    { key: "action", alignRight: false, label: "Action" },
    { key: "result", alignRight: false, label: "Result" },
    { key: "target", alignRight: true, label: "Target" },
    { key: "user_ip", alignRight: true, label: "IP" },
    { key: "browser", alignRight: true, label: "Browser" },
    { key: "os", alignRight: true, label: "OS" },
  ];

  const selectedValues = [
    {
      label: "UID",
      value: "uid",
      checked: false,
    },
    {
      label: "Email",
      value: "email",
      checked: false,
    },
    {
      label: "Action",
      value: "action",
      checked: false,
    },
    {
      label: "Topic",
      value: "topic",
      checked: false,
    },
    {
      label: "Target UID",
      value: "target_uid",
      checked: false,
    },
  ];

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
        handleTimeFormat={(date) => {
          return date.unix().toString();
        }}
      />
      <Paper style={{ marginTop: 25 }}>
        {adminActivity && adminActivity.data.length ? (
          <AdminActivityTable
            dataLength={adminActivity.total}
            rows={activityRows}
            data={adminActivity.data}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={(page) => setPage(page)}
            handleChangeRowsPerPage={(count) => {
              setPage(0);
              setRowsPerPage(Number(count));
            }}
            hidePagination={false}
            location={location}
            label="Admin activities"
          />
        ) : (
          <Typography
            variant="caption"
            align="center"
            className="admin-activities__emptyTable"
          >
            There is no data to show
          </Typography>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default AdminActivities;
