import { API, RequestOptions } from "api";
import { PAYMENTS_API_END_POINT_BENEFICIARIES } from "api/apiEndpoints";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import useSWR from "swr";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

export const SWR_BENEFICIARIES_KEY = (optionalParameters: string) => [
  "get-beneficiary",
  optionalParameters,
];

export interface BeneficiaryInfo {
  id: number;
  member_uid: string;
  is_customer_account: boolean;
  currency: string;
  payment_network: string;
  name: string;
  description: string;
  state: string;
  data: Object;
}

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<BeneficiaryInfo[]> => {
  const { data } = await API.get(requestOptions)(
    `${PAYMENTS_API_END_POINT_BENEFICIARIES}?${optionalParameters}`
  );

  return data;
};

export const useBeneficiary = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR(
    SWR_BENEFICIARIES_KEY(optionalParameters || ""),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const takeActionBeneficiary = async (id: number, action: string) => {
    try {
      await API.put(requestOptions)(
        `${PAYMENTS_API_END_POINT_BENEFICIARIES}/actions`,
        {
          id: id,
          action: action,
        }
      );
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return { data, error, mutate, takeActionBeneficiary };
};
