import React from "react";
import { Grid, Switch, Typography } from "@material-ui/core";
import { localeDate } from "helpers/localeDate";
import { useSnackbar } from "notistack";
import { UserKycProps } from "./types";
import { icons } from "./icons";

import "./styles.scss";

export const UserKYC: React.FC<UserKycProps> = ({ user, editLabel }) => {
  const { enqueueSnackbar } = useSnackbar();

  const checkLabel = (key: string) => {
    return (
      user.labels &&
      user.labels.find(
        (label) => label.key === key && label.value === "verified"
      )
    );
  };

  const getRow = (key: string, index: number) => {
    const status = checkLabel(key.toLowerCase()) ? "verified" : "verification";

    const findUpdatedAt = (key: string) => {
      const label =
        user.labels &&
        user.labels.find((label) => label.key === key.toLowerCase());

      if (label) return label.updated_at;

      return "";
    };

    return (
      <Grid item={true} className="user-kyc__level" key={key}>
        <Grid
          item={true}
          className="user-kyc__gridColumn"
          style={{ alignItems: "flex-start" }}
          xs={11}
        >
          <Typography variant="subtitle1" style={{ letterSpacing: "0.44px" }}>
            {key} {status}
          </Typography>
          <Typography variant="body2" className="user-kyc__info">
            {status === "verified"
              ? `Level ${index + 1} completed at ${localeDate(
                  findUpdatedAt(key),
                  "shortDate"
                )}`
              : `Confirm the ${key}`}
          </Typography>
        </Grid>
        <Grid item={true} className="user-kyc__gridColumn">
          <Switch
            checked={status === "verified"}
            onChange={onClick(key.toLowerCase())}
            color="primary"
          />
        </Grid>
      </Grid>
    );
  };

  const onClick = (key: string) => () => {
    const label = user.labels && user.labels.find((item) => item.key === key);
    if (!label) {
      enqueueSnackbar("Label doesn't exist", {
        variant: "error",
      });
    } else if (label.value === "verified") {
      editLabel(key, "rejected", label.scope);
    } else {
      editLabel(key, "verified", label.scope);
    }
  };

  return (
    <div className="user-kyc__paper">
      <Grid container={true}>
        <Grid item={true} xs={12} className="user-kyc__gridRow">
          <Typography variant="h6" className="user-kyc__title">
            KYC verification
          </Typography>
        </Grid>

        <Grid
          item={true}
          xs={1}
          className="user-kyc__gridColumn"
          style={{ alignItems: "center", marginTop: 10 }}
        >
          {checkLabel("email") ? icons("success") : icons("email")}
          <div className="user-kyc__gridRowWithDivider" />
          {checkLabel("profile") ? icons("success") : icons("user")}
          <div className="user-kyc__gridRowWithDivider" />
          {checkLabel("identity") ? icons("success") : icons("identity")}
        </Grid>
        <Grid
          item={true}
          container={true}
          xs={11}
          className="user-kyc__gridColumn"
        >
          {["Email", "Profile", "Identity"].map((item, index) =>
            getRow(item, index)
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UserKYC;
