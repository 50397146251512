import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  IconButton,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useStyles } from ".";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

const imgReason1 =
  // eslint-disable-next-line no-multi-str
  "Por favor realiza una nueva toma con el original del documento ya que no aceptamos escaneos, fotografias digitales ni fotocopias.";

const imgReason2 =
  // eslint-disable-next-line no-multi-str
  "Por favor realiza una nueva toma en la que la información esté iluminada, enfocada y lo suficientemente cerca como para poder corroborar todos los datos.";

const imgReason3 =
  // eslint-disable-next-line no-multi-str
  "Por favor realiza una nueva toma utilizando el frente de tu DNI físico, emitido por tu país de residencia.";

const imgReason4 =
  // eslint-disable-next-line no-multi-str
  "Vemos que por un error en la cámara de tu dispositivo es necesario que nos envies la foto del DNI por medio del chat de la plataforma para ser verificado";

export const ImgMediaCard = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>("");
  const [rotation, setRotation] = React.useState<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleClick = () => {
    window.open(props.img.url, "_blank");
  };
  const rotate = (angle) => {
    let newRotation = rotation + angle;

    if (newRotation >= 360) {
      newRotation = -360;
    }
    console.log({ newRotation });
    setRotation(newRotation);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt=""
          height="300"
          image={props.img.url}
          style={{ transform: `rotate(${rotation}deg)` }}
          onClick={() => {
            handleClick();
          }}
        />
        <CardContent>
          {props.checked && (
            <FormControl>
              <TextField
                label="Description"
                multiline
                rows={4}
                value={value}
                variant="outlined"
                onChange={handleChange}
              />
              <div className="button-class">
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => setValue(imgReason1)}
                >
                  no formato
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => setValue(imgReason2)}
                >
                  no legible
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => setValue(imgReason3)}
                >
                  no valido
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => setValue(imgReason4)}
                >
                  Bug borrosa
                </Button>
              </div>
            </FormControl>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.checked && (
          <Button
            size="small"
            color="primary"
            onClick={(e) => props.handleRejectID(value)}
          >
            Reject
          </Button>
        )}
        <IconButton size="small" color="primary" onClick={() => rotate(-90)}>
          <RotateLeftIcon />
        </IconButton>
        <IconButton size="small" color="primary" onClick={() => rotate(90)}>
          <RotateRightIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};
