import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { useExtraDocumentsHistory } from "hooks/useExtraDocumentsHistory";

export const ExtraDocumentsScreen: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const { data: extraDocuments, mutate, loading } = useExtraDocumentsHistory(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
    })
  );

  const activityRows = [
    { key: "email", alignRight: false, label: "Email" },
    { key: "uid", alignRight: false, label: "UID" },
  ];

  return (
    <React.Fragment>
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={extraDocuments?.total}
          rows={activityRows}
          data={extraDocuments?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={() => {}}
          hidePagination={false}
          tableTitle={"Pending Extra Documents"}
          loading={loading}
          mutateData={mutate}
        />
      </Paper>
    </React.Fragment>
  );
};

export default ExtraDocumentsScreen;
