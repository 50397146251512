import { useEffect } from "react";
import { API } from "api";
import { RequestOptions } from "api/requestBuilder";
import useSWR from "swr";
import {
  IdDocument,
  LastChallenge,
  VerificationVideo,
} from "./usePendingDocuments";
import { User, UserLabel } from "providers/UserProvider";
import { useSnackbar } from "notistack";
import {
  BARONG_API_END_POINT_ADMIN_USERS,
  COMPLIANCE_API_END_POINT_ADMIN_ID_REJECT,
  COMPLIANCE_API_END_POINT_ADMIN_VERIFICATION,
  COMPLIANCE_API_END_POINT_ADMIN_VIDEO_REJECT,
  BARONG_API_END_POINT_ADMIN_USERS_UPDATE,
  BARONG_API_END_POINT_ADMIN_USERS_ROLE,
} from "api/apiEndpoints";

const barongRequestOptions: RequestOptions = {
  apiVersion: "barong",
};

const complianceRequestOptions: RequestOptions = {
  apiVersion: "compliance",
};

export interface UserData {
  uid: string;
  email: string;
  role: string;
  level: number;
  state: string;
  partner?: Partner;
  created_at: string;
  updated_at: string;
  labels?: UserLabel[];
  customer: Customer;
  natural_person_data: NaturalPersonData;
  id_document: IdDocument;
  verification_video: VerificationVideo;
  last_challenge: LastChallenge;
  otp: boolean;
}

export interface Partner {
  id: number;
  email: string;
  uid: string;
  level: number;
  cid: string;
}

export interface NaturalPersonData {
  first_name: string;
  last_name: string;
  address: string;
  postcode: string;
  city: string;
  province: string;
  phone: string;
  nationality: string;
  gender: string;
  politically_exposed: boolean;
  pe_reason: string;
  marital_status: string;
  occupation: string;
  date_of_birth: string;
  status: string;
}

export interface Customer {
  id: number;
  cid: string;
  country: string;
  person_type: string;
  national_id_type: string;
  national_id: string;
}

export const SWR_USER_DATA_KEY = (uid: string) => ["get-user-data-key", uid];

const fetcher = async (_key: string, uid: string): Promise<UserData> => {
  const { data: user }: { data: UserData } = await API.get(
    complianceRequestOptions
  )(`${COMPLIANCE_API_END_POINT_ADMIN_VERIFICATION}/${uid}`);

  if (!user.partner) {
    try {
      const { data: barong_user }: { data: User } = await API.get(
        barongRequestOptions
      )(`${BARONG_API_END_POINT_ADMIN_USERS}/${uid}`);

      user.otp = barong_user.otp;

      user.state = barong_user.state;
      user.role = barong_user.role;
    } catch (err) {
      console.log(err);
    }
  }

  return user;
};

export const useUserData = (uid: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR(SWR_USER_DATA_KEY(uid), fetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const changeUserState = async (data) => {
    try {
      await API.post(barongRequestOptions)(
        BARONG_API_END_POINT_ADMIN_USERS_UPDATE,
        data
      );
      enqueueSnackbar("User state changed", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const changeUserRole = async (data) => {
    try {
      await API.post(barongRequestOptions)(
        BARONG_API_END_POINT_ADMIN_USERS_ROLE,
        data
      );
      enqueueSnackbar("User state changed", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const changeUserOTP = async (data) => {
    try {
      await API.post(barongRequestOptions)(
        BARONG_API_END_POINT_ADMIN_USERS_UPDATE,
        data
      );
      enqueueSnackbar("User state changed", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const acceptUserKYC = async (data) => {
    try {
      await API.post(complianceRequestOptions)(
        COMPLIANCE_API_END_POINT_ADMIN_VERIFICATION,
        data
      );
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const rejectUserID = async (data) => {
    try {
      await API.post(complianceRequestOptions)(
        COMPLIANCE_API_END_POINT_ADMIN_ID_REJECT,
        data
      );
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const rejectUserVideo = async (data) => {
    try {
      await API.post(complianceRequestOptions)(
        COMPLIANCE_API_END_POINT_ADMIN_VIDEO_REJECT,
        data
      );
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return {
    data,
    error,
    mutate,
    changeUserState,
    changeUserRole,
    changeUserOTP,
    acceptUserKYC,
    rejectUserID,
    rejectUserVideo,
  };
};
