import React from "react";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";

import ActionButtons from "components-ts/ActionButtons";
import { ActionButtonInterface } from "components-ts/ActionButtons/types";
import { useBeneficiary } from "hooks/useBeneficiary";
import { buildQueryString } from "helpers";
import { BeneficiaryInfoProps } from "./types";

import "./styles.scss";

export const BeneficiaryInfo: React.FC<BeneficiaryInfoProps> = ({ match }) => {
  const { data, mutate, takeActionBeneficiary } = useBeneficiary(
    buildQueryString({
      id: match.params.id,
    })
  );

  const beneficiary = data?.[0];

  const updateBeneficiary = async (action: string) => {
    await takeActionBeneficiary(match.params.id, action);
    mutate();
  };

  const actionButtons: ActionButtonInterface[] = [
    {
      variant: "accept",
      value: "Activate",
      state: "pending processing",
      onClick: () => updateBeneficiary("activate"),
    },
    {
      variant: "accept",
      value: "Process",
      state: "pending aml_blocked",
      onClick: () => updateBeneficiary("process"),
    },
    {
      variant: "accept",
      value: "Enable",
      state: "processing",
      onClick: () => updateBeneficiary("enable"),
    },
    {
      variant: "accept",
      value: "Review",
      state: "processing",
      onClick: () => updateBeneficiary("review"),
    },
    {
      variant: "archive",
      value: "Archive",
      state: "pending processing active",
      onClick: () => updateBeneficiary("archive"),
    },
    {
      variant: "reject",
      value: "Block",
      state: "processing",
      onClick: () => updateBeneficiary("block"),
    },
    {
      variant: "reject",
      value: "Reject",
      state: "under_review processing",
      onClick: () => updateBeneficiary("reject"),
    },
  ];

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardContent className="beneficiary-info__beneficiaryInfo">
          <div className="beneficiary-info__beneficiaryInfoBlockBody">
            <div className="beneficiary-info__beneficiaryInfoBlock">
              <Typography variant="h6" className="beneficiary-info__emailTitle">
                {beneficiary?.member_uid}
              </Typography>
            </div>
            <div className="beneficiary-info__block">
              <div className="beneficiary-info__blockItem">
                <Typography
                  className="beneficiary-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  State
                </Typography>
                <Typography
                  className="beneficiary-info__blockItemInfo"
                  component="p"
                >
                  <span
                    className={clsx({
                      "beneficiary-info__red":
                        (beneficiary?.state || "") === "rejected",
                      "beneficiary-info__yellow":
                        (beneficiary?.state || "") === "pending",
                      "beneficiary-info__green":
                        (beneficiary?.state || "") === "active",
                    })}
                  >
                    {beneficiary?.state}
                  </span>
                </Typography>
              </div>
              <div className="beneficiary-info__blockItem">
                <Typography
                  className="beneficiary-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Name
                </Typography>
                <Typography
                  className="beneficiary-info__blockItemInfo"
                  component="p"
                >
                  {beneficiary?.name}
                </Typography>
              </div>
              <div className="beneficiary-info__blockItem">
                <Typography
                  className="beneficiary-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Payment Network
                </Typography>
                <Typography
                  className="beneficiary-info__blockItemInfo"
                  component="p"
                >
                  {beneficiary?.payment_network}
                </Typography>
              </div>

              <div className="beneficiary-info__blockItem">
                <Typography
                  className="beneficiary-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Currency
                </Typography>
                <Typography
                  className="beneficiary-info__blockItemInfo"
                  component="p"
                >
                  {beneficiary?.currency}
                </Typography>
              </div>

              <div className="beneficiary-info__blockItem">
                <Typography
                  className="beneficiary-info__blockItemTitle"
                  variant="h5"
                  component="h5"
                >
                  Description
                </Typography>
                <Typography
                  className="beneficiary-info__blockItemInfo"
                  component="p"
                >
                  {beneficiary?.description}
                </Typography>
              </div>

              {beneficiary &&
                beneficiary?.data &&
                beneficiary?.data !== null &&
                Object.entries(beneficiary?.data).map(([field, data]) => (
                  <div className="beneficiary-info__blockItem" key={field}>
                    <Typography
                      className="beneficiary-info__blockItemTitle"
                      variant="h5"
                      component="h5"
                    >
                      {field.replace(/_/g, " ")}
                    </Typography>
                    <Typography
                      className="beneficiary-info__blockItemInfo"
                      component="p"
                    >
                      {data}
                    </Typography>
                  </div>
                ))}
              <Divider variant="middle" className="beneficiary-info__divider" />
              {beneficiary && (
                <ActionButtons
                  actionButtons={actionButtons}
                  originalData={beneficiary}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default BeneficiaryInfo;
