import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import {
  PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS,
  PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_ACTION,
  PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_NEW,
} from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "peatio",
};

interface Asset {
  id: number;
  account_kind: string;
  code: number;
  created_at: string;
  credit: string;
  currency: string;
  debit: string;
  reference_type: string;
  rid: number;
}

interface Liability extends Asset {
  uid: string;
}

export interface Adjustment {
  id: number;
  amount: string;
  asset_account_code: number;
  category: string;
  created_at: string;
  updated_at: string;
  creator_uid: string;
  currency: string;
  description: string;
  asset: Asset;
  liability: Liability;
  reason: string;
  receiving_account_code: string;
  receiving_member_uid: string;
  state: string;
  validator_uid: string;
}

interface Response {
  data: Adjustment[];
  total: number;
}

const fetcher = async (_key: string): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useAdjustments = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    "get-adjustments",
    fetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const createAdjustment = async (data) => {
    try {
      await API.post(requestOptions)(
        PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_NEW,
        data
      );
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  const takeActionAdjustment = async (data) => {
    try {
      await API.post(requestOptions)(
        PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_ACTION,
        data
      );
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return {
    data,
    error,
    mutate,
    loading: isValidating,
    createAdjustment,
    takeActionAdjustment,
  };
};
