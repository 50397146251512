/* Compliance endpoints */
const COMPLIANCE_API_END_POINT_ADMIN_LABELS = "/admin/labels";
const COMPLIANCE_API_END_POINT_ADMIN_LABELS_UPDATE = "/admin/labels/update";
const COMPLIANCE_API_END_POINT_ADMIN_MEMBERS = "/admin/members";
const COMPLIANCE_API_END_POINT_ADMIN_VERIFICATION = "/admin/verification";
const COMPLIANCE_API_END_POINT_ADMIN_ID_REJECT = "/admin/id/reject";
const COMPLIANCE_API_END_POINT_ADMIN_VIDEO_REJECT = "/admin/video/reject";
const COMPLIANCE_API_END_POINT_ADMIN_BLACKLIST_MATCH =
  "/admin/blacklist_match/";

/* Barong endpoints */
const BARONG_API_END_POINT_ADMIN_USERS_LABELS = "/admin/users/labels";
const BARONG_API_END_POINT_ADMIN_USERS_LABELS_UPDATE =
  "/admin/users/labels/update";
const BARONG_API_END_POINT_ADMIN_USERS = "/admin/users";
const BARONG_API_END_POINT_ADMIN_USERS_UPDATE = "/admin/users/update";
const BARONG_API_END_POINT_ADMIN_USERS_ROLE = "/admin/users/role";
const BARONG_API_END_POINT_ADMIN_METRICS = "/admin/metrics";
const BARONG_API_END_POINT_ADMIN_ACTIVITIES = "/admin/activities";
const BARONG_API_END_POINT_ADMIN_ACTIVITIES_ADMIN = "/admin/activities/admin";
const BARONG_API_END_POINT_ADMIN_IDENTITY_SESSIONS = "/identity/sessions";
const BARONG_API_END_POINT_ADMIN_RESOURCE_SESSIONS_ME = "/resource/users/me";
const BARONG_API_END_POINT_ADMIN_PERMISSIONS = "/admin/permissions";

/* Peatio endpoints */
const PEATIO_API_END_POINT_CURRENCIES = "/admin/currencies";
const PEATIO_API_END_POINT_ORDERS = "/admin/orders";
const PEATIO_API_END_POINT_MEMBERS = "/admin/members";
const PEATIO_API_END_POINT_ADMIN_TRADES = "/admin/trades";
const PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS = "/admin/adjustments";
const PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_NEW = "/admin/adjustments/new";
const PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_ACTION =
  "/admin/adjustments/action";

/* Referrer endpoints */

/* Payments endpoints */

const PAYMENTS_API_END_POINT_DEPOSITS_ACTIONS = "/admin/deposits/actions";
const PAYMENTS_API_END_POINT_ADMIN_DEPOSITS = "/admin/deposits";
const PAYMENTS_API_END_POINT_ADMIN_LIMITS = "/admin/limits";
const PAYMENTS_API_END_POINT_WITHDRAWS = "/admin/withdraws";
const PAYMENTS_API_END_POINT_WITHDRAWS_ACTIONS = "/admin/withdraws/actions";
const PAYMENTS_API_END_POINT_BENEFICIARIES = "/admin/beneficiaries";
const PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS =
  "/admin/unmatched_deposits";

export {
  PEATIO_API_END_POINT_CURRENCIES,
  PEATIO_API_END_POINT_ORDERS,
  PEATIO_API_END_POINT_MEMBERS,
  PEATIO_API_END_POINT_ADMIN_TRADES,
  PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS,
  PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_NEW,
  PEATIO_API_END_POINT_ADMIN_ADJUSTMENTS_ACTION,
  PAYMENTS_API_END_POINT_DEPOSITS_ACTIONS,
  PAYMENTS_API_END_POINT_ADMIN_LIMITS,
  PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS,
  PAYMENTS_API_END_POINT_ADMIN_DEPOSITS,
  PAYMENTS_API_END_POINT_WITHDRAWS,
  PAYMENTS_API_END_POINT_WITHDRAWS_ACTIONS,
  PAYMENTS_API_END_POINT_BENEFICIARIES,
  COMPLIANCE_API_END_POINT_ADMIN_LABELS,
  COMPLIANCE_API_END_POINT_ADMIN_LABELS_UPDATE,
  COMPLIANCE_API_END_POINT_ADMIN_MEMBERS,
  COMPLIANCE_API_END_POINT_ADMIN_VERIFICATION,
  COMPLIANCE_API_END_POINT_ADMIN_ID_REJECT,
  COMPLIANCE_API_END_POINT_ADMIN_VIDEO_REJECT,
  COMPLIANCE_API_END_POINT_ADMIN_BLACKLIST_MATCH,
  BARONG_API_END_POINT_ADMIN_USERS,
  BARONG_API_END_POINT_ADMIN_USERS_UPDATE,
  BARONG_API_END_POINT_ADMIN_USERS_ROLE,
  BARONG_API_END_POINT_ADMIN_USERS_LABELS,
  BARONG_API_END_POINT_ADMIN_USERS_LABELS_UPDATE,
  BARONG_API_END_POINT_ADMIN_METRICS,
  BARONG_API_END_POINT_ADMIN_ACTIVITIES,
  BARONG_API_END_POINT_ADMIN_ACTIVITIES_ADMIN,
  BARONG_API_END_POINT_ADMIN_IDENTITY_SESSIONS,
  BARONG_API_END_POINT_ADMIN_RESOURCE_SESSIONS_ME,
  BARONG_API_END_POINT_ADMIN_PERMISSIONS,
};
