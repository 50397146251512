import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { convertToObj } from "helpers/convertToObj";
import { useMembers } from "hooks/useMembers";
import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";

const AllUsersTable: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);
  const { data: users, mutate, loading } = useMembers(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ...convertToObj(data),
    })
  );

  const searchValues = [
    {
      label: "Email",
      value: "email",
      checked: false,
    },
    {
      label: "First name",
      value: "first_name",
      checked: false,
    },
    {
      label: "Last name",
      value: "last_name",
      checked: false,
    },
    {
      label: "UID",
      value: "uid",
      checked: false,
    },
    {
      label: "Role",
      value: "role",
      checked: false,
    },
    {
      label: "Level",
      value: "level",
      checked: false,
    },
    {
      label: "State",
      value: "state",
      checked: false,
    },
    {
      label: "Type",
      value: "type",
      checked: false,
    },
  ];

  const usersRows = [
    { key: "uid", alignRight: false, label: "UID" },
    { key: "email", alignRight: false, label: "Email" },
    { key: "type", alignRight: false, label: "Type" },
    { key: "role", alignRight: false, label: "Role" },
    { key: "level", alignRight: true, label: "Level" },
    { key: "created_at", alignRight: true, label: "Created" },
    { key: "state", alignRight: true, label: "State" },
    { key: "partner", alignRight: true, label: "Partner Email" },
  ];

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={searchValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
        handleTimeFormat={(date) => {
          return date.unix().toString();
        }}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={users?.total}
          rows={usersRows}
          data={users?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={() => { }}
          hidePagination={false}
          mutateData={mutate}
          loading={loading}
          tableTitle={"All Users"}
        />
      </Paper>
    </React.Fragment>
  );
};

export default AllUsersTable;
