import React from "react";
import { UserSummaryProps } from "./types";
import { Grid, Typography } from "@material-ui/core";
import { localeDate } from "helpers/localeDate";

import "./styles.scss";

export const UserSummary: React.FC<UserSummaryProps> = ({ user }) => {
  return (
    <div className="user-summary__paper">
      <Typography
        gutterBottom={true}
        variant="h6"
        className="user-summary__label"
      >
        {user.email}
      </Typography>
      <Grid item={true}>
        <Typography style={{ color: "#757575", fontSize: "12px" }}>
          Last activity: {localeDate(user.updated_at, "fullDate")}
        </Typography>
      </Grid>
      <br />
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            UID
          </Typography>
          <Typography gutterBottom={true}>{user.uid}</Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Created at
          </Typography>
          <Typography gutterBottom={true}>
            {localeDate(user.created_at, "shortDate") || "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            First name
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.first_name
              : "-"}
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Last name
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.last_name
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Gender
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.gender
              : "-"}
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Marital status
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.marital_status
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Phone number
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null &&
            user.natural_person_data.phone.length > 0
              ? user.natural_person_data.phone
              : "-"}
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Day of Birth
          </Typography>
          <Typography gutterBottom={true}>
            {(user.natural_person_data !== null &&
              localeDate(user.natural_person_data.date_of_birth, "date")) ||
              "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Occupation
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.occupation
              : "-"}
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Citizenship
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data !== null &&
                user.natural_person_data.nationality
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Province
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.province
              : "-"}
          </Typography>
        </Grid>

        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            City
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.city
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Address
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.address
              : "-"}
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Postcode
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.postcode
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className="user-summary__gridRow">
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className="user-summary__title">
            Politically exposed
          </Typography>
          <Typography gutterBottom={true}>
            {user.natural_person_data !== null
              ? user.natural_person_data.politically_exposed === true
                ? "Yes"
                : "No"
              : "-"}
          </Typography>
        </Grid>
        {user.natural_person_data !== null &&
        user.natural_person_data.politically_exposed === true ? (
          <Grid item={true} xs={6}>
            <Typography gutterBottom={true} className="user-summary__title">
              Politically exposed reason
            </Typography>
            <Typography gutterBottom={true}>
              {user.natural_person_data.pe_reason}
            </Typography>
          </Grid>
        ) : (
          <> </>
        )}
      </Grid>
    </div>
  );
};

export default UserSummary;
