import React from "react";
import "./styles.scss";
import { Paper } from "@material-ui/core";
import {
  dateToMetricsDate,
  metricsToChartData,
} from "helpers/metricsToChartData";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import { Metrics, useMetrics } from "hooks/useMetrics";

const getWeek = (endDate: Date = new Date()) => {
  const week: string[] = [];

  for (let i = 0; i < 7; i++) {
    const d = new Date(endDate);
    d.setDate(d.getDate() - i);
    week.unshift(dateToMetricsDate(d));
  }
  return week;
};

export const Dashboard: React.FC = () => {
  const { data: metrics } = useMetrics();

  const week = getWeek();

  const defaultMetric: Metrics = {
    signups: {
      week,
    },
    sucessful_logins: {
      week,
    },
    failed_logins: {
      week,
    },
    pending_applications: 0,
  };

  const data = metricsToChartData(metrics || defaultMetric);

  return (
    <React.Fragment>
      <div className="dashboard__main">
        <Paper className="dashboard__paper">
          <div className="dashboard__heading">New Signup</div>
          <div className="dashboard__counter">
            {data[data.length - 1].signups.toLocaleString()}
          </div>
        </Paper>
        <Paper className="dashboard__paper">
          <div className="dashboard__heading">Login last 24h</div>
          <div className="dashboard__counter">
            {data[data.length - 1].sucessful_logins.toLocaleString()}
          </div>
        </Paper>
        <Paper className="dashboard__paper">
          <div className="dashboard__heading">Failed Login</div>
          <div className="dashboard__counterRed">
            {data[data.length - 1].failed_logins.toLocaleString()}
          </div>
        </Paper>
        <Paper className="dashboard__paper">
          <div className="dashboard__heading">Pending Applications</div>
          <div className="dashboard__counter">
            {metrics && metrics.pending_applications.toLocaleString()}
          </div>
        </Paper>
      </div>
      <Paper className="dashboard__activity">
        <div className="dashboard__activityHeading">
          Last 7 days user activity
        </div>

        <ResponsiveContainer width="99%" height={300}>
          <LineChart
            data={data}
            margin={{
              top: 15,
              right: 0,
              left: 20,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis orientation="right" />
            <Tooltip />
            <Legend align={"left"} />
            <Line
              type="monotone"
              dataKey="signups"
              name="NEW SIGNUPS"
              stroke="#2076D2"
            />
            <Line
              type="monotone"
              dataKey="sucessful_logins"
              name="LOGINS"
              stroke="#43A047"
            />
            <Line
              type="monotone"
              dataKey="failed_logins"
              name="FAILED LOGINS"
              stroke="#DD503D"
            />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </React.Fragment>
  );
};

export default Dashboard;
