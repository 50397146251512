import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { DepositInfo } from "./useDepositHistory";
import { PAYMENTS_API_END_POINT_DEPOSITS_ACTIONS } from "../api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

export const SWR_USER_DEPOSIT_KEY = (tid: string) => ["get-user-deposit", tid];

const fetcher = async (_key: string, tid: string): Promise<DepositInfo> => {
  const { data } = await API.get(requestOptions)(`/admin/deposits/${tid}`);

  return data;
};

export const useDeposit = (tid: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate } = useSWR(SWR_USER_DEPOSIT_KEY(tid), fetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const takeActionDeposit = async (data) => {
    try {
      await API.put(requestOptions)(
        PAYMENTS_API_END_POINT_DEPOSITS_ACTIONS,
        data
      );
      enqueueSnackbar("Done", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return { data, error, mutate, takeActionDeposit };
};
