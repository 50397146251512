import React, { useState } from "react";
import { Paper } from "@material-ui/core";

import { UserActivitiesProps } from "./types";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { useActivity } from "hooks/useActivity";

export const UserActivities: React.FC<UserActivitiesProps> = ({ match }) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [optionaParameters, setOptionaParameters] = useState<string>(
    buildQueryString({
      page: page + 1,
      limit: tablePageLimit(),
      uid: match.params.uid,
    })
  );

  const { data: userActivity, mutate, loading } = useActivity(
    optionaParameters
  );

  const activityRows = [
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "action", alignRight: false, label: "Action" },
    { key: "result", alignRight: false, label: "Result" },
    { key: "user_ip", alignRight: true, label: "IP" },
    { key: "browser", alignRight: true, label: "Browser" },
    { key: "os", alignRight: true, label: "OS" },
  ];

  return (
    <Paper style={{ marginBottom: 15 }}>
      <InfoTableComponent
        dataLength={userActivity?.total}
        rows={activityRows}
        data={userActivity?.data}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(page) => {
          setPage(page);
          setOptionaParameters(
            buildQueryString({
              page: page + 1,
              limit: rowsPerPage,
              uid: match.params.uid,
            })
          );
        }}
        handleChangeRowsPerPage={(rows) => {
          setPage(0);
          setRowsPerPage(Number(rows));
          setOptionaParameters(
            buildQueryString({
              page: 1,
              limit: Number(rows),
              uid: match.params.uid,
            })
          );
        }}
        handleRowOnClick={() => {}}
        hidePagination={false}
        mutateData={mutate}
        loading={loading}
        tableTitle={"User Activity"}
      />
    </Paper>
  );
};

export default UserActivities;
