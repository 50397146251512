import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { minutesUntilAutoLogout } from "../api/config";

import { UserTabs } from "components-ts/UserInfo/UserTabs";
import LoginPage from "components-ts/LoginPage";
import Dashboard from "components-ts/Dashboard";
import UsersTable from "components-ts/UsersTable";
import PendingDocuments from "components-ts/PendingDocuments";
import UserInfo from "components-ts/UserInfo";
import UserAccountsInfo from "components-ts/UserInfo/UserAccountsInfo";
import DepositListTable from "components-ts/DepositListTable";
import OrdersScreen from "components-ts/OrdersScreen";
import TradesScreen from "components-ts/TradesScreen";
import WithdrawListTable from "components-ts/WithdrawListTable";
import Activities from "components-ts/Activities";
import WithdrawInfo from "components-ts/WithdrawInfo";
import AdminActivities from "components-ts/AdminActivities";
import DepositInfo from "components-ts/DepositInfo";
import UnmatchedDepositsScreen from "components-ts/UnmatchedDepositsScreen";
import UnmatchedDepositInfo from "components-ts/UnmatchedDepositInfo";
import Permissions from "components-ts/Permissions";
import BeneficiaryInfo from "components-ts/BeneficiaryInfo";
import ExtraDocumentsScreen from "components-ts/ExtraDocumentsScreen";
import AllUsersTable from "components-ts/AllUsersTable";
import AdjustmentsTable from "components-ts/AdjustmentsTable";

import {
  RouterProps,
  SuperAdminRouteProps,
  PublicRouteProps,
  PrivateRouteProps,
} from "./types";
import BlackListTable from "components-ts/BlackListMatch";
import BlackListMactchInfo from "components-ts/BlackListMatchInfo";
import BeneficiariesTable from "components-ts/Beneficiaries";

const renderLoading = () => {
  return <div>Loading...</div>;
};

const CHECK_INTERVAL = 15000;
const STORE_KEY = "lastAction";

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: CustomComponent,
  loading,
  isLogged,
  ...rest
}) => {
  if (loading) {
    return renderLoading();
  }

  const renderCustomerComponent = (props) => <CustomComponent {...props} />;

  if (isLogged) {
    return <Route {...rest} render={renderCustomerComponent} />;
  }

  return (
    <Route {...rest}>
      <Redirect to={"/tower/login"} />
    </Route>
  );
};

const SuperAdminRoute: React.FC<SuperAdminRouteProps> = ({
  component: CustomComponent,
  loading,
  isLogged,
  user,
  ...rest
}) => {
  if (loading) {
    return renderLoading();
  }

  const renderCustomerComponent = (props) => <CustomComponent {...props} />;

  if (isLogged && user && user.role === "superadmin") {
    return <Route {...rest} render={renderCustomerComponent} />;
  }

  return (
    <Route {...rest}>
      <Redirect to={"/tower/login"} />
    </Route>
  );
};

const PublicRoute: React.FunctionComponent<PublicRouteProps> = ({
  component: CustomComponent,
  loading,
  isLogged,
  ...rest
}) => {
  if (loading) {
    return renderLoading();
  }

  if (isLogged) {
    return (
      <Route {...rest}>
        <Redirect to={"/tower"} />
      </Route>
    );
  }

  const renderCustomerComponent = (props) => <CustomComponent {...props} />;
  return <Route {...rest} render={renderCustomerComponent} />;
};

export const AppRouter: React.FC<RouterProps> = ({
  isCurrentSession,
  userLoading,
  logout,
  user,
}) => {
  const eventsListen = [
    "click",
    "keydown",
    "scroll",
    "resize",
    "mousemove",
    "TabSelect",
    "TabHide",
  ];

  let timer;

  useEffect(() => {
    initInterval();
    check();
  });

  useEffect(() => {
    for (const type of eventsListen) {
      document.body.removeEventListener(type, reset);
    }
    clearInterval(timer);
    return timer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsListen, timer]);

  const setLastAction = (lastAction: number) => {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  };

  const reset = () => {
    setLastAction(Date.now());
  };

  const initListener = () => {
    reset();
    for (const type of eventsListen) {
      document.body.addEventListener(type, reset);
    }
  };

  initListener();

  const getLastAction = () => {
    if (localStorage.getItem(STORE_KEY) !== null) {
      return parseInt(localStorage.getItem(STORE_KEY) || "0", 10);
    }
    return 0;
  };

  const initInterval = () => {
    timer = setInterval(() => {
      check();
    }, CHECK_INTERVAL);
  };

  const check = () => {
    const now = Date.now();
    const timeleft =
      getLastAction() + parseFloat(minutesUntilAutoLogout()) * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout && user && user.email) {
      logout();
    }
  };

  return (
    <Switch>
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/users"
        component={UsersTable}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/users/all"
        component={AllUsersTable}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/users/blacklist"
        component={BlackListTable}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/users/blacklist/:id"
        component={BlackListMactchInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/pending"
        component={PendingDocuments}
      />
      <PrivateRoute
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/pending/:uid"
        component={UserInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/activities"
        component={Activities}
      />
      <SuperAdminRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/admin-activities"
        component={AdminActivities}
        user={user}
      />
      <SuperAdminRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/admin-activities/:uid/accounts"
        component={UserAccountsInfo}
        user={user}
      />
      <SuperAdminRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/admin-activities/:uid"
        component={UserInfo}
        user={user}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower"
        component={Dashboard}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/activities/:uid/accounts"
        component={UserAccountsInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/activities/:uid"
        component={UserInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/users/:uid/:page"
        component={UserTabs}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/withdraws"
        component={WithdrawListTable}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/deposits"
        component={DepositListTable}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/deposits/:tid"
        component={DepositInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/orders"
        component={OrdersScreen}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/trades"
        component={TradesScreen}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/unmatched"
        component={UnmatchedDepositsScreen}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/unmatched/:id"
        component={UnmatchedDepositInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/withdraws/:tid"
        component={WithdrawInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/permissions"
        component={Permissions}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/beneficiaries/:id"
        component={BeneficiaryInfo}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/beneficiaries"
        component={BeneficiariesTable}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/extra"
        component={ExtraDocumentsScreen}
      />
      <PrivateRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        path="/tower/adjustments"
        component={AdjustmentsTable}
      />
      <PublicRoute
        loading={userLoading}
        isLogged={isCurrentSession}
        exact={true}
        path="/tower/login"
        component={LoginPage}
      />
      <Route path="**">
        <Redirect to="/tower" />
      </Route>
    </Switch>
  );
};
