import * as React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Customer } from "hooks/useUserData";

import "./styles.scss";

type UserCustomerDataProps = {
  customer: Customer;
};

export const UserCustomerData: React.FC<UserCustomerDataProps> = (props) => {
  const { customer } = props;
  return (
    <div className="user-customer-data__paper">
      <Typography
        gutterBottom={true}
        variant="h6"
        className="user-customer-data__label"
      >
        Customer data
      </Typography>
      {customer ? (
        <>
          {" "}
          <Grid container={true} className="user-customer-data__grid-row">
            <Grid item={true} xs={6}>
              <Typography
                gutterBottom={true}
                className="user-customer-data__title"
              >
                Customer id
              </Typography>
              <Typography gutterBottom={true}>{customer.cid}</Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography
                gutterBottom={true}
                className="user-customer-data__title"
              >
                Country
              </Typography>
              <Typography gutterBottom={true}>{customer.country}</Typography>
            </Grid>
          </Grid>
          <Grid container={true} className="user-customer-data__grid-row">
            <Grid item={true} xs={6}>
              <Typography
                gutterBottom={true}
                className="user-customer-data__title"
              >
                Person type
              </Typography>
              <Typography gutterBottom={true}>
                {customer.person_type}
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography
                gutterBottom={true}
                className="user-customer-data__title"
              >
                National id type
              </Typography>
              <Typography gutterBottom={true}>
                {customer.national_id_type}
              </Typography>
            </Grid>
          </Grid>
          <Grid container={true} className="user-customer-data__grid-row">
            <Grid item={true} xs={6}>
              <Typography
                gutterBottom={true}
                className="user-customer-data__title"
              >
                National id
              </Typography>

              <Typography gutterBottom={true}>
                {customer.national_id}
              </Typography>

              {customer.national_id !== undefined &&
              (customer.national_id_type === "cuit" ||
                customer.national_id_type === "cuil") ? (
                <>
                  <Button
                    target="_blank"
                    href={
                      "https://afip.tangofactura.com/Rest/GetContribuyenteFull?cuit=" +
                      customer.national_id
                    }
                  >
                    {" "}
                    Tango{" "}
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>{" "}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};
