import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { InfoPopper } from "components-ts/InfoPopper/InfoPopper";
import { DataInterface } from "hooks/useAdminActivity";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  capitalize,
  getUserBrowser,
  getUserOS,
  localeDate,
  parseList,
} from "../../helpers";
import "./styles.scss";
import { ActivityTableProps } from "./types";

interface State {
  anchorEl: HTMLElement | null;
  selectIndex: number;
}

export const AdminActivityTable: React.FC<ActivityTableProps> = ({
  dataLength,
  rows,
  data,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  hidePagination,
  label,
  location,
}) => {
  const [state, setState] = useState<State>({ anchorEl: null, selectIndex: 0 });

  const changePage = (event: any, page: number) => {
    handleChangePage && handleChangePage(page);
  };

  const changeRowsPerPage = (event) => {
    handleChangeRowsPerPage && handleChangeRowsPerPage(event.target.value);
  };

  const getHeaderForTable = () => {
    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row: { key: string; alignRight: boolean; label: string }) => (
              <TableCell
                key={row.key}
                align={row.alignRight ? "right" : "left"}
                className="admin-table__cell"
              >
                <Typography
                  variant="subtitle2"
                  gutterBottom={true}
                  className="admin-table__headers"
                >
                  {row.label}
                </Typography>
              </TableCell>
            )
          )}
          <TableCell className="admin-table__info" />
        </TableRow>
      </TableHead>
    );
  };

  const handleClick = (i) => (event: React.MouseEvent<HTMLElement>) => {
    setState({ selectIndex: i, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setState({ ...state, anchorEl: null });
  };

  const renderData = (list) => {
    if (list && list.length > 0) {
      return list.map((i: DataInterface, index: number) =>
        i.type === "key" ? (
          <Typography
            variant="body2"
            className="admin-table__title"
            key={index}
          >
            {capitalize(i.value)}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            className="admin-table__value"
            key={index}
          >
            {i.value}
          </Typography>
        )
      );
    } else {
      return (
        <Typography variant="caption" align="center">
          There is no data to show
        </Typography>
      );
    }
  };

  const renderContent = () => {
    return (
      <div className="admin-table__root">
        <div className="admin-table__table-wrapper">
          <Table className="admin-table__table" aria-labelledby="tableTitle">
            {getHeaderForTable()}
            <TableBody>
              {
                // tslint:disable:no-any
                data.map((n: any, i: number) => {
                  return (
                    <TableRow key={i}>
                      {rows.map((row: any, index: number) => {
                        return (
                          <TableCell
                            key={index}
                            component="td"
                            align={row.alignRight ? "right" : "left"}
                            className="admin-table__cell"
                          >
                            {row.key === "admin_email" ? (
                              <Link
                                to={`${location && location.pathname}/${
                                  n.admin && n.admin.uid
                                }`}
                                className="admin-table__link"
                              >
                                {n.admin && n.admin.email}
                              </Link>
                            ) : row.key === "created_at" ? (
                              localeDate(n[row.key], "fullDate")
                            ) : row.key === "browser" ? (
                              getUserBrowser(n.user_agent)
                            ) : row.key === "os" ? (
                              getUserOS(n.user_agent)
                            ) : row.key === "result" ? (
                              <span
                                className={clsx({
                                  "admin-table__active":
                                    n.result === "active" ||
                                    n.result === "succeed",
                                  "admin-table__banned":
                                    n.result !== "active" &&
                                    n.result !== "succeed",
                                })}
                              />
                            ) : row.key === "admin_role" ? (
                              n.admin && n.admin.role
                            ) : row.key === "target" ? (
                              <Link
                                to={`${location && location.pathname}/${
                                  n.target && n.target.uid
                                }`}
                                className="admin-table__link"
                              >
                                {n.target && n.target.email}
                              </Link>
                            ) : row.key === "user_ip" ? (
                              parseList(n.user_ip)
                            ) : (
                              n[row.key]
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell component="td" className="admin-table__info">
                        <IconButton onClick={handleClick(i)}>
                          <InfoOutlined className="admin-table__icon" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }) // tslint:enable:no-any
              }
            </TableBody>
          </Table>
        </div>
        {!hidePagination ? (
          <TablePagination
            component="div"
            count={Number(dataLength)}
            rowsPerPage={rowsPerPage}
            page={page || 0}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
            classes={{ selectIcon: "admin-table__select-icon" }}
          />
        ) : null}
        <InfoPopper
          anchorEl={state.anchorEl}
          open={Boolean(state.anchorEl)}
          handleClose={handleClose}
          data={renderData(data[state.selectIndex].data)}
        />
      </div>
    );
  };

  return (
    <div className="admin-table__root">
      {label && (
        <Typography
          variant="h6"
          gutterBottom={true}
          className="admin-table__label"
        >
          {label}
        </Typography>
      )}
      {data.length ? renderContent() : null}
    </div>
  );
};
