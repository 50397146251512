import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

interface UnmatchDepositCounterparty {
  id: string;
  name: string;
  id_type: string;
  bank_routing: { scheme: string; address: string } | undefined;
  account_routing: { scheme: string; address: string } | undefined;
}

interface UnmatchDepositData {
  id: string;
  type: string;
  status: string;
  start_date: string;
  end_date: string;
  transaction_ids: string[]; //
  from: { bank_id: string; account_id: string } | undefined;
  details: { type: string } | undefined;
  counterparty: UnmatchDepositCounterparty | undefined;
  charge:
    | { value: { amount: string; currency: string }; summary: string }
    | undefined;
  payment_network?: string;
  currency?: string;
  amount?: string;
  account_number?: string;
  omnibus_account_number?: string;
}

export interface UnmatchDeposit {
  id: number;
  txid: string;
  state: string;
  data: UnmatchDepositData;
}

interface Result {
  data: UnmatchDeposit[];
  total: number;
}

export const SWR_UNMATCHED_DEPOSITS_KEY = (params: string) => [
  "get-unmatched-deposits",
  params,
];

const fetcher = async (_key: string, params: string): Promise<Result> => {
  const { data, headers } = await API.get(requestOptions)(
    `${PAYMENTS_API_END_POINT_ADMIN_UNMATCHED_DEPOSITS}?${params}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useUnmatchedDepositHistory = (params: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_UNMATCHED_DEPOSITS_KEY(params),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return { data, error, mutate, loading: isValidating };
};
