import React from "react";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { BlackListMactchProps } from "./types";
import { useBlackList } from "hooks/useBlackList";
import { ActionButtonInterface } from "components-ts/ActionButtons/types";
import ActionButtons from "components-ts/ActionButtons";

import "./styles.scss";

export const BlackListMactchInfo: React.FC<BlackListMactchProps> = ({
  match,
}) => {
  const { data: blackList, acceptMatch, rejectMatch } = useBlackList();

  const member = blackList?.data.find((value) => {
    return value.id === +match.params.id;
  });

  const actionButtons: ActionButtonInterface[] = [
    {
      variant: "accept",
      value: "Accept",
      state: "pending",
      onClick: () => member && acceptMatch(member),
    },
    {
      variant: "reject",
      value: "Reject",
      state: "pending",
      onClick: () => member && rejectMatch(member),
    },
  ];

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardContent className="blacklist-info__match-info">
          <div className="blacklist-info__match-info-block-body">
            <div className="blacklist-info__match-info-block">
              <Typography variant="h6" className="blacklist-info__email-title">
                Match details
              </Typography>
            </div>
            <div className="blacklist-info__block">
              <div className="blacklist-info__block-item">
                <Typography
                  className="blacklist-info__block-item-title"
                  variant="h5"
                  component="h5"
                >
                  State
                </Typography>
                <Typography
                  className="blacklist-info__block-item-info"
                  component="p"
                >
                  <span
                    className={clsx({
                      "blacklist-info__red": "blocked rejected".includes(
                        member?.state || ""
                      ),
                      "blacklist-info__yellow": "submitted".includes(
                        member?.state || ""
                      ),
                      "blacklist-info__green": "accepted".includes(
                        member?.state || ""
                      ),
                      "blacklist-info__blue": "collected".includes(
                        member?.state || ""
                      ),
                    })}
                  >
                    {member?.state}
                  </span>
                </Typography>
              </div>
              <div className="blacklist-info__block-item">
                <Typography
                  className="blacklist-info__block-item-title"
                  variant="h5"
                  component="h5"
                >
                  National ID
                </Typography>
                <Typography
                  className="blacklist-info__block-item-info"
                  component="p"
                >
                  {member?.customer_national_id}
                </Typography>
              </div>

              <div className="blacklist-info__block-item">
                <Typography
                  className="blacklist-info__block-item-title"
                  variant="h5"
                  component="h5"
                >
                  CID
                </Typography>
                <Typography
                  className="blacklist-info__block-item-info"
                  component="p"
                >
                  {member?.customer_cid}
                </Typography>
              </div>
              <div className="blacklist-info__block-item">
                <Typography
                  className="blacklist-info__block-item-title"
                  variant="h5"
                  component="h5"
                >
                  Email
                </Typography>
                <Typography
                  className="blacklist-info__block-item-info"
                  component="p"
                >
                  {member?.member_email}
                </Typography>
              </div>

              <Divider variant="middle" className="blacklist-info__divider" />
              {member && (
                <ActionButtons
                  actionButtons={actionButtons}
                  originalData={member}
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default BlackListMactchInfo;
