import React, { useState } from "react";
import { Paper } from "@material-ui/core";

import {
  SearchBarContainer,
  SearchBarRequestInterface,
} from "components-ts/SearchBarContainer";
import { InfoTableComponent } from "components-ts/InfoTableComponent/InfoTableComponent";
import { useActivity } from "hooks/useActivity";
import { tablePageLimit } from "api/config";
import { buildQueryString } from "helpers/buildQueryString";
import { convertToObj } from "helpers/convertToObj";

export const Activities: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tablePageLimit());
  const [data, setData] = useState<SearchBarRequestInterface[]>([
    {
      property: "",
      value: "",
    },
  ]);
  const { data: userActivity, mutate, loading } = useActivity(
    buildQueryString({
      page: page + 1,
      limit: rowsPerPage,
      ordering: "desc",
      ...convertToObj(data),
    })
  );

  const handleSearch = (data: SearchBarRequestInterface[]) => {
    setData(data);
    setPage(0);
    setRowsPerPage(tablePageLimit());
  };

  const handleClearSearchRequest = () => {
    setPage(0);
    setData([
      {
        property: "",
        value: "",
      },
    ]);
  };

  const activityRows = [
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "user_email", alignRight: false, label: "Email" },
    { key: "user_role", alignRight: false, label: "Role" },
    { key: "topic", alignRight: true, label: "Topic" },
    { key: "action", alignRight: false, label: "Action" },
    { key: "result", alignRight: false, label: "Result" },
    { key: "user_ip", alignRight: true, label: "IP" },
    { key: "browser", alignRight: true, label: "Browser" },
    { key: "os", alignRight: true, label: "OS" },
  ];

  const selectedValues = [
    {
      label: "UID",
      value: "uid",
      checked: false,
    },
    {
      label: "Email",
      value: "email",
      checked: false,
    },
    {
      label: "Action",
      value: "action",
      checked: false,
    },
    {
      label: "Topic",
      value: "topic",
      checked: false,
    },
  ];

  // sabe rey

  return (
    <React.Fragment>
      <SearchBarContainer
        selectedItems={selectedValues}
        handleSearchRequest={handleSearch}
        handleClearSearchRequest={handleClearSearchRequest}
        handleTimeFormat={(date) => {
          return date.unix().toString();
        }}
      />
      <Paper style={{ marginTop: 25 }}>
        <InfoTableComponent
          dataLength={userActivity?.total}
          rows={activityRows}
          data={userActivity?.data}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(page) => setPage(page)}
          handleChangeRowsPerPage={(count) => {
            setPage(0);
            setRowsPerPage(Number(count));
          }}
          handleRowOnClick={() => { }}
          hidePagination={false}
          mutateData={mutate}
          loading={loading}
          tableTitle={"User Activities"}
        />
      </Paper>
    </React.Fragment>
  );
};

export default Activities;
