import React from "react";
import { UserDataProps } from "./types";
import { Grid, Paper } from "@material-ui/core";
import { UserSummary } from "../UserSummary";
import { UserPartnerData } from "../UserPartnerData";
import { UserKYC } from "../UserKYC";
import { UserSettings } from "../UserSettings";
import { UserCustomerData } from "../UserCustomerData";
import { UserCreateAction } from "../UserCreateAction";
import { UserLabel } from "../UserLabel";
import { UserKYCDocuments } from "../UserKYCDocuments";

export const UserData: React.FC<UserDataProps> = ({
  user,
  newLabelName,
  newLabelValue,
  newLabelScope,
  addNewLabel,
  deleteUserLabel,
  isAddLabelModalOpened,
  isEditLabelModalOpened,
  openAddLabelModal,
  openEditLabelModal,
  closeModal,
  handleChangeUserState,
  handleChangeRole,
  handleChangeUserOTP,
  handleEditLabel,
  currentUser,
  newDeposit,
  openNewDepositModal,
  isNewDepositModalOpened,
  handleRejectVideo,
  handleRejectID,
  handleAcceptKYC,
  currencyOptions,
}) => {
  return (
    <div className="user-data">
      <Grid container={true} spacing={3} direction={"row"}>
        <Grid item={true} xs={12} lg={6}>
          <Paper style={{ padding: 20, marginBottom: 24 }}>
            <UserSummary user={user} />
          </Paper>
          {user.partner && (
            <Paper style={{ marginBottom: 24 }}>
              <UserPartnerData partner={user.partner} />
            </Paper>
          )}
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <Paper style={{ marginBottom: 24 }}>
            <UserKYC user={user} editLabel={handleEditLabel} />
          </Paper>
          <Paper style={{ marginBottom: 24 }}>
            <UserSettings
              user={user}
              handleChangeUserState={handleChangeUserState}
              handleChangeRole={handleChangeRole}
              handleChangeUserOTP={handleChangeUserOTP}
              currentUser={currentUser}
            />
          </Paper>
          <Paper style={{ marginBottom: 24 }}>
            <UserCustomerData customer={user.customer} />
          </Paper>
          <Paper style={{ marginBottom: 24 }}>
            <UserCreateAction
              newDeposit={newDeposit}
              openNewDepositModal={openNewDepositModal}
              isNewDepositModalOpened={isNewDepositModalOpened}
              closeModal={closeModal}
              currencyOptions={currencyOptions}
            />
          </Paper>
        </Grid>
        <Grid item={true} xs={12} lg={12}>
          <Paper>
            <UserLabel
              user={user}
              newLabelName={newLabelName}
              newLabelValue={newLabelValue}
              newLabelScope={newLabelScope}
              addNewLabel={addNewLabel}
              editLabel={handleEditLabel}
              deleteUserLabel={deleteUserLabel}
              isAddLabelModalOpened={isAddLabelModalOpened}
              isEditLabelModalOpened={isEditLabelModalOpened}
              openAddLabelModal={openAddLabelModal}
              openEditLabelModal={openEditLabelModal}
              closeModal={closeModal}
            />
          </Paper>
        </Grid>
        <Grid item={true} xs={12} lg={12}>
          <Paper style={{ marginBottom: 15 }}>
            <UserKYCDocuments
              user={user}
              handleRejectVideo={handleRejectVideo}
              handleRejectID={handleRejectID}
              handleAcceptKYC={handleAcceptKYC}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserData;
