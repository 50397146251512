import * as React from "react";
import { getCode } from "country-list";
import { Moment } from "moment";
import { SearchBar } from "components-ts/SearchBar";
import { SelectItem } from "components-ts/SearchBar/types";
import {
  SearchBarElementInterface,
  SearchBarWrapperState,
  SearchBarConteinerProps,
} from "./types";
import { useEffect, useState } from "react";

export type SearchBarRequestInterface = {
  property: string;
  value: string;
};

export const SearchBarContainer: React.FC<SearchBarConteinerProps> = ({
  selectedItems,
  handleSearchRequest,
  handleClearSearchRequest,
  handleTimeFormat,
}) => {
  const defaultItems = [
    {
      value: "",
      label: "",
      checked: false,
    },
  ];

  const [state, setState] = useState<SearchBarWrapperState>({
    selectedItems: selectedItems.length ? selectedItems : defaultItems,
    selectedMainItem: selectedItems.length ? selectedItems[0] : defaultItems[0],
    selectedMainItemValue: "",
    elems: [],
    selectedStartDate: null,
    selectedEndDate: null,
  });

  useEffect(() => {
    const items = state.selectedItems;
    items[0].checked = true;

    setState({
      ...state,
      selectedItems: items,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSelectedValue = (index: number, value: unknown) => {
    if (index === 0) {
      const item = state.selectedItems.find((e) => e.value === value);

      if (item) {
        const selectedIndex = state.selectedItems.indexOf(item);
        const pastItemIndex = state.selectedItems.indexOf(
          state.selectedMainItem
        );

        const newItems = state.selectedItems;

        newItems[pastItemIndex].checked = false;
        newItems[selectedIndex].checked = true;

        setState({
          ...state,
          selectedMainItem: state.selectedItems[selectedIndex],
          selectedItems: newItems,
        });
      }
    } else {
      const newElems = state.elems;
      const item = state.selectedItems.find((e) => e.value === value);

      if (item) {
        const pastItemIndex = state.selectedItems.indexOf(
          newElems[index - 1].selectedSearchValue
        );
        const selectedIndex = state.selectedItems.indexOf(item);

        const newItems = state.selectedItems;
        newItems[pastItemIndex].checked = false;
        newItems[selectedIndex].checked = true;
        newElems[index - 1].selectedSearchValue = newItems[selectedIndex];
        setState({ ...state, selectedItems: newItems, elems: newElems });
      }
    }
  };

  const handleChangeInputValue = (index: number, value: string) => {
    if (index === 0) {
      setState({ ...state, selectedMainItemValue: value });
    } else {
      const newElems = state.elems;
      const elem = newElems.find((item) => item.index === index);

      if (elem) {
        elem.selectedValueData = value;
        newElems[index - 1] = elem;

        setState({ ...state, elems: newElems });
      }
    }
  };

  const deleteItem = (index: number) => {
    const newElems = state.elems;
    const newSelectedItems = state.selectedItems;

    const removedItemIndex = state.selectedItems.indexOf(
      newElems[index - 1].selectedSearchValue
    );

    newSelectedItems[removedItemIndex].checked = false;
    newElems.splice(index - 1, 1);
    newElems.map((item) =>
      item.index > index ? (item.index = item.index - 1) : item.index
    );

    setState({ ...state, elems: newElems, selectedItems: newSelectedItems });
  };

  const handleSendRequest = () => {
    const firstItem = {
      index: 0,
      selectedSearchValue: state.selectedMainItem,
      selectedValueData: state.selectedMainItemValue,
    };

    const requestArray = [...state.elems, firstItem].map((item) => {
      if (
        item.selectedSearchValue.value === "country" &&
        getCode(item.selectedValueData) !== undefined
      ) {
        return {
          property: item.selectedSearchValue.value,
          value: getCode(item.selectedValueData),
        };
      } else {
        return {
          property: item.selectedSearchValue.value,
          value: item.selectedValueData,
        };
      }
    });
    state.selectedStartDate &&
      handleTimeFormat &&
      requestArray.push({
        property: "from",
        value: handleTimeFormat(state.selectedStartDate.startOf("day")),
      });

    state.selectedEndDate &&
      handleTimeFormat &&
      requestArray.push({
        property: "to",
        value: handleTimeFormat(state.selectedEndDate.endOf("day")),
      });

    (state.selectedStartDate || state.selectedEndDate) &&
      requestArray.push({
        property: "range",
        value: "created",
      });

    handleSearchRequest(requestArray);
  };

  const enterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendRequest();
    }
  };

  const getSearchBars = () => {
    return state.elems.map((item: SearchBarElementInterface, i: number) => {
      return (
        <SearchBar
          key={i}
          index={i + 1}
          selectedValues={selectedItems}
          activeItem={item.selectedSearchValue}
          handleChangeSelect={handleChangeSelectedValue}
          searchValue={item.selectedValueData}
          handleChangeSearchValue={handleChangeInputValue}
          handleDeleteItem={deleteItem}
          handleEnterPress={enterPress}
        />
      );
    });
  };

  const addItemElem = () => {
    const newItems = state.elems;

    if (newItems.length + 1 < state.selectedItems.length) {
      const firstUnselectedItem = state.selectedItems.find(
        (item) => !item.checked
      );

      if (firstUnselectedItem) {
        const updateSelectedItems = state.selectedItems.indexOf(
          firstUnselectedItem
        );
        const newSelectedItems = state.selectedItems;
        newSelectedItems[updateSelectedItems].checked = true;

        newItems.push({
          index: state.elems.length + 1,
          selectedSearchValue: firstUnselectedItem,
          selectedValueData: "",
        });

        setState({
          ...state,
          selectedItems: newSelectedItems,
          elems: newItems,
        });
      }
    }
  };

  const handleClear = () => {
    const cleanedSelectedItems = selectedItems;
    cleanedSelectedItems.map((item: SelectItem, index: number) =>
      index === 0 ? (item.checked = true) : (item.checked = false)
    );

    setState({
      selectedItems: cleanedSelectedItems,
      selectedMainItem: selectedItems[0],
      selectedMainItemValue: "",
      elems: [],
      selectedStartDate: null,
      selectedEndDate: null,
    });

    handleClearSearchRequest();
  };

  const handleStartDateChange = (date: Moment | null) => {
    setState({ ...state, selectedStartDate: date });
  };

  const handleEndDateChange = (date: Moment | null) => {
    setState({ ...state, selectedEndDate: date });
  };

  return (
    <>
      <SearchBar
        index={0}
        selectedValues={selectedItems}
        activeItem={state.selectedMainItem}
        handleChangeSelect={handleChangeSelectedValue}
        searchValue={state.selectedMainItemValue}
        handleChangeSearchValue={handleChangeInputValue}
        handleAddItem={addItemElem}
        handleClear={handleClear}
        handleSendRequest={handleSendRequest}
        startDate={state.selectedStartDate}
        endDate={state.selectedEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleEnterPress={enterPress}
      />
      {getSearchBars()}
    </>
  );
};
