import { useEffect } from "react";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { API, RequestOptions } from "../api";
import { PAYMENTS_API_END_POINT_ADMIN_LIMITS } from "api/apiEndpoints";

const getLimitsOptions: RequestOptions = {
  apiVersion: "payments",
};

export interface LimitsOrderForm {
  currency: string;
  payment_network: string;
  cid?: string;
  level?: number;
  value: number;
  operation: string;
  kind: string;
  time_period: string;
}

export interface UserLimits {
  id: number;
  remaining: string;
  customer_id?: number;
  payment_network: string;
  currency: string;
  value: number;
  operation: string;
  kind: string;
  level?: number;
  time_period: string;
  type: string;
}

interface Response {
  data: UserLimits[];
}

export const SWR_USER_LIMITS_KEY = (uid: string) => ["get-user-limits", uid];

const fetcher = async (_key: string, uid: string): Promise<UserLimits[]> => {
  const { data: limit }: Response = await API.get(getLimitsOptions)(
    `${PAYMENTS_API_END_POINT_ADMIN_LIMITS}/${uid}`
  );
  return limit;
};

export const useLimits = (uid: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_USER_LIMITS_KEY(uid),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const createLimit = async (orderForm: LimitsOrderForm) => {
    try {
      await API.post(getLimitsOptions)(
        PAYMENTS_API_END_POINT_ADMIN_LIMITS,
        orderForm
      );
    } catch (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  const deleteLimit = async (params: { limit_id: number; type: string }) => {
    try {
      await API.delete(getLimitsOptions)(
        PAYMENTS_API_END_POINT_ADMIN_LIMITS,
        params
      );
    } catch (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  };

  return {
    data,
    error,
    mutate,
    createLimit,
    deleteLimit,
    loading: isValidating,
  };
};
