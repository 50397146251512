import { useEffect } from "react";
import { API, RequestOptions } from "api";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { PAYMENTS_API_END_POINT_ADMIN_DEPOSITS } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

const peatioRequestOptions: RequestOptions = {
  apiVersion: "peatio",
};

export interface DepositInfo {
  tid: string;
  uid: string;
  currency: string;
  type: string;
  amount: number;
  state: string;
  data: Object;
  txid: string;
  confirmations: string;
  created_at: string;
  completed_at: string;
}

interface Response {
  data: DepositInfo[];
  total: number;
}

export const SWR_USER_DEPOSITS_KEY = (optionalParameters: string) => [
  "get-user-deposits",
  optionalParameters,
];

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${PAYMENTS_API_END_POINT_ADMIN_DEPOSITS}?${optionalParameters}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useDepositHistory = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_USER_DEPOSITS_KEY(optionalParameters || ""),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const createNewDeposit = async (deposit) => {
    try {
      await API.post(peatioRequestOptions)("/admin/deposits/new", deposit);
      enqueueSnackbar("Deposit created", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err.message[0], {
        variant: "error",
      });
    }
  };

  return { data, error, mutate, createNewDeposit, loading: isValidating };
};
