export enum CATEGORY_TYPE {
  ASSET_REGISTRATION = "asset_registration",
  INVESTMENT = "investment",
  MINTING_TOKEN = "minting_token",
  BALANCE_ANOMALY = "balance_anomaly",
  MISC = "misc",
  REFUND = "refund",
  COMPENSATION = "compensation",
  INCENTIVE = "incentive",
  BANK_FEES = "bank_fees",
  BANK_INTEREST = "bank_interest",
  MINOR = "minor",
}

export const CATEGORY_OPTIONS = [
  { label: "Asset registration", value: CATEGORY_TYPE.ASSET_REGISTRATION },
  { label: "Investment", value: CATEGORY_TYPE.INVESTMENT },
  { label: "Minting token", value: CATEGORY_TYPE.MINTING_TOKEN },
  { label: "Balance anomaly", value: CATEGORY_TYPE.BALANCE_ANOMALY },
  { label: "Refund", value: CATEGORY_TYPE.REFUND },
  { label: "Compensation", value: CATEGORY_TYPE.COMPENSATION },
  { label: "Incentive", value: CATEGORY_TYPE.INCENTIVE },
  { label: "Bank fees", value: CATEGORY_TYPE.BANK_FEES },
  { label: "Bank interest", value: CATEGORY_TYPE.BANK_INTEREST },
  { label: "Minor", value: CATEGORY_TYPE.MINOR },
  { label: "Miscelaneus", value: CATEGORY_TYPE.MISC },
];

export enum ACCOUNT_TYPE {
  MAIN_FIAT_ASSETS_ACCOUNT = "101",
  MAIN_CRYPTO_ASSETS_ACCOUNT = "102",
  MAIN_FIAT_LIABILITIES_ACCOUNT = "201",
  MAIN_CRYPTO_LIABILITIES_ACCOUNT = "202",
  LOCKED_FIAT_LIABILITIES_ACCOUNT = "211",
  LOCKED_CRYPTO_LIABILITIES_ACCOUNT = "212",
  MAIN_FIAT_REVENUES_ACCOUNT = "301",
  MAIN_CRYPTO_REVENUES_ACCOUNT = "302",
  MAIN_FIAT_EXPENSES_ACCOUNT = "401",
  MAIN_CRYPTO_EXPENSES_ACCOUNT = "402",
}

export const ASSET_OPTIONS = [
  { label: "101", value: ACCOUNT_TYPE.MAIN_FIAT_ASSETS_ACCOUNT },
  { label: "102", value: ACCOUNT_TYPE.MAIN_CRYPTO_ASSETS_ACCOUNT },
];

export const RECEIVING_CODE_OPTIONS = [
  { label: "201", value: ACCOUNT_TYPE.MAIN_FIAT_LIABILITIES_ACCOUNT },
  { label: "202", value: ACCOUNT_TYPE.MAIN_CRYPTO_LIABILITIES_ACCOUNT },
  { label: "211", value: ACCOUNT_TYPE.LOCKED_FIAT_LIABILITIES_ACCOUNT },
  { label: "212", value: ACCOUNT_TYPE.LOCKED_CRYPTO_LIABILITIES_ACCOUNT },
  { label: "301", value: ACCOUNT_TYPE.MAIN_FIAT_REVENUES_ACCOUNT },
  { label: "302", value: ACCOUNT_TYPE.MAIN_CRYPTO_REVENUES_ACCOUNT },
  { label: "401", value: ACCOUNT_TYPE.MAIN_FIAT_EXPENSES_ACCOUNT },
  { label: "402", value: ACCOUNT_TYPE.MAIN_CRYPTO_EXPENSES_ACCOUNT },
];
