import { API, RequestOptions } from "api";
import { Customer } from "./useUserData";
import { useSnackbar } from "notistack";
import useSWR from "swr";
import { useEffect } from "react";
import { COMPLIANCE_API_END_POINT_ADMIN_BLACKLIST_MATCH } from "api/apiEndpoints";

export interface Member {
  id: number;
  email: string;
  uid: number;
  level: number;
  state: string;
  role: string;
  created_at: string;
  updated_at: string;
}

export interface BlackListCustomer {
  id: number;
  person_type: string;
  source_id: number;
  source: string;
}

export interface BlackListMember {
  id: number;
  customer: Customer;
  member: Member;
  customer_blacklist: BlackListCustomer;
  state: string;
  observation: string;
  customer_national_id?: number | string;
  customer_cid?: string | number;
  member_email?: string;
}

interface Response {
  data: BlackListMember[];
  total: number;
}

const requestOptions: RequestOptions = {
  apiVersion: "compliance",
};

export const SWR_BLACKLIST_KEY = (optionalParameters?: string) => [
  "get-blacklist-match",
  optionalParameters,
];

const fetcher = async (
  _key: string,
  optionalParams?: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${COMPLIANCE_API_END_POINT_ADMIN_BLACKLIST_MATCH}?${optionalParams}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useBlackList = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  let { data, error, mutate, isValidating } = useSWR(
    SWR_BLACKLIST_KEY(optionalParameters || ""),
    fetcher
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }

    if (data) {
      const newData = data.data.map((item) => {
        return {
          customer_national_id: item.customer.national_id,
          customer_cid: item.customer.cid,
          member_email: item.member.email,
          ...item,
        };
      });
      mutate({ ...data, data: newData }, false);
    }
  }, [data, error, mutate, enqueueSnackbar]);

  const acceptMatch = async (member: BlackListMember) => {
    try {
      await API.post(requestOptions)(
        `${COMPLIANCE_API_END_POINT_ADMIN_BLACKLIST_MATCH}${member.id}/accept`
      );
    } catch (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
    mutate();
  };

  const rejectMatch = async (member: BlackListMember) => {
    try {
      await API.post(requestOptions)(
        `/admin/blacklist_match/${member.id}/reject`
      );
    } catch (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
    mutate();
  };

  return {
    data,
    error,
    mutate,
    acceptMatch,
    rejectMatch,
    loading: isValidating,
  };
};
