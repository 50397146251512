import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { PAYMENTS_API_END_POINT_WITHDRAWS } from "api/apiEndpoints";
import { BeneficiaryInfo } from "./useBeneficiary";

const requestOptions: RequestOptions = {
  apiVersion: "payments",
};

export interface WithdrawInfo {
  id: number;
  tid: string;
  uid: string;
  currency: string;
  type: string;
  amount: number;
  state: string;
  fee: number;
  txid: string;
  created_at: string;
  note: string;
  rid: string;
  beneficiary?: BeneficiaryInfo;
}

interface Response {
  data: WithdrawInfo[];
  total: number;
}

export const SWR_USER_WITHDRAWS_KEY = (optionalParameters: string) => [
  "get-users-withdraws",
  optionalParameters,
];

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${PAYMENTS_API_END_POINT_WITHDRAWS}?${optionalParameters}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useWithdrawHistory = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_USER_WITHDRAWS_KEY(optionalParameters || ""),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);
  return { data, error, mutate, loading: isValidating };
};
