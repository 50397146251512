import { useEffect } from "react";
import { API } from "api";
import { RequestOptions } from "api/requestBuilder";
import useSWR from "swr";
import { BARONG_API_END_POINT_ADMIN_METRICS } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "barong",
};

export interface Metrics {
  signups: {};
  sucessful_logins: {};
  failed_logins: {};
  pending_applications: number;
}

const fetcher = async (): Promise<Metrics> => {
  const { data: metrics } = await API.get(requestOptions)(BARONG_API_END_POINT_ADMIN_METRICS);
  return metrics;
};

export const useMetrics = () => {
  const { data, error, mutate } = useSWR("get-metrics", fetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, error, mutate]);

  return {
    data,
    error,
    mutate,
  };
};
