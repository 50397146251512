import * as React from "react";
import { Moment } from "moment";
import MomentUtils from "@date-io/moment";
import {
  Card,
  CardContent,
  createMuiTheme,
  FormControl,
  MenuItem,
  MuiThemeProvider,
  Select,
  TextField,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  RemoveCircleOutline,
  Search,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SearchBarProps } from "./types";

import "./styles.scss";

const datePickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#309CEA",
    },
  },
  typography: {},
});

export const SearchBar: React.FC<SearchBarProps> = ({
  activeItem,
  selectedValues,
  searchValue,
  index,
  startDate,
  endDate,
  handleChangeSelect,
  handleChangeSearchValue,
  handleAddItem,
  handleDeleteItem,
  handleClear,
  handleSendRequest,
  handleStartDateChange,
  handleEndDateChange,
  handleEnterPress,
}) => {
  const deleteItem = (e) => {
    if (handleDeleteItem) {
      handleDeleteItem(index);
    }
  };

  const startDateChange = (date: Moment | null) => {
    handleStartDateChange && handleStartDateChange(date);
  };

  const endDateChange = (date: Moment | null) => {
    handleEndDateChange && handleEndDateChange(date);
  };

  return (
    <Card className="search-bar__card">
      <CardContent className="search-bar__card-content">
        <FormControl className="search-bar__form-control" variant="outlined">
          <div className="search-bar__container">
            <div className="search-bar__search-terms">
              <div className="search-bar__form-control">
                <div className="search-bar__search-fields">
                  <Select
                    value={activeItem.value}
                    onChange={(e) => handleChangeSelect(index, e.target.value)}
                    className="search-bar__select"
                    classes={{ icon: "search-bar__icon" }}
                  >
                    {selectedValues.map((el, i) => (
                      <MenuItem key={i} disabled={el.checked} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    className="search-bar__text-field"
                    placeholder={`Enter ${activeItem.label}`}
                    value={searchValue}
                    onChange={(e) =>
                      handleChangeSearchValue(index, e.target.value)
                    }
                    onKeyPress={handleEnterPress}
                  />
                </div>
                <div className="search-bar__add-minus-icon-block">
                  {index === 0 ? (
                    <AddCircleOutline
                      onClick={handleAddItem}
                      className="search-bar__add-minus-icon"
                    />
                  ) : (
                    <RemoveCircleOutline
                      onClick={deleteItem}
                      className="search-bar__add-minus-icon"
                    />
                  )}
                </div>
              </div>

              <div>
                {index === 0 ? (
                  <MuiThemeProvider theme={datePickerTheme}>
                    <div className="search-bar__date-picker" >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          value={startDate}
                          onChange={startDateChange}
                          placeholder="Start date"
                          className={"search-bar__date-picker-start"}
                          format="DD-MM-YYYY"
                          onKeyPress={handleEnterPress}
                        />
                      </MuiPickersUtilsProvider>

                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          value={endDate}
                          onChange={endDateChange}
                          placeholder="End date"
                          minDate={startDate || undefined}
                          format="DD-MM-YYYY"
                          onKeyPress={handleEnterPress}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </MuiThemeProvider>
                ) : null}
              </div>
            </div>
            {index === 0 ? (
              <div className="search-bar__block search-bar__icons">
                <div className="search-bar__side-icon-search">
                  <Search
                    className="search-bar__side-icon-item"
                    onClick={handleSendRequest}
                  />
                </div>
                <div className="search-bar__side-icon-clear">
                  <Close
                    className="search-bar__side-icon-item"
                    onClick={handleClear}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </FormControl>
      </CardContent>
    </Card>
  );
};
