import React, { useContext, useState } from "react";
import { Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import { UserInfoProps } from "./types";
import { UserData } from "./UserData";
import { useUserData } from "hooks/useUserData";
import { useLabels } from "hooks/useLabels";
import { UserContext } from "providers/UserProvider";
import { useDepositHistory } from "hooks/useDepositHistory";
import { useCurrencies } from "hooks/useCurrencies";

export const UserInfo: React.FC<UserInfoProps> = ({ match, location }) => {
  const [nameLabel, setNameLabel] = useState<string>("");
  const [valueLabel, setValueLabel] = useState<string>("");
  const [scopeLabel, setScopeLabel] = useState<string>("private");
  const [documentIndex, setDocumentIndex] = useState<number>(0);
  const [openAddLabelModal, setOpenAddLabelModal] = useState<boolean>(false);
  const [openEditLabelModal, setOpenEditLabelModal] = useState<boolean>(false);
  const [openNewDepositModal, setOpenNewDepositModal] = useState<boolean>(
    false
  );
  const [openDocumentCarousel, setOpenDocumentCarousel] = useState<boolean>(
    false
  );
  const {
    data: userData,
    mutate,
    changeUserState,
    changeUserRole,
    changeUserOTP,
    acceptUserKYC,
    rejectUserID,
    rejectUserVideo,
  } = useUserData(match.params.uid);
  const { addNewLabel, editLabel, deleteLabel } = useLabels();
  const { createNewDeposit } = useDepositHistory();
  const { data: currencyList } = useCurrencies();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);
  const history = useHistory();

  const goBack = (event) => {
    event.preventDefault();
    history.goBack();
  };

  const addLabel = async (key, value, scope) => {
    const requestProps = {
      key: key,
      value: value,
      scope: scope,
      uid: (userData && userData.uid) || "",
    };
    await addNewLabel(requestProps);
    mutate();
  };

  const deleteActualLabel = async (uid: string, key: string, scope: string) => {
    await deleteLabel({ uid: uid, key: key, scope: scope });
    mutate();
  };

  const handleCloseModal = () => {
    setOpenAddLabelModal(false);
    setOpenEditLabelModal(false);
    setOpenNewDepositModal(false);
  };

  // tslint:disable-next-line:no-any
  const handleChangeUserState = async (e: any) => {
    const uid = userData && userData.uid;
    await changeUserState({ uid: uid, state: e.target.value });
    mutate();
  };

  // tslint:disable-next-line:no-any
  const handleChangeRole = async (e: any) => {
    const uid = userData && userData.uid;

    await changeUserRole({ uid: uid, role: e.target.value });
    mutate();
  };

  // tslint:disable-next-line:no-any
  const handleChangeUserOTP = async (e: any) => {
    if (!e.target.checked) {
      const uid = userData && userData.uid;
      await changeUserOTP({ uid: uid, otp: e.target.checked });
      mutate();
    } else {
      enqueueSnackbar("2FA can only be enabled by the user", {
        variant: "error",
      });
    }
  };

  const handleOpenEditLabelModal = (
    key: string,
    value: string,
    scope: string
  ) => {
    setOpenEditLabelModal(true);
    setNameLabel(key);
    setValueLabel(value);
    setScopeLabel(scope);
  };

  const handleEditLabel = async (key: string, value: string, scope: string) => {
    const uid = userData && userData.uid;
    setNameLabel(key);
    setValueLabel(value);
    setScopeLabel(scope);
    const requestProps = {
      key: key,
      value: value,
      scope: scope,
      uid: uid || "",
    };

    await editLabel(requestProps);

    setTimeout(() => mutate(), 500);
  };

  const newDeposit = async (currency?: string, amount?: number) => {
    const uid = userData && userData.uid;

    const requestProps = {
      currency: currency,
      amount: amount,
      uid: uid || "",
    };
    await createNewDeposit(requestProps);
    mutate();
  };

  const handleRejectID = async (value: string) => {
    const uid = (userData && userData.uid) || "";
    await rejectUserID({ uid: uid, reason: value });
    mutate();
  };

  const handleRejectVideo = async (value: string) => {
    const uid = (userData && userData.uid) || "";

    await rejectUserVideo({ uid: uid, reason: value });
    mutate();
  };

  const handleAcceptKYC = async () => {
    const uid = (userData && userData.uid) || "";
    await acceptUserKYC({ uid: uid });
    mutate();
  };

  const documentsRows = [
    { key: "created_at", alignRight: false, label: "Date" },
    { key: "doc_number", alignRight: false, label: "Doc number" },
    { key: "doc_expire", alignRight: true, label: "Doc expire" },
    { key: "upload", alignRight: true, label: "Attachments" },
  ];

  return (
    <Paper>
      {user && userData ? (
        <UserData
          documentsRows={documentsRows}
          addNewLabel={addLabel}
          editLabel={handleEditLabel}
          closeModal={handleCloseModal}
          deleteUserLabel={deleteActualLabel}
          handleChangeUserState={handleChangeUserState}
          handleChangeRole={handleChangeRole}
          handleChangeUserOTP={handleChangeUserOTP}
          newLabelName={nameLabel}
          newLabelScope={scopeLabel}
          newLabelValue={valueLabel}
          isAddLabelModalOpened={openAddLabelModal}
          isEditLabelModalOpened={openEditLabelModal}
          openAddLabelModal={() => setOpenAddLabelModal(true)}
          openEditLabelModal={handleOpenEditLabelModal}
          user={userData}
          handleEditLabel={handleEditLabel}
          goBack={goBack}
          pathname={location.pathname}
          currentUser={user}
          handleCloseDocumentCarousel={() => setOpenDocumentCarousel(false)}
          handleOpenDocumentCarousel={(index) => {
            setOpenDocumentCarousel(true);
            setDocumentIndex(index);
          }}
          openDocumentCarousel={openDocumentCarousel}
          documentIndex={documentIndex}
          handleNavigateDocumentCarousel={(index) => setDocumentIndex(index)}
          newDeposit={newDeposit}
          openNewDepositModal={() => setOpenNewDepositModal(true)}
          isNewDepositModalOpened={openNewDepositModal}
          currencyOptions={currencyList ? currencyList : []}
          handleRejectVideo={handleRejectVideo}
          handleRejectID={handleRejectID}
          handleAcceptKYC={handleAcceptKYC}
        />
      ) : (
        "Loading"
      )}
    </Paper>
  );
};

export default UserInfo;
