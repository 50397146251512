import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { Player } from "video-react";
import { useStyles } from ".";

const videoReason1 =
  // eslint-disable-next-line no-multi-str
  "Por favor realiza un nuevo video en un ambiente bien iluminado asegurándote que la cámara capte los gestos de tu cara y tus manos de manera nítida.";

const videoReason2 =
  // eslint-disable-next-line no-multi-str
  "Por favor realiza un nuevo video prestándole atención a los gestos generados especialmente para tu toma.";

const videoReason3 =
  // eslint-disable-next-line no-multi-str
  "Te pedimos que realices nuevamente el video prestando atención a que los gestos queden grabados dentro del cuadro";

interface VideoPlayerRef {
  playbackRate: number;
}
export const VideoMediaCard = (props) => {
  const [value, setValue] = React.useState<string>("");
  const classes = useStyles();
  const playerRef = useRef<VideoPlayerRef | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.playbackRate = 2;
    }
  }, [playerRef]);

  return (
    <Card className={classes.card}>
      <CardActionArea>
        {props.video && (
          <CardMedia>
            <Player
              ref={playerRef}
              src={props.video.url}
              height={300}
              fluid={false}
            />
          </CardMedia>
        )}
        {props.checked && (
          <CardContent>
            <div>
              <TextField
                label="Description"
                multiline
                rows={4}
                value={value}
                onChange={handleChange}
                variant="outlined"
              />
              <div>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => setValue(videoReason1)}
                >
                  no claros
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => setValue(videoReason2)}
                >
                  no solicitados
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={(e) => setValue(videoReason3)}
                >
                  gestos cuadro
                </Button>
              </div>
            </div>
          </CardContent>
        )}
      </CardActionArea>
      <CardActions>
        {props.checked && (
          <Button
            size="small"
            color="primary"
            onClick={(e) => props.handleRejectVideo(value)}
          >
            Reject
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
