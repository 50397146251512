import { useEffect } from "react";
import { API, RequestOptions } from "api";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { PEATIO_API_END_POINT_ORDERS } from "api/apiEndpoints";

const requestOptions: RequestOptions = {
  apiVersion: "peatio",
};

export interface OrdersListItem {
  id: number;
  side: string;
  ord_type: string;
  price: string;
  avg_price: string;
  state: string;
  market: string;
  created_at: string;
  updated_at: string;
  origin_volume: string;
  remaining_volume: string;
  executed_volume: string;
  trades_count: number;
  email: string;
  uid: string;
}

interface Response {
  data: OrdersListItem[];
  total: number;
}

export const SWR_USER_ORDERS_KEY = (optionalParameters: string) => [
  "get-users-orders",
  optionalParameters,
];

const fetcher = async (
  _key: string,
  optionalParameters: string
): Promise<Response> => {
  const { data, headers } = await API.get(requestOptions)(
    `${PEATIO_API_END_POINT_ORDERS}?${optionalParameters}`
  );

  const result = {
    data: data,
    total: +headers.total,
  };

  return result;
};

export const useOrders = (optionalParameters?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, mutate, isValidating } = useSWR(
    SWR_USER_ORDERS_KEY(optionalParameters || ""),
    fetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data && !error) {
      mutate();
    }

    if (error) {
      enqueueSnackbar(error.message[0], {
        variant: "error",
      });
    }
  }, [data, error, mutate, enqueueSnackbar]);

  return { data, error, mutate, loading: isValidating };
};
